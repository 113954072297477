<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
	entity: Object,
	draggable: Boolean,
});
const store = useStore();

/**
 * 可視状態切り替えボタンの活性状態を制御する
 * 以下の場合非活性
 * ・地表面投影処理中
 * ・アセットがアップロードされていない
 */
const projectionToGroundExecutingIds = (itemId) =>
	store.getters["dxf/projectionToGroundExecutingIds"].some(
		(id) => id === itemId,
	) ||
	!["complete", "complete_wgs84"].some(
		(s) => s === props.entity.getProcessingStatus(),
	);
</script>

<template>
  <span>
    <span v-if="timeStamp" class="ml-4">{{ timeStamp }}</span>
    <v-card-actions class="py-0" v-bind:class="highlight">
      <v-btn v-if="draggable" icon size="24" class="handle grab">
        <v-icon> mdi-drag </v-icon>
      </v-btn>
      <v-btn icon size="24" @click="toggleVisibility" :disabled="projectionToGroundExecutingIds(entity.getId())"
        class="ml-0">
        <v-icon>
          {{ entity.getVisibility() ? "icon:View" : "icon:View-Off" }}
        </v-icon>
      </v-btn>
      <v-btn icon size="36" @click="toggleExpansion" class="ml-0" :disabled="true">
        <v-icon>
          mdi-play {{ entity.getExpansion() ? "mdi-rotate-90" : "" }}
        </v-icon>
      </v-btn>
      <ListItemLabel :text="entity?.getName() ?? ''" />
      <v-btn @click="focus" :disabled="isDisabledFocusBtn" icon size="24">
        <v-icon size="small">icon:Search</v-icon>
      </v-btn>
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props" size="24">
            <v-icon> icon:Overflow-Menu-Vertical </v-icon>
          </v-btn>
        </template>
        <dxf-three-dot-menu v-if="entity.getLineType() === 'dxf'" :entity="entity"></dxf-three-dot-menu>
      </v-menu>
      <!-- 処理ステータス -->
      <div v-if="entity.getProcessingStatus()">
        <processing-status :status="entity.getProcessingStatus()" :processingStatus="constants.designProcessingStatus">
        </processing-status>
      </div>
    </v-card-actions>
  </span>
</template>

<script>
import { mapMutations } from "vuex";
import * as cesiumCommon from "@/utils/cesium-common";
import * as constants from "@/constant.js";
import ProcessingStatus from "@/components/common/ProcessingStatus.vue";
import ListItemLabel from "@/components/Project/ListItemLabel.vue";
import dayjs from "dayjs";
import {
  addLineWorks,
  setLineWorksVisibility,
} from "../../../utils/lineworks-viewer";
import DxfThreeDotMenu from "./DxfThreeDotMenu.vue";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);

export default {
  name: "LineListItemInDesign",
  components: {
    ProcessingStatus,
    DxfThreeDotMenu,
    ListItemLabel,
  },
  data() {
    return {
      constants,
    };
  },
  computed: {
    isActive() {
      const activePointCloudId = this.$store.getters.activeDesign?.getAssetId();
      return activePointCloudId === this.entity.getAssetId();
    },
    highlight() {
      return this.isActive ? "highlight" : "";
    },
    /**
     * フォーカスボタンの活性状態を制御する
     */
    isDisabledFocusBtn() {
      // 非表示状態の場合は非活性
      return !this.entity.getVisibility();
    },
    isVisible() {
      return this.entity.getVisibility();
    },

    timeStamp() {
      return this.entity.getTimeStamp();
    },
  },
  methods: {
    ...mapMutations(["set_snackbar"]),
    async toggleVisibility() {
      const isNotvisible = !this.entity.getVisibility();
      this.entity.setVisibility(isNotvisible);
      if (isNotvisible) {
        if (this.entity.isLoadedInViewer()) {
          setLineWorksVisibility(this.entity, true);
        } else {
          this.$store.dispatch("executeWithSpinner", async () => {
            try {
              await addLineWorks(this.entity);
              this.entity.setLoadedInViewer(true);
            }
            catch (e) {
              setLineWorksVisibility(this.entity, false);
              this.entity.setVisibility(false);
              this.set_snackbar({
                text: this.$t("LINEWORKS_LOADING_FAILED", { name: this.entity.getName() }),
                color: 'rgba(153, 0, 0, 0.72)'
              });
            }
          });
        }
      } else {
        setLineWorksVisibility(this.entity, false);
      }
    },

    toggleExpansion() {
      this.entity.setExpansion(!this.entity.getExpansion());
    },

    isLineDisabledBtn() {
      // 線形データ制御フラグ
      const isNotProcessCompleted =
        this.entity.getProcessingStatus() !== "complete";
      const isNotVisible = !this.entity.getVisibility();
      return isNotProcessCompleted || isNotVisible;
    },
    focus() {
      cesiumCommon.zoomToTileset(this.entity);
    },
  },
};
</script>

<style scoped>
.highlight {
  background-color: #0064ba !important;
}

.grab {
  cursor: grab;
}

.w-75 {
  width: 75%;
}
</style>

<script setup>
import useArbitraryLines from "@/composables/useArbitraryLines.js";
import validationRules from "@/utils/validation-rules.js";

const { createArbitraryLineFromObject } = useArbitraryLines();
const createArbitraryLine = async (obj) => {
	await createArbitraryLineFromObject([obj]);
};
const rules = {
	...validationRules,
	required: (value) => !!value || "*",
	required0: (value) => value === 0 || !!value || "*",
	fgValidInterpolation: (v) => Number.parseFloat(v) > 0,
};
</script>

<template>
  <v-card-text class="object-list-item">
    <v-card-actions class="py-0" :style="obj.edit && !isRenamingMode ? 'background-color: #0064BA;' : ''">
      <!-- 表示ステータス切替 -->
      <v-btn variant="text" icon size="24" @click="toggleVisibility" :disabled="isDisabledToggleVisibilityBtn">
        <v-icon>
          {{ obj.isShow ? "icon:View" : "icon:View-Off" }}
        </v-icon>
      </v-btn>
      <!-- パネル開閉 -->
      <v-btn variant="text" icon size="36" @click="obj.isOpen = !obj.isOpen" class="ml-0">
        <v-icon> mdi-play {{ obj.isOpen ? "mdi-rotate-90" : "" }} </v-icon>
      </v-btn>
      <!-- オブジェクト名 -->
      <div class="d-flex obj-name-area">
        <img v-if="obj.type === constants.objectType.ROAD" class="mr-1" :src="TemporaryRoadIcon"
          alt="Temporary Road Icon" />
        <img v-if="obj.type === constants.objectType.FLAT" class="mr-1" :src="FlatFieldIcon"
          alt="Temporary Road Icon" />
        <img v-if="obj.type === constants.objectType.TRENCH" class="mr-1" :src="DiggingIcon"
          alt="Temporary Road Icon" />
        <object-name @end-rename="isRenamingMode = false" :isRenamingMode="isRenamingMode" :objId="obj.id">
        </object-name>
      </div>
      <!-- サーチボタン -->
      <v-btn variant="text" @click="fitToView" size="24" :disabled="!obj.isShow" icon>
        <v-icon size="small">icon:Search</v-icon>
      </v-btn>
      <!-- メニューボタン -->
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn variant="text" size="24" icon v-bind="props">
            <v-icon> icon:Overflow-Menu-Vertical </v-icon>
          </v-btn>
        </template>
        <v-list density="compact">
          <!-- 出力 -->
          <v-list-item :disabled="!obj.isLoaded" @click="exportByJson">
            <v-icon class="mr-4">icon:Export</v-icon>
            <span class="menu-item-label">{{ $t("EXPORT_AS") }}</span>
          </v-list-item>
          <!-- 中心線形作成 -->
          <v-list-item v-if="obj.type === constants.objectType.ROAD || obj.type === constants.objectType.TRENCH"
            @click="createArbitraryLine(obj)" :disabled="!obj.isShow">
            <v-icon class="mr-4">mdi-vector-polyline</v-icon>
            <span class="menu-item-label">{{ $t("CREATE_LINEWORK_FROM_CENTER_LINE") }}</span>
          </v-list-item>
          <!-- 名称変更 -->
          <v-list-item @click="toggleRenamingMode" :disabled="!obj.edit">
            <v-icon class="mr-4">mdi-rename</v-icon>
            <span class="menu-item-label">{{ $t("RENAME") }}</span>
          </v-list-item>
          <!-- 編集 -->
          <v-list-item @click="toggleLock">
            <v-icon class="mr-4">icon:Pencil</v-icon>
            <span class="menu-item-label">{{ $t("EDIT") }}</span>
          </v-list-item>
          <!-- 削除 -->
          <v-list-item @click="deleteObj">
            <v-icon class="mr-4">icon:Trash-Can</v-icon>
            <span class="menu-item-label">{{ $t("REMOVE") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
    <div v-if="obj.type === constants.objectType.ROAD">
      <road-panel :objId="obj.id" :rules="rules" @road-panel-data="associatedPointData"> </road-panel>
    </div>
    <div v-else-if="obj.type === constants.objectType.FLAT">
      <flat-ground-panel :objId="obj.id" :rules="rules" @road-panel-data="associatedPointData"></flat-ground-panel>
    </div>
    <div v-else-if="obj.type === constants.objectType.TRENCH">
      <trench-panel :objId="obj.id" :rules="rules" @road-panel-data="associatedPointData"></trench-panel>
    </div>
  </v-card-text>
  <div v-if="showSlope2DInput">
    <Slope2dValueInput :objId="obj.id" :slopeValue="slope2DInput.slopeValue" :slopeUnit="slope2DInput.slopeUnit"
      :slopeIndex="slope2DInput.slopeIndex" :posX="slope2DInput.clientX" :posY="slope2DInput.clientY"
      @close="close2dValueInput"></Slope2dValueInput>
  </div>
</template>

<script>
import FlatGroundPanel from "./ObjectPanels/FlatGroundPanel.vue";
import RoadPanel from "./ObjectPanels/RoadPanel.vue";
import TrenchPanel from "./ObjectPanels/TrenchPanel.vue";
import * as cesiumCommon from "@/utils/cesium-common";
import ObjectName from "./ObjectName.vue";
import * as constants from "@/constant.js";
import axios from "axios";
import { mapMutations } from "vuex";
import PointCloud from "@/models/PointCloud";
import Design from "@/models/Design";
import { Drawing2dImage } from "../../models/Drawing2DImage";
import * as lockUtils from "@/utils/lock-utils";
import dayjs from "dayjs";
import TemporaryRoadIcon from "@/assets/icons/customIcons/temporary-road-icon.svg";
import FlatFieldIcon from "@/assets/icons/customIcons/flat-field-icon.svg";
import DiggingIcon from "@/assets/icons/customIcons/digging-icon.svg";
import objUpdateMixin from '@/mixins/objUpdateMixin.js'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter)
import Slope2dValueInput from "../Slope2dValueInput.vue";


export default {
  mixins: [objUpdateMixin],
  components: {
    FlatGroundPanel,
    RoadPanel,
    TrenchPanel,
    ObjectName,
    Slope2dValueInput
  },
  props: {
    objId: String,
    isFlatAdd: Boolean,
    isRoadAdd: Boolean,
    isTrenchAdd: Boolean,
    globeVisibilityBeforeCreationMode: Boolean,
    objects2DViewVisibility: Object,
  },
  data() {
    return {
      constants,
      roadVal: false,
      TemporaryRoadIcon,
      FlatFieldIcon,
      DiggingIcon,
      showSlope2DInput: false,
      slope2DInput: {
        slopeValue: 0,
        slopeUnit: "percent",
        slopeIndex: 0,
        clientX: 0,
        clientY: 0
      },
      isRenamingMode: false,
      isDisabledToggleVisibilityBtn: false
    };
  },
  computed: {
    lockInfo() {
      return this.$store.state.lock.lockInfo;
    },
    isObjectUpdating() {
      return this.$store.state.isObjectUpdating;
    },
  },
  watch: {
    async lockInfo(newValue, oldValue) {
      if (newValue?.objId === oldValue?.objId) return;
      // ロック時の処理
      if (newValue?.objId === this.obj.id) {
        await this.startEdit();
        this.$store.dispatch('lock/keepLock');
      }

      // ロック解除時の処理
      if (oldValue?.objId === this.obj.id) {
        this.endEdit();
      }
    }
  },
  methods: {
    ...mapMutations(["set_snackbar"]),
    waitForReleaseLock() {
      const editingObject = this.$store.state.objects.find((item) =>
        item.id === this.lockInfo.objId
      )
      // ロック解除と編集ステータスの変更が完了するまでステータスを監視して待機
      return new Promise((resolve, reject) => {
        if (editingObject && editingObject.edit) {
          const unwatch = this.$store.watch(
            () => editingObject.edit,
            (newValue) => {
              // 編集ステータスがFalseになったら待機終了
              if (!newValue) {
                unwatch();
                resolve();
              }
            }
          );
        } else {
          resolve();
        }
      })
    },
    waitForUpdate() {
      // アップロードが完了するまでステータスを監視して待機
      return new Promise((resolve, reject) => {
        if (!this.isObjectUpdating) {
          resolve();
        } else {
          const unwatch = this.$store.watch(
            (state) => state.isObjectUpdating,
            (newValue) => {
              if (!newValue) {
                unwatch();
                resolve();
              }
            }
          );
        }
      })
    },
    async toggleVisibility() {
      if (this.obj.isShow) {
        // 連打防止のためアップロード中はボタン非活性にする
        this.isDisabledToggleVisibilityBtn = true;
        await this.waitForUpdate()
        this.isDisabledToggleVisibilityBtn = false;
      }
      this.obj.isShow = !this.obj.isShow;
      try {
        let assetId;
        if (
          this.obj.type === constants.objectType.TRENCH &&
          (this.obj.settings.commonSettings.destination.target === constants.RubbingDestination.POINT_CLOUD ||
            this.obj.settings.commonSettings.destination.target === constants.RubbingDestination.DESIGN_DATA)
        ) {
          assetId = this.obj.settings.commonSettings.destination.id;
        }
        if (!assetId) {
          assetId = JSON.parse(this.obj.json).associatePointCloudId;
        }
        let assetData = assetId ? this.$store.state.point_cloud_list.find((p) => p.getAssetId() == assetId) : undefined; // assetId は文字列なので === での厳密な比較はしない
        // 点群リストに無い場合設計リストから探す
        if (!assetData) {
          assetData = assetId ? this.$store.state.design_list.find((p) => p.getAssetId() == assetId) : undefined; // assetId は文字列なので === での厳密な比較はしない
        }
        if(!assetId){
          assetId = JSON.parse(this.obj.json).associateDrawing2dImageId;
          assetData = assetId ? this.$store.getters["drawing2dImage/getDrawing2dImageList"].find((p) => p.getAssetId() == assetId) : undefined; // assetId は文字列なので === での厳密な比較はしない
        }
        if (assetData && !assetData.getVisibility() && this.obj.isShow) {
          if (assetData instanceof PointCloud) {
            // 点群も同時に表示する
            await cesiumCommon.togglePointCloudVisibility(assetData, this.$store.getters.getRoads);
          } else if (assetData instanceof Design) {
            // 設計データも同時に表示する
            await cesiumCommon.toggleDesignVisibility(assetData);
          }
         else if (assetData instanceof Drawing2dImage) {
            // 設計データも同時に表示する
            await cesiumCommon.toggleDrawing2dImageVisibility(assetData);
          }
        }
        switch (this.obj.type) {
          case constants.objectType.ROAD: {
            await cesiumCommon.toggleRoadVisibility(this.obj, assetId, !assetData);
            break;
          }
          case constants.objectType.FLAT: {
            await cesiumCommon.toggleFlatGroundVisibility(this.obj, assetId);
            break;
          }
          case constants.objectType.TRENCH: {
            await cesiumCommon.setTrenchVisibility(this.obj, !assetData);
            break;
          }
        }
        // 非表示時の処理
        if (!this.obj.isShow) {
          if (this.objects2DViewVisibility[this.obj.id]) {
            this.updateObject2DViewVisibility(false, this.obj.id);
          }
          if (this.obj.edit) {
            lockUtils.releaseLock();
          }
        }
      } catch (e) {
        // エラーが発生したら表示状態を元に戻す
        console.error(e);
        this.obj.isShow = !this.obj.isShow;
        throw e;
      }
    },
    async toggleLock() {
      if (!this.obj.edit) {
        if (this.lockInfo && this.lockInfo.objId !== this.obj.objId) {
          lockUtils.releaseLock();
          await this.waitForReleaseLock();
        }
        lockUtils.lock(this.obj.id);
      } else {
        lockUtils.releaseLock();
      }
    },
    async startEdit() {
      window["viewer"].clearCommandManager();
      try {
        // オブジェクト作成中の場合キャンセル
        if (this.$store.state.modes.inCreationMode.isActive) {
          window.dispatchEvent(new Event("cancelCreateMode"));
        }

        if (this.$store.state.modes.inEditDrawing2dImage.isActive) {
          this.$store.dispatch("end2dImageEdit");
        }

        if (!this.obj.isShow) {
          await this.toggleVisibility(this.obj);
        }
        // Need to clear altitude view and section view by default
        // Reason: there are few cases where altitude view and section view is not clear when flat ground edit called.
        this.clear2DViews();
        try {
          const edit = true;
          if (this.obj.type === this.constants.objectType.ROAD) {
            window["viewer"].enableRoadEdit(edit, this.obj.id, () => {
              this.updatePathInfoInStore(this.obj);
              cesiumCommon.uploadObjAndNotify(this.obj, this.obj.type);
            }, (event, data) => {
              this.showSlope2DInput = true;
              console.log("from client", event, data)
              this.slope2DInput.slopeValue = data.slopeValue;
              this.slope2DInput.slopeIndex = data.slopeIndex;
              this.slope2DInput.slopeUnit = data.slopeUnit;
              this.slope2DInput.clientX = event.clientX;
              this.slope2DInput.clientY = event.clientY;
              console.log("slope2DInput", this.slope2DInput);
            });
            this.updateObject2DViewVisibility(true, this.obj.id);
          } else if (this.obj.type === this.constants.objectType.FLAT) {
            window["viewer"].enableFlatGroundEdit(edit, this.obj.id, () => {
              this.updateFlatGroundInfoInStore();
              cesiumCommon.uploadObjAndNotify(this.obj, this.obj.type);
            });
          } else if (this.obj.type === this.constants.objectType.TRENCH) {
            window["viewer"].enableTrenchEdit(edit, this.obj.id, () => {
              this.updateTrenchInfoInStore(this.obj);
              cesiumCommon.uploadObjAndNotify(this.obj, this.obj.type);
            });
            this.updateObject2DViewVisibility(edit, this.obj.id);
          }
          // 編集モードの切り替え
          this.obj.edit = edit;
          this.$store.dispatch("startEditMode", this.obj.id)
        } catch (error) {
          throw error;
        }
      } catch (error) {
        console.error(error);
      }
    },
    endEdit() {
      try {
        window["viewer"].clearCommandManager();
        try {
          const edit = false;
          if (this.obj.type === this.constants.objectType.ROAD) {
            // 仮設道路
            window["viewer"].enableRoadEdit(edit, this.obj.id, () => {
              //update segments range values
              this.updatePathInfoInStore(this.obj);
            });
            this.updateObject2DViewVisibility(false, this.obj.id);
          } else if (this.obj.type === this.constants.objectType.FLAT) {
            // 平場
            window["viewer"].enableFlatGroundEdit(edit, this.obj.id, () => { });
          } else if (this.obj.type === this.constants.objectType.TRENCH) {
            // 床掘
            window["viewer"].enableTrenchEdit(edit, this.obj.id, () => {
              //update segments range values
              this.updateTrenchInfoInStore(this.obj);
            });
            this.updateObject2DViewVisibility(edit, this.obj.id);
          }
          // 編集モードの切り替え
          this.obj.edit = edit;
          this.$store.dispatch("endEditMode");
        } catch (error) {
          throw error;
        }
      } catch (error) {
        console.error(error);
      }
    },
    updatePathInfoInStore() {
      if (this.obj.settings.commonSettings.segmentSettings && this.obj.settings.commonSettings.segmentSettings.length > 0) {
        for (const segmentSetting of this.obj.settings.commonSettings.segmentSettings) {
          segmentSetting.heightRange = window["viewer"].getRoadHeightInfoOfSection(this.obj.id, segmentSetting.startIndex, segmentSetting.endIndex);
          segmentSetting.slopeRange = window["viewer"].getRoadSlopeInfoOfSection(this.obj.id, segmentSetting.startIndex, segmentSetting.endIndex);
        }
      }
      const lengthOfRoad = window["viewer"].getLengthOfRoad(this.obj.id);
      if (lengthOfRoad) {
        this.obj.info.length = parseFloat(lengthOfRoad.toFixed(3));
      }
      this.obj.info.height = window["viewer"].getRoadHeightInfo(this.obj.id);
      this.obj.info.slope = window["viewer"].getRoadSlopeInfo(this.obj.id);
    },
    updateFlatGroundInfoInStore() {
      const maxHeight = window["viewer"].getMaxHeightOfFlatGroundCtrlPts(this.obj.id);
      this.obj.info.maxHeight = maxHeight.toFixed(3);
    },
    updateTrenchInfoInStore() {
      const lengthOfTrench = window["viewer"].getLengthOfTrench(this.obj.id);
      if (lengthOfTrench) {
        this.obj.info.length = parseFloat(lengthOfTrench.toFixed(3));
      }
      this.obj.info.height = window["viewer"].getTrenchHeightInfo(this.obj.id);
      this.obj.info.slope = window["viewer"].getTrenchSlopeInfo(this.obj.id);
    },
    toggleRenamingMode() {
      this.isRenamingMode = !this.isRenamingMode;
    },
    async deleteObj() {
      try {
        const siteId = this.$route.query.siteId;
        // 他人がロックしている場合エラー
        const lockedObject = await lockUtils.checkLockStatus(siteId, this.obj.cid);
        if (lockedObject.locked_by) {
          this.set_snackbar({
            text: lockedObject.locked_by + this.$t("EXCLUSIVE_NOTICE"),
            color: "rgba(153, 0, 0, 0.72)",
          });
          return;
        }
        // 削除処理
        if (this.obj.type === this.constants.objectType.ROAD) {
          window["viewer"].deleteRoad(this.obj.id);
        } else if (this.obj.type === this.constants.objectType.FLAT) {
          window["viewer"].removePolygon(this.obj.id);
        } else if (this.obj.type === this.constants.objectType.TRENCH) {
          window["viewer"].deleteTrench(this.obj.id);
        }
        await this.updateMeasurementView();
        await this.$store.dispatch("deleteObject", { siteId, obj: this.obj });
      } catch (error) {
        console.error(error);
        this.set_snackbar({
          text: `${this.$t("REMOVE")} ${this.$t("failed")}`,
          color: "rgba(153, 0, 0, 0.72)",
        });
      }
    },
    async updateMeasurementView() {
      for (let measurement of this.$store.state.measurement.measurementList) {

        if (measurement.isCrossSectionVisible) {
          await window["viewer"].updateMeasurement(String(measurement.measurementId), "crossSection");
        }

        if (measurement.isLinearVisible) {
          await window["viewer"].updateMeasurement(String(measurement.measurementId), "linear");
        }
      }
    },
    async fitToView() {
      try {
        // 他のオブジェクトをロック中の場合は解放する
        if (this.lockInfo && this.lockInfo.objId !== this.obj.id) {
          this.releaseLock();
        }
        this.clear2DViews();
        if (this.obj.type === this.constants.objectType.ROAD) {
          window["viewer"].fitToRoad(this.obj.id);
        } else if (this.obj.type === this.constants.objectType.FLAT) {
          window["viewer"].fitToFlatGround(this.obj.id);
        } else if (this.obj.type === this.constants.objectType.TRENCH) {
          window["viewer"].fitToTrench(this.obj.id);
        }
        this.updateObject2DViewVisibility(true, this.obj.id);
      } catch (err) {
        console.log("Something went wrong", err);
      }
    },
    exportByJson() {
      let geometry;
			// viewerに読み込まれていなければ読み込む（一時的に表示中にする）
			if (this.obj.type === 0) {
				geometry = window["viewer"].getRoadMeshGeometry(this.obj.id);
			} else if (this.obj.type === 1) {
				geometry = window["viewer"].getFlatGroundMeshGeometry(this.obj.id);
			} else if (this.obj.type === 7) {
				geometry = window["viewer"].getTrenchMeshGeometry(this.obj.id);
			}
      const event = new CustomEvent("openPropertyDialog", {
        detail: { type: 6, name: this.obj.name, geometry: geometry },
      });
      window.dispatchEvent(event);
    },
    async exclusiveCheck() {
      let editSiteData = await this.getSiteInfo();

      const isUpdated = dayjs(this.$store.state.initial_site_data.updated_at).isBefore(editSiteData.updated_at);

      // 現場が更新されていなければ排他制御を行わない
      if (!isUpdated) return false;

      // 自分以外が現場を更新していれば排他制御を行う
      return editSiteData.updated_by !== this.$store.state.user.id;
    },
    updateGlobeVisibility(visibility) {
      this.$emit("updateGlobeVisibility", visibility);
    },
    updateObject2DViewVisibility(show2DView, id) {
      this.$emit("updateObject2DViewVisibility", show2DView, id);
    },
    clear2DViews() {
      this.$emit("clear2DViews");
    },
    associatedPointData(objDelFlag) {
      this.roadVal = objDelFlag;
    },
    async getSiteInfo() {
      console.trace("getSiteInfo");
      const res = await axios.get(`${import.meta.env.VITE_API_BASE}/sites/${this.$route.query.siteId}`, {
        auth: this.$store.state.authObject
      });
      return res ? res.data : null;
    },
    async updateSiteInfo() {
      await axios.put(`${import.meta.env.VITE_API_BASE}/sites/touch/${this.$route.query.siteId}`, {
        auth: this.$store.state.authObject
      });
    },
    close2dValueInput() {
      this.showSlope2DInput = false;
    }
  },
  beforeUnmount() {
    // 削除時にviewerからも削除する
    if (this.obj?.isShow && !this.roadVal) {
      if (this.obj.type === this.constants.objectType.ROAD) {
        // 仮設道路追加を削除
        window["viewer"].deleteRoad(this.obj.id);
      } else if (this.obj.type === this.constants.objectType.FLAT) {
        // 平場追加を削除
        window["viewer"].removePolygon(this.obj.id);
      }
      // TODO 床掘追加を削除
      // else if (this.obj.type === this.constants.objectType.TRENCH) {}
    }
    // 摺り付け点群の場合
    this.roadVal = false;
  },
};
</script>

<style scoped>
.object-list-item {
  padding: 0;
  padding-left: 32px;
}

.obj-name-area {
  flex: 1;
  min-width: 0;
}

.menu-item-label {
  font-size: 13px;
}
</style>

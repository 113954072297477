<!-- 計測データ名のコンポーネント -->
<template>
  <div id="measurement-name">
    <!-- ファイル名表示 -->
    <span
      v-if="!isRenamingMode"
      class="ml-1">
      {{ measurement.measurementName }}
    </span>
    <!-- ファイル名編集 -->
    <span v-else>
        <VeeValidateForm :initial-values="{ name: measurement.measurementName }" v-slot="{ meta }">
            <VeeValidateField name="name" :rules="nameRules" v-slot="{ field, errorMessage }">
                <ErrorTooltip placement="bottom" :shown="!meta.valid" :triggers="[]">
                    <v-text-field
                        v-model="measurementName"
                        v-bind="field"
                        type="text"
                        :error="!meta.valid"
                        color="primary"
                        class="ma-0 pa-0"
                        hide-details=""
                        variant="outlined"
                        autofocus
                        style="font-size: 14px"
                        :loading="isLoading"
                        @blur="blur(meta.valid)"
                        @keydown.enter.prevent
                        @keyup.enter="enter(meta.valid)"
                        @keyup.escape="cancelUpdateFileName"
                    >
                    </v-text-field>
                    <template #popper>
                        <span>{{ errorMessage }}</span>
                    </template>
                </ErrorTooltip>
            </VeeValidateField>
        </VeeValidateForm>
    </span>
  </div>
</template>

<script>
import axios from "axios";
import {
	Form as VeeValidateForm,
	Field as VeeValidateField,
} from "vee-validate";
import { mapMutations, mapActions } from "vuex";
import ErrorTooltip from "@/components/common/ErrorTooltip";

export default {
	components: {
		VeeValidateForm,
		VeeValidateField,
		ErrorTooltip,
	},
	props: {
		measurement: Object,
		isRenamingMode: Boolean,
	},
	data() {
		return {
			measurementName: "",
			hasUpdatedError: false, // 前回のAPIリクエストのエラー有無
			nameRules: [
				(value) => {
					if (value) return true;
					return this.$t("REQUIRED_ERROR");
				},
				(value) => {
					if (value?.length <= 100) return true;
					return this.$t("MAX_LENGTH_ERROR");
				},
			],
		};
	},
	computed: {
		isLoading: {
			get() {
				return this.$store.state.isLoading;
			},
			set(value) {
				this.$store.dispatch("updateIsLoading", value);
			},
		},
	},
	watch: {
		measurementName() {
			// 変更があればサーバーエラー解除
			if (this.hasUpdatedError) this.hasUpdatedError = false;
		},
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		...mapActions("measurement", ["getMeasurementList"]),
		async blur(valid) {
			// 前回のリクエストが終わっていない場合はリクエストを送らない（多重リクエスト防止）
			if (this.isLoading) return;

			// 前回のリクエストでエラーが発生していた場合はフォーカスアウト時にリクエスト送信しない
			if (this.hasUpdatedError) {
				this.cancelUpdateFileName();
				return;
			}

			// 前回のリクエストが終わっていない場合はリクエストを送らない
			if (this.isLoading) return;

			// バリデーションエラーがある場合編集キャンセル
			if (!valid) {
				this.cancelUpdateFileName();
				return;
			}
			// ファイル名に変更がなければリクエストを送らない
			if (this.measurementName === this.measurement.measurementName) {
				this.endNameEditing();
				return;
			}
			// リクエスト成功の場合は編集モード終了、失敗の場合は変更を戻す
			const status = await this.updateFileName();
			if (status) {
				this.endNameEditing();
			} else {
				this.cancelUpdateFileName();
			}
		},
		async enter(valid) {
			// 前回のリクエストが終わっていない場合はリクエストを送らない（多重リクエスト防止）
			if (this.isLoading) return;

			// バリデーションエラーがある場合Enterキーを押しても確定させない
			if (!valid) {
				return;
			}
			// ファイル名に変更がなければリクエストを送らない
			if (this.measurementName === this.measurement.measurementName) {
				this.endNameEditing();
				return;
			}
			// リクエスト成功の場合は編集モード終了
			const status = await this.updateFileName();
			if (status) this.endNameEditing();
		},
		// ファイル名変更
		async updateFileName() {
			const body = {
				two_points_picker_name: this.measurementName,
			};
			const status = await this.request(body);
			return status;
		},
		// ファイル名変更キャンセル
		cancelUpdateFileName() {
			this.measurementName = this.measurement.measurementName;
			this.endNameEditing();
		},
		// ファイル名編集開始と終了
		endNameEditing() {
			this.hasUpdatedError = false;
			this.$emit("end-rename");
		},
		async request(body) {
			const successMessage = `${this.$t("UPDATE_DATA")} ${this.$t("successful")}`;
			const errorMessage = `${this.$t("UPDATE_DATA")} ${this.$t("failed")}`;
			try {
				// ローディング開始
				this.isLoading = true;
				// 登録リクエスト
				const res = await axios.put(
					`${import.meta.env.VITE_API_BASE}/twoPointsPicker/${this.measurement.measurementId}`,
					body,
				);
				if (res.status === 200) {
					// データリスト更新
					await this.getMeasurementList(this.$route.query.siteId);
					this.set_snackbar({
						text: successMessage,
						color: "rgba(0, 153, 0, 0.72)",
					});
					return res.status;
				} else {
					// 想定外のステータスコードが返却された場合エラーをスロー
					throw new Error(errorMessage);
				}
			} catch {
				this.hasUpdatedError = true;
				this.set_snackbar({
					text: errorMessage,
					color: "rgba(153, 0, 0, 0.72)",
				});
				return "";
			} finally {
				// ローディング解除
				this.isLoading = false;
			}
		},
	},
	created() {
		this.measurementName = this.measurement.measurementName;
	},
};
</script>

<style lang="sass" scoped>
#measurement-name :deep(input.v-field__input)
  padding: 0 6px
  min-height: 0px
</style>
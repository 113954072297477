<template>
  <v-expand-transition>
    <div v-show="obj.isOpen">
      <v-form v-model="isValid" ref="form">
        <v-card-text class="py-0 pr-0">
          <!-- 道路・摺り付け -->
          <v-card-actions class="pr-0">
            {{ $t("Trenchparameter") }}
            <v-btn class="ml-auto" height="20px" icon @click="togglePanel(0)">
              <v-icon>
                {{ panel0 ? "icon:Chevron-Up" : "icon:Chevron-Down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="panel0" class="trench-parameter">
              <v-table density="compact">
                <tbody>
                  <!-- 道路長さ -->
                  <tr>
                    <td>
                      {{ $t("Lengthoftrench") }}
                    </td>
                    <td>{{ obj.info.length.toFixed(3) }}m</td>
                  </tr>
                  <!-- 道路幅 -->
                  <tr>
                    <td>{{ $t("Widthoftrench") }} </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                        max="9999.999" step="0.001" suffix="m" :disabled="!obj.edit" @blur="onBlur"
                        v-model.number="obj.settings.commonSettings.width"
                        :rules="[rules.orLess(9999.999), rules.orMore(0), rules.decimalLength(3), rules.numberFormat]" />
                    </td>
                  </tr>
                  <!-- 道路範囲 -->
                  <tr>
                    <td>
                      {{ $t("Elevationarea") }}
                    </td>
                    <td>{{ obj.info.height.min.toFixed(3) }}m - {{ obj.info.height.max.toFixed(3) }}m</td>
                  </tr>
                  <!-- 勾配範囲 -->
                  <tr>
                    <td>
                      {{ $t("Gradientarea") }}
                    </td>
                    <td>
                      <SlopeRangeComponent :min="obj.info.slope.min" :max="obj.info.slope.max"
                        :suffix="getSuffix(trenchParameterSlopeUnit.gradientArea)">
                      </SlopeRangeComponent>
                    </td>
                  </tr>
                  <!-- 擦り付け先 -->
                  <tr>
                    <td>
                      {{ $t("RUBBLING_DESTINATION") }}
                    </td>
                    <td>
                      <v-select variant="plain" v-model="obj.settings.commonSettings.destination.target"
                        :disabled="!obj.edit" @update:model-value="updateRubbingDestination()"
                        :items="rubbingDestinations" item-title="label" item-value="value" hide-details
                        density="compact" class="ma-0"></v-select>
                    </td>
                  </tr>
                  <tr v-if="obj.settings.commonSettings.destination.target === 0">
                    <td>{{ $t("REFERENCE_POINT_CLOUD_ASSET") }}</td>
                    <td>
                      <v-select v-if="pointClouds.length > 0" variant="plain" v-model="obj.settings.commonSettings.destination.id"
                        :disabled="!obj.edit" :items="pointClouds" :item-title="(item) => item && item.getName()"
                        :item-value="(item) => item && item.getAssetIdNum()" density="compact" hide-details class="ma-0"
                        @update:model-value="updateSettings()">
                      </v-select>
                    </td>
                  </tr>
                  <tr v-if="obj.settings.commonSettings.destination.target === 1">
                    <td>{{ $t("REFERENCE_DESIGN_ASSET") }}</td>
                    <td>
                      <v-select variant="plain" v-model="obj.settings.commonSettings.destination.id"
                        :disabled="!obj.edit" :items="designs" :item-title="(item) => item && item.getName()"
                        :item-value="(item) => item && item.getAssetIdNum()" density="compact" hide-details class="ma-0"
                        @update:model-value="updateSettings()">
                      </v-select>
                    </td>
                  </tr>
                  <tr v-if="obj.settings.commonSettings.destination.target === 2">
                    <td>{{ $t("REFERENCE_FLAT_GROUND") }}</td>
                    <td>
                      <v-select variant="plain" v-model="obj.settings.commonSettings.destination.id"
                        :disabled="!obj.edit" :items="flatGrounds" :item-title="item => item.name"
                        :item-value="item => item.id" density="compact" hide-details class="ma-0"
                        @update:model-value="updateSettings()">
                      </v-select>
                    </td>
                  </tr>
                  <tr v-if="obj.settings.commonSettings.destination.target === 3">
                    <td>{{ $t("SPECIFIED_ALTITUDE") }}</td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="-9999.999"
                        max="9999.999" step="0.001" suffix="m" :disabled="!obj.edit" @blur="onBlur"
                        v-model.number="obj.settings.commonSettings.destination.specifiedAltitude"
                        :rules="[rules.required0, rules.orLess(9999.999), rules.orMore(-9999.999), rules.numberFormat]" />
                    </td>
                  </tr>
                  <!-- <tr v-if="obj.settings.commonSettings.destination.target === 4">
                    <td>{{ $t("REFERENCE_TOPOLOGY") }}</td>
                    <td>
                      <v-select variant="plain" v-model="obj.settings.commonSettings.destination.id"
                        :disabled="!obj.edit" :items="drawing2dImages" :item-title="(item) => item && item.getName()"
                        :item-value="(item) => item && item.getAssetId()" density="compact" hide-details class="ma-0"
                        @update:model-value="updateSettings()">
                      </v-select>
                    </td>
                  </tr> -->
                  <!-- 摺り付けピッチ -->
                  <tr>
                    <td>
                      {{ $t("InterpolationPitch") }}
                      <span class="text-red-lighten-3">*</span>
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                        max="9999.999" step="0.001" suffix="m" model-value="5" :disabled="!obj.edit" @blur="onBlur"
                        v-model.number="obj.settings.commonSettings.interpolationPitch"
                        :rules="[rules.orLess(9999.999), rules.orMore(0.001), rules.decimalLength(3), rules.numberFormat]" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("Longitudinal_Slope_Type") }}
                    </td>
                    <td>
                      <v-select variant="plain" v-model="obj.settings.commonSettings.longitudinalSlope.slopeType"
                        :items="longitudinalSlopeType" :disabled="!obj.edit" item-title="label" item-value="value"
                        hide-details density="compact" class="ma-0"
                        v-on:update:model-value="updateSettings()"></v-select>
                    </td>
                  </tr>
                  <tr v-if="enableSpecifiedElevation">
                    <td class="text-pre-wrap">
                      <span v-text="$t('Specified_Elevation')"></span>
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="-9999.999"
                        max="9999.999" step="0.001" suffix="m" :disabled="!obj.edit" @blur="onBlur"
                        v-model.number="obj.settings.commonSettings.specifiedElevation"
                        :rules="[rules.required0, rules.orMore(-9999.999), rules.orLess(9999.999), rules.decimalLength(3), rules.numberFormat]" />
                    </td>
                  </tr>
                  <tr v-if="enableSlopeValue">
                    <td>
                      {{ $t("Longitudinal_Slope_Value") }}
                    </td>
                    <td>
                      <SlopeSettingInput :suffix-to-display="getSuffix(trenchLongitudinalSlopeUnit)"
                        :is-editable="obj.edit" :slope-value="obj.settings.commonSettings.longitudinalSlope.slopeValue"
                        :step="0.001" @blur="onBlur"
                        v-model.number="obj.settings.commonSettings.longitudinalSlope.slopeValue"
                        :max-value="trenchLongitudinalSlopeUnit === 'percent' ? 9999.99 : 999.999"
                        :min-value="trenchLongitudinalSlopeUnit === 'percent' ? -100 : 0.001" :decimal-length="3"
                        :un-valid-zero="trenchLongitudinalSlopeUnit === 'percent'"></SlopeSettingInput>
                    </td>
                  </tr>
                  <tr v-if="enableDepth">
                    <td>
                      {{ $t("TRENCH_DEPTH") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0.001"
                        max="9999.999" step="0.001" suffix="m" :disabled="!obj.edit" @blur="onBlur"
                        v-model.number="obj.settings.commonSettings.longitudinalSlope.depth"
                        :rules="[rules.orLess(9999.999), rules.orMore(0.001), rules.decimalLength(3), rules.numberFormat]" />
                    </td>
                  </tr>
                  <!-- 路面色 -->
                  <tr>
                    <td>
                      {{ $t("Trenchsurfacecolor") }}
                    </td>
                    <td>
                      <v-menu :disabled="!obj.edit">
                        <template v-slot:activator="{ props }">
                          <v-avatar :color="obj.settings.commonSettings.surfaceColor" size="20px" v-bind="props">
                          </v-avatar>
                        </template>
                        <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                          v-model="obj.settings.commonSettings.surfaceColor"></v-color-picker>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <IndividualSegmentSettingList
                v-show="obj.settings.commonSettings.segmentSettings && obj.settings.commonSettings.segmentSettings.length > 0"
                style="width: 100%" :trenchId="obj.id" :is-editable="obj.edit"
                :suffix="getSuffix(trenchParameterSlopeUnit.gradientArea)"
                :setting-list="obj.settings.commonSettings.segmentSettings"></IndividualSegmentSettingList>
            </div>
          </v-expand-transition>
          <!-- 切土設定 -->
          <v-card-actions class="pr-0">
            {{ $t("Cutsetting") }}
            <v-btn class="ml-auto" height="20px" icon @click="togglePanel(1)">
              <v-icon>
                {{ panel1 ? "icon:Chevron-Up" : "icon:Chevron-Down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="panel1">
              <v-table density="compact">
                <tbody>
                  <!-- 法面高さ -->
                  <tr>
                    <td>
                      {{ $t("Slopeheight") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                        step="0.01" suffix="m" :disabled="cutSlopeCondition"
                        :rules="cutSlopeCondition ? [] : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                        v-model.number="obj.settings.cutSettings.trenchHeight" @blur="onBlur" />
                    </td>
                  </tr>
                  <tr>
                    <!-- 法面勾配 -->
                    <td>
                      {{ $t("Slopegradient") }}
                    </td>
                    <td>
                      <SlopeSettingInput :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                        :is-editable="obj.edit && !obj.settings.cutSettings.bLeftRightSettings"
                        :slope-value="obj.settings.cutSettings.moundSlope" :step="0.01" @blur="onBlur"
                        v-model.number="obj.settings.cutSettings.moundSlope"
                        :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                        :min-value="0.01" :decimal-length="2" :un-valid-zero="false">
                      </SlopeSettingInput>
                    </td>
                  </tr>
                  <!-- 小段幅 -->
                  <tr>
                    <td>
                      {{ $t("Bermwidth") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                        step="0.01" suffix="m" :disabled="cutSlopeCondition"
                        :rules="cutSlopeCondition ? [] : [rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                        v-model.number="obj.settings.cutSettings.shelfWidth" @blur="onBlur" />
                    </td>
                  </tr>
                  <!-- 小段勾配 -->
                  <tr>
                    <td>
                      {{ $t("Bermgradient") }}
                    </td>
                    <td>
                      <SlopeSettingInput :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                        :is-editable="!cutSlopeCondition" :slope-value="obj.settings.cutSettings.shelfSlope"
                        :step="0.01" @blur="onBlur" v-model.number="obj.settings.cutSettings.shelfSlope"
                        :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                        :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01" :decimal-length="2"
                        :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                      </SlopeSettingInput>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{ $t("LeftRightSettings") }}
                    </td>
                    <td>
                      <v-checkbox hide-details density="compact" class="ma-0" :disabled="!obj.edit"
                        @update:model-value="updateSettings()"
                        v-model="obj.settings.cutSettings.bLeftRightSettings"></v-checkbox>
                    </td>
                  </tr>
                  <!-- 小段あり -->
                  <tr>
                    <td>
                      {{ $t("Thereisberm") }}
                    </td>
                    <td>
                      <v-checkbox hide-details density="compact" class="ma-0" :disabled="!obj.edit"
                        @update:model-value="updateSettings()"
                        v-model="obj.settings.cutSettings.generateShelf"></v-checkbox>
                    </td>
                  </tr>
                  <!-- 道路色 -->
                  <tr>
                    <td>
                      {{ $t("Trenchcolor") }}
                    </td>
                    <td>
                      <v-menu :disabled="!obj.edit">
                        <template v-slot:activator="{ props }">
                          <v-avatar :color="obj.settings.cutSettings.color" size="20px" v-bind="props">
                          </v-avatar>
                        </template>
                        <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                          v-model="obj.settings.cutSettings.color"></v-color-picker>
                      </v-menu>
                    </td>
                  </tr>
                </tbody>
              </v-table>
              <!-- 左右設定 -->
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-title class="py-0 px-2">
                    {{ $t("Leftrightsetting") }}
                  </v-expansion-panel-title>
                  <v-expansion-panel-text class="pa-0 pt-2">
                    <v-row class="pa-0">
                      <v-col class="pa-0">
                        {{ $t("Leftcutsetting") }}
                        <v-table density="compact">
                          <tbody>
                            <!-- 法面高さ -->
                            <tr>
                              <td>
                                {{ $t("Slopeheight") }}
                              </td>
                              <td>
                                <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                                  step="0.01" suffix="m" :disabled="cutLeftRightCondition"
                                  :rules="cutLeftRightCondition ? [] : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                                  v-model.number="obj.settings.cutSettings.leftSlopeHeight" @blur="onBlur" />
                              </td>
                            </tr>
                            <!-- 法面勾配 -->
                            <tr>
                              <td>
                                {{ $t("Slopegradient") }}
                              </td>
                              <td>
                                <SlopeSettingInput :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                  :is-editable="obj.edit && obj.settings.cutSettings.bLeftRightSettings"
                                  :slope-value="obj.settings.cutSettings.leftMoundSlope" :step="0.01" @blur="onBlur"
                                  v-model.number="obj.settings.cutSettings.leftMoundSlope"
                                  :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>
                              </td>
                            </tr>
                            <!-- 小段幅 -->
                            <tr>
                              <td>
                                {{ $t("Bermwidth") }}
                              </td>
                              <td>
                                <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                                  step="0.01" suffix="m" :disabled="cutLeftRightCondition"
                                  :rules="cutLeftRightCondition ? [] : [rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                                  v-model.number="obj.settings.cutSettings.leftShelfWidth" @blur="onBlur" />
                              </td>
                            </tr>
                            <!-- 小段勾配 -->
                            <tr>
                              <td>
                                {{ $t("Bermgradient") }}
                              </td>
                              <td>
                                <SlopeSettingInput :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                  :is-editable="!cutLeftRightCondition"
                                  :slope-value="obj.settings.cutSettings.leftShelfSlope" @blur="onBlur" :step="0.01"
                                  v-model.number="obj.settings.cutSettings.leftShelfSlope"
                                  :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                  :decimal-length="2" :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                </SlopeSettingInput>
                              </td>
                            </tr>
                          </tbody>
                        </v-table>
                        {{ $t("Rightcutsetting") }}
                        <v-table density="compact" style="background-color: rgba(41, 41, 47, 0)">
                          <tbody>
                            <!-- 法面高さ -->
                            <tr>
                              <td>
                                {{ $t("Slopeheight") }}
                              </td>
                              <td>
                                <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                                  step="0.01" suffix="m" :disabled="cutLeftRightCondition"
                                  :rules="cutLeftRightCondition ? [] : [rules.required, rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                                  v-model.number="obj.settings.cutSettings.rightSlopeHeight" @blur="onBlur" />
                              </td>
                            </tr>
                            <!-- 法面勾配 -->
                            <tr>
                              <td>
                                {{ $t("Slopegradient") }}
                              </td>
                              <td>
                                <SlopeSettingInput :suffix-to-display="getSuffix(cutSettingSlopeUnit.slopeGradient)"
                                  :is-editable="obj.edit && obj.settings.cutSettings.bLeftRightSettings"
                                  :slope-value="obj.settings.cutSettings.rightMoundSlope" :step="0.01" @blur="onBlur"
                                  v-model.number="obj.settings.cutSettings.rightMoundSlope"
                                  :max-value="cutSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="0.01" :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>
                              </td>
                            </tr>
                            <!-- 小段幅 -->
                            <tr>
                              <td>
                                {{ $t("Bermwidth") }}
                              </td>
                              <td>
                                <v-text-field variant="underlined" hide-details density="compact" type="number" min="0"
                                  step="0.01" suffix="m" :disabled="cutLeftRightCondition"
                                  :rules="cutLeftRightCondition ? [] : [rules.orLess(99.99), rules.orMore(0.01), rules.decimalLength(2), rules.numberFormat]"
                                  v-model.number="obj.settings.cutSettings.rightShelfWidth" @blur="onBlur" />
                              </td>
                            </tr>
                            <!-- 小段勾配 -->
                            <tr>
                              <td>
                                {{ $t("Bermgradient") }}
                              </td>
                              <td>
                                <SlopeSettingInput :suffix-to-display="getSuffix(cutSettingSlopeUnit.bermGradient)"
                                  :is-editable="!cutLeftRightCondition"
                                  :slope-value="obj.settings.cutSettings.rightShelfSlope" @blur="onBlur" :step="0.01"
                                  v-model.number="obj.settings.cutSettings.rightShelfSlope"
                                  :max-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                                  :min-value="cutSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01"
                                  :decimal-length="2" :un-valid-zero="cutSettingSlopeUnit.bermGradient === 'percent'">
                                </SlopeSettingInput>
                              </td>
                            </tr>
                          </tbody>
                        </v-table>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-expand-transition>
          <!-- 土量 -->
          <v-card-actions class="pr-0" v-show="config.useSoilVolumeCalculation">
            {{ $t("Soilvolume") }}
            <v-btn height="20px" icon :disabled="!obj.edit" @click="calculateTrenchVolume(obj)" class="ml-3"
              title="ボリュームを計算する" :loading="obj.loadingVolume">
              <v-icon> mdi-play-circle-outline </v-icon>
            </v-btn>
            <v-btn class="ml-auto" height="20px" icon @click="togglePanel(3)">
              <v-icon>
                {{ isPanelActive(3) ? "icon:Chevron-Up" : "icon:Chevron-Down" }}
              </v-icon>
            </v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div v-show="isPanelActive(3)">
              <v-table density="compact">
                <tbody>
                  <tr>
                    <td>
                      {{ $t("Cut") }}
                    </td>
                    <td>
                      <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.1"
                        :suffix="$t('METERS_CUBED_UNIT')" readonly v-model="obj.cutVolume" />
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </div>
          </v-expand-transition>
          <v-card-actions class="py-0 mr-3">
            {{ $t("OPACITY") }}
            <v-slider @end="saveChanges()" v-model="obj.transparency" :disabled="!obj.edit" hide-details max="1"
              step="0.001" class="ml-1" color="primary" track-size="0.5" thumb-size="14"></v-slider>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </div>
  </v-expand-transition>
</template>

<script>
import "@/assets/icons/style.css";
import config from "@/config.json";
import SlopeSettingInput from "../SlopeSettingInput.vue";
import IndividualSegmentSettingList from "../IndividualSegmentSettingList.vue";
import SlopeRangeComponent from "../SlopeRangeComponent.vue";
import {
	uploadObjAndNotify,
	updateTrenchSettingsInViewer,
} from "../../../utils/cesium-common";
import * as constants from "@/constant.js";
import * as cesiumCommon from "@/utils/cesium-common";
import {
	LongitudinalSlopeType,
	RubbingDestination,
	UnitTypes,
	objectType,
} from "../../../constant";
import { useObjectPanel } from "./composables/useObjectPanel";

export default {
	setup(props) {
		const { obj, isValid, form, saveChanges } = useObjectPanel(props.objId);
		return {
			obj,
			isValid,
			form,
			saveChanges,
		};
	},
	name: "TrenchPanel",
	components: {
		SlopeSettingInput,
		IndividualSegmentSettingList,
		SlopeRangeComponent,
	},
	props: {
		objId: String,
		rules: Object,
	},
	computed: {
		panel0() {
			return this.isPanelActive(0);
		},
		panel1() {
			return this.isPanelActive(1);
		},
		panel2() {
			return this.isPanelActive(2);
		},
		designs() {
			return this.$store.state.design_list.filter(
				(item) => item.getIsAssetUploaded() === "DONE",
			);
		},
		pointClouds() {
			return this.$store.state.point_cloud_list.filter(
				(item) => item.getIsAssetUploaded() === "DONE",
			);
		},
		flatGrounds() {
			return this.$store.state.objects.filter(
				(item) => item.type === objectType.FLAT,
			);
		},
		drawing2dImages() {
			return this.$store.getters["drawing2dImage/getDrawing2dImageList"].filter(
				(drawing2dImage) => drawing2dImage.getIsVisible(),
			);
		},
		trenchParameterSlopeUnit() {
			return this.$store.state.trenchParameterSlopeUnit;
		},
		cutSettingSlopeUnit() {
			return this.$store.state.trenchCutSettingSlopeUnit;
		},
		trenchLongitudinalSlopeUnit() {
			return this.$store.state.trenchLongitudinalSlopeUnit;
		},
		// Disabling condition for cut setting's - shelfSlope, shelfWidth
		cutSlopeCondition() {
			return (
				!this.obj.edit ||
				this.obj.settings.cutSettings.bLeftRightSettings ||
				!this.obj.settings.cutSettings.generateShelf
			);
		},
		// Disabling condition for cut setting's - leftShelfWidth, leftShelfSlope, rightShelfWidth, rightShelfSlope
		cutLeftRightCondition() {
			return !(
				this.obj.edit &&
				this.obj.settings.cutSettings.bLeftRightSettings &&
				this.obj.settings.cutSettings.generateShelf
			);
		},
		rubbingDestinations() {
			const result = [];
			if (this.pointClouds.length > 0) {
				result.push({
					label: this.$t("POINT_CLOUD"),
					value: RubbingDestination.POINT_CLOUD,
				});
			}
			if (this.designs.length > 0) {
				result.push({
					label: this.$t("DESIGN"),
					value: RubbingDestination.DESIGN_DATA,
				});
			}
			if (this.flatGrounds.length > 0) {
				result.push({
					label: this.$t("FLAT_GROUND"),
					value: RubbingDestination.FLAT_GROUND,
				});
			}
			if (this.drawing2dImages.length > 0) {
				result.push({
					label: this.$t("REFERENCE_TOPOLOGY"),
					value: RubbingDestination.REFERENCE_TOPLOGY,
				});
			}
			result.push({
				label: this.$t("SPECIFIED_ALTITUDE"),
				value: RubbingDestination.SPECIFIED_ALTITUDE,
			});
			return result;
		},
		longitudinalSlopeType() {
			return [
				{
					label: this.$t("LS_DEFAULT"),
					value: LongitudinalSlopeType.DEFAULT,
				},
				{
					label: this.$t("LS_FLAT"),
					value: LongitudinalSlopeType.FLAT,
				},
				{
					label: this.$t("LS_CONSTANT"),
					value: LongitudinalSlopeType.CONSTANT,
				},
				{
					label: this.$t("LS_NONE"),
					value: LongitudinalSlopeType.NONE,
				},
			];
		},
		enableSlopeValue() {
			return this.isSlopeType("constant");
		},
		enableSpecifiedElevation() {
			return (
				this.isSlopeType("flat") ||
				this.isSlopeType("constant") ||
				this.isSlopeType("default")
			);
		},
		enableDepth() {
			return this.isSlopeType("none");
		},
	},
	watch: {
		"obj.settings.commonSettings.destination.target"() {
			this.setInitialDestination();
		},
		async "obj.settings.commonSettings.longitudinalSlope.slopeType"() {
			// 床掘下面標高を使用する場合
			if (this.enableSpecifiedElevation) {
				// 始点の床掘下面標高が未入力の場合デフォルト値を設定
				if (this.obj.settings.commonSettings.specifiedElevation === null) {
					if (this.elevation === "") {
						this.setElevation();
					}
					this.setSpecifiedElevation();
				}
			}

			// 勾配値を設定不可能な場合
			if (!this.enableSlopeValue) {
				// 初期値にする
				this.obj.settings.commonSettings.longitudinalSlope.slopeValue = 0;
			}

			// 床掘高さを設定可能な場合
			if (this.enableDepth) {
				// 床掘高さが未入力の場合デフォルト値を設定
				if (this.obj.settings.commonSettings.longitudinalSlope.depth === null) {
					this.obj.settings.commonSettings.longitudinalSlope.depth = 1;
				}
			}
		},
	},
	data() {
		return {
			config,
			activePanel: null,
			elevation: "",
		};
	},
	methods: {
		setSpecifiedElevation() {
			const specifiedElevation = this.elevation - 1;
			this.obj.settings.commonSettings.specifiedElevation =
				+specifiedElevation.toFixed(3);
		},
		getSuffix(unitType) {
			const unit = UnitTypes.find((type) => type.value === unitType);
			return unit.symbol;
		},
		isNum(val) {
			return typeof val == "number";
		},
		async onBlur() {
			this.$store.dispatch("startObjectUpdating");
			await this.updateSettings();
		},
		async updateSettings() {
			return this.$nextTick(async () => {
				this.$refs.form?.validate();
				if (!this.isValid || !this.obj.settings.commonSettings.destination.id)
					return;
				await updateTrenchSettingsInViewer(this.obj.id, this.obj);
				await uploadObjAndNotify(this.obj, constants.objectType.TRENCH);
			});
		},
		async updateRubbingDestinationId() {
			let assetId;
			if (
				this.obj.settings.commonSettings.destination.target ===
					RubbingDestination.POINT_CLOUD ||
				this.obj.settings.commonSettings.destination.target ===
					RubbingDestination.DESIGN_DATA
			) {
				assetId = this.obj.settings.commonSettings.destination.id;
			} else {
				assetId = JSON.parse(this.obj.json).associatePointCloudId;
			}
			await cesiumCommon.toggleAssociatedPointCloudVisibility(assetId);
			this.updateSettings();
		},
		async updateRubbingDestination() {
			this.setInitialDestination();
			if (
				this.obj.settings.commonSettings.destination.target ===
				RubbingDestination.SPECIFIED_ALTITUDE
			) {
				this.setDefaultSpecifiedAltitude();
			}
			await this.updateRubbingDestinationId();
		},
		isPanelActive(id) {
			return this.activePanel === id;
		},
		togglePanel(id) {
			if (this.activePanel === id) {
				this.activePanel = null; // アクティブなアコーディオンを閉じる
			} else {
				this.activePanel = id; // 新しいアコーディオンをアクティブにする
			}
		},
		async calculateTrenchVolume(obj) {
			obj.loadingVolume = true;
			try {
				const res = window["viewer"].volumeCalculationForTrench(obj.id);
				obj.cutVolume = res.cutVolume?.toFixed(3);
				obj.fillVolume = res.fillVolume?.toFixed(3);
			} catch (error) {
				console.error(error.message);
			} finally {
				obj.loadingVolume = false;
			}
			this.$forceUpdate();
		},
		unit() {
			return this.$store.state.slopeUnit;
		},
		isSlopeType(slopeType) {
			return (
				this.obj.settings.commonSettings.longitudinalSlope.slopeType ===
				slopeType
			);
		},
		setInitialDestination() {
			if (
				this.obj.settings.commonSettings.destination.target ===
				RubbingDestination.POINT_CLOUD
			) {
				// 点群の場合はアクティブなものを設定
				this.obj.settings.commonSettings.destination.id =
					this.$store.getters.activePointCloud?.getAssetIdNum() || "";
			} else if (
				this.obj.settings.commonSettings.destination.target ===
				RubbingDestination.DESIGN_DATA
			) {
				// 設計データリストを作成日時の降順に並べる
				const designList = this.designs.slice();
				designList.sort(
					(a, b) => new Date(b.getCreatedAt()) - new Date(a.getCreatedAt()),
				);
				if (designList.length) {
					this.obj.settings.commonSettings.destination.id =
						designList[0].getAssetIdNum();
				} else {
					this.obj.settings.commonSettings.destination.id = "";
				}
			} else if (
				this.obj.settings.commonSettings.destination.target ===
				RubbingDestination.FLAT_GROUND
			) {
				if (this.flatGrounds.length) {
					// 平場があれば作成日時最新の平場を選択済みにする
					this.obj.settings.commonSettings.destination.id =
						this.flatGrounds[this.flatGrounds.length - 1].id;
				} else {
					this.obj.settings.commonSettings.destination.id = "";
				}
			}
		},
		setElevation() {
			this.elevation = window["viewer"].getReferencePointElevationOfTrench(
				this.obj.id,
			);
		},
		setDefaultSpecifiedAltitude() {
			const specifiedAltitude = window[
				"viewer"
			].getAverageHeightOfFirstAndLastPointOfTrench(this.obj.id);
			if (specifiedAltitude) {
				this.obj.settings.commonSettings.destination.specifiedAltitude =
					+specifiedAltitude.toFixed(3);
			}
		},
	},
};
</script>

<style scoped>
div.trench-parameter .v-data-table table {
  table-layout: fixed
}

tr td {
  padding: 0 8px !important;
}

tr td:first-child {
  min-width: 84px;
}
</style>

import store from "@/store/index";
import { i18n } from "@/i18n.js";
import axios from "axios";
import objectLockWebSocket from "@/utils/objectLockWebSocket";

/**
 * 現場のロック情報の一覧を取得
 */
export const callGetLockInfoRequest = async (siteId) => {
	return await axios.get(
		`${import.meta.env.VITE_API_BASE}/sites/wslockinfo/${siteId}`,
	);
};

/**
 * 指定したオブジェクトをロック中かを確認する
 * @param {*} siteId オブジェクトと紐づく現場
 * @param {*} jsonId 確認対象のオブジェクト
 * @returns 確認対象オブジェクトのロック状況
 */
export const checkLockStatus = async (siteId, jsonId) => {
	const retValue = {
		isLocked: false, // 自身含むいずれかのユーザーがロック中であればTRUE
		locked_by: null, // 自分以外がロックしている場合のみユーザー名を設定
	};
	const res = await callGetLockInfoRequest(siteId);
	const lockedObject = res.data.find((lock) => lock.json_name === jsonId);
	if (lockedObject) {
		retValue.isLocked = true;
		if (lockedObject.locked_by !== store.state.user.id) {
			retValue.locked_by = lockedObject.user_name;
		}
		return retValue;
	}
	return retValue;
};

/**
 * 指定したオブジェクトのロックを獲得する
 * コネクションが確立されていない場合エラー
 */
export const lock = (jsonId) => {
	if (objectLockWebSocket.isOpen()) {
		objectLockWebSocket.sendLockEvent(jsonId);
	} else {
		store.commit("set_snackbar", {
			text: i18n.global.t("CONNECTION_LOST_LOCK_ERROR"),
			color: "rgba(153, 0, 0, 0.72)",
		});
	}
};

/**
 * ロック継続処理を停止し、取得中のロック解放する
 */
export const releaseLock = () => {
	store.dispatch("lock/stopLockUpdate");
	objectLockWebSocket.sendUnLockEvent();
};

<script setup>
import { mapState } from "vuex";
const props = defineProps({
	position: Object, //position in 2d Sreen
	source: Object, // {x,y,z}
	destination: Object, //{x,y,z}
	color: String,
	locked: Boolean,
});
</script>
<template>
  <v-card @click="lockPoint" class="fixedPos" :style="{'border': `solid ${locked ? color : 'transparent'}` }"> 
    <v-container class="d-flex flex-column">
      <span>Source</span>
      <div class="d-flex flex-row ga-sm-1">
        <span> x:</span>
        <span> {{ source.x }}</span>
      </div>
      <div class="d-flex flex-row ga-sm-1">
        <span> y:</span>
        <span> {{ source.y }}</span>
      </div>
      <div class="d-flex flex-row ga-sm-1">
        <span> z:</span>
        <span> {{ source.z }}</span>
      </div>
    </v-container>
    <v-container>
      <span>Destination</span>
      <div v-if="showInput">
        <v-form>
        <v-text-field hide-details hide-spin-buttons type="number" suffix="m" v-model.number="destination.x" label="x"/>
        <v-text-field hide-details hide-spin-buttons type="number" suffix="m" v-model.number="destination.y" label="y"/>
        <v-text-field disabled hide-details hide-spin-buttons type="number" suffix="m" v-model.number="destination.z" label="z"/>
      </v-form>
    </div>
    <div v-if="!showInput">
      <span>{{ `x:${destination.x} y: ${destination.y} z:${destination.z}` }}</span>
</div>
</v-container>
  </v-card>
</template>


<script>
export default {
  data(){
    return {
      showInput: true,
      point: {x: undefined, y: undefined, z: undefined}
    }
  },
  computed:{
    ...mapState("drawing2dImage", ["pointsOfTwoPointAlignment"]),
    isLockStart(){
      const count = this.pointsOfTwoPointAlignment.filter(item => !!item).length;
      return count === 2;
    }
  },
  methods:{
    lockPoint(e){
      if(this.isLockStart){
        this.$emit("onLock");
      }
    },
    
    onChangeX(){
      console.log("x", this.point.x);
    },
    onChangeY(){
      console.log("y", this.point.y);
    },
    onChangeZ(){
      console.log("z", this.point.z);
    }
    
  },
  mounted(){
    console.log("Floating Div mounted")
  }
}
</script>

<style lang="css" scoped>
.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    background-color: transparent;
}
.fixedPos {
  position: absolute;
  display: block;
  width: fit-content;
  z-index: 9999;
}
</style>
<template>
  <v-card>
    <div id="sectionView" tile class="pa-0 chart-board" @resize="onresize()"
      ref="section_view"></div>
  </v-card>
</template>

<script>
import "./style.css";

export default {
	name: "SectionViewDialog",
	props: {
		id: String,
	},

	mounted() {
		this.$nextTick(function () {
			this.update();
			this.onScreenResize();
		});
	},
	data: () => ({}),
	methods: {
		onScreenResize() {
			window.addEventListener("resize", () => {
				this.update();
			});
		},
		update() {
			const canvas =
				window["viewer"] && window["viewer"].getCanvasForView("sectionView");
			const element = this.$refs.section_view;
			if (canvas && element) {
				for (const node of element.children) {
					element.removeChild(node);
				}
				element.appendChild(canvas);
				canvas.height = element.getBoundingClientRect().height;
				canvas.width = element.getBoundingClientRect().width;
				window["viewer"].resize2dView(
					{ width: canvas.width, height: canvas.height },
					"sectionView",
				);
			}
		},
	},
	onresize: () => {
		this.update();
	},
};
</script>

<template>
	<v-card class="groupware-file-picker-card">
		<v-card-title class="d-flex align-center justify-space-between">
			{{ $t("IMPORT_FROM_GROUPWARE") }}
			<v-btn icon variant="flat" @click="close(false)">
				<v-icon> mdi-close </v-icon>
			</v-btn>
		</v-card-title>
		<v-container>
			<div class="groupware-file-picker-container">
				<groupware-file-picker .siteId="this.siteId" .locale="this.locale" .limit="this.limit" .timezone="this.timezone"
					:msg="this.msgCallback" @fetch="onFetch" @cancel="this.onCancel" @accept="onAccept">
				</groupware-file-picker>
			</div>
		</v-container>
	</v-card>
</template>
<script>
import "@earthbrain/sc-widgets/Source/GroupwareFilePicker.js";
import "@earthbrain/sc-widgets/Source/Icon.js";
import { getNodesRaw, getBuckets } from "../../utils/fileStorageUtils.js";

export default {
	name: "addDataFromGroupware",

	data: () => ({
		// showIsDraped: false,
		siteId: undefined,
		locale: undefined,
		limit: 10,
		timezone: undefined,
	}),
	mounted() {
		this.siteId = this.$store.getters.site.site_uuid;
		this.locale = this.$store.getters.lang;
	},
	methods: {
		msgCallback(key, substitutions) {
			if (key === "ACCEPT") {
				return this.$t("NEXT");
			}
			if (key === "AND_N_MORE") {
				return "";
			}
			if (key === "LIST_DISPLAYING") {
				return "";
			}
			if (!substitutions) {
				return this.$t(key);
			}
		},
		close() {
			this.$emit("close");
		},
		onCancel() {
			this.$emit("close");
		},
		onAccept({ detail }) {
			this.$emit("accept", detail);
		},
		async onFetch({ detail }) {
			try {
				if (detail.bucketId) {
					// Get nodes of bucket
					const nodes = await getNodesRaw(
						detail.siteId,
						detail.bucketId,
						detail.queryParameters,
					);
					detail.resolve(nodes);
				} else {
					// Get bucket list
					const buckets = await getBuckets(detail.siteId);
					buckets.buckets = buckets.data.buckets;
					detail.resolve(buckets);
				}
			} catch (e) {
				detail.reject(e);
			}
		},
	},
};
</script>
<style scoped>
.groupware-file-picker-container {
	--widgets-transparent-background-color: var(--bg-color-sticky);
	--widgets-space-xs: var(--space-xs);
	--widgets-space-sm: var(--space-sm);
	--widgets-space-md: var(--space-md);
	--widgets-line-height: var(--line-height);
	--widgets-container-padding: var(--container-padding);
	--widget-input-padding: var(--input-padding);
	--cesium-font-size: var(--font-size);
	--widgets-active-color: var(--primary-bg);
	--widgets-active-color-shade: var(--primary-hover);
	--widgets-lighter-color: #555;
	--widgets-border-color: #444;
	--widgets-border-radius: 2rem;
	--widgets-default-color:
}

groupware-file-picker {
	width: 100%;
	max-height: 100%;
}
</style>
<script setup>
const props = defineProps({
	position: Object, //position in 2d Sreen
	color: String,
});
</script>


<template>
  <div class="container" :style="styles" @pointerdown="startDrag" @click="onClick">    
      <slot></slot>
  </div>
</template>

<script>
export default {
name: "DragableContainer",
mounted() {
  this.sectionDialogX = this.position.x;
  this.sectionDialogY = this.position.y;
},
data: () => ({
  dialogIndrag: false,
  sectionDialogX: 50,
  sectionDialogY: 50,
  dialoginitialLeft: 0,
  dialoginitialTop: 0,
  dragStartX: 0,
  dragStartY: 0,
}),
methods: {
  startDrag(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.dialogIndrag = true;
    document.addEventListener("pointermove", this.drag);
    document.addEventListener("pointerup", this.endDrag);
    this.dragStartX = e.pageX;
    this.dragStartY = e.pageY;
    this.dialoginitialLeft = this.sectionDialogX;
    this.dialoginitialTop = this.sectionDialogY;
  },
  drag(e) {
    e.stopImmediatePropagation();
    if (this.dialogIndrag) {
      this.sectionDialogX =
        this.dialoginitialLeft + e.pageX - this.dragStartX;
      this.sectionDialogY = this.dialoginitialTop + e.pageY - this.dragStartY;
    }
  },
  endDrag(e) {
    this.dialogIndrag = false;
    document.removeEventListener("pointermove", this.drag);
    document.removeEventListener("pointerup", this.endDrag);
    e.preventDefault();
    e.stopImmediatePropagation();
  },
  onClick(e) {
    e.preventDefault();
    e.stopImmediatePropagation();
  },
},
created: () => {},
computed: {
  styles() {
    return {
      left: this.sectionDialogX + "px",
      top: this.sectionDialogY + "px",
      cursor: this.dialogIndrag ? "move" : "pointer",
    };
  },
},
};
</script>

<style lang="scss" scoped>
.container {
position: absolute;
box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
background-color: #fff;
z-index: 9999999;
width: 100%;
}
</style>

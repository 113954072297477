import Lock from "@/models/Lock";
import objectLockWebSocket from "@/utils/objectLockWebSocket";

const state = {
	lockInfo: null, // lockしているオブジェクト
};

const mutations = {
	setLockStatus(state, { objId, status }) {
		if (status) {
			// ロック取得
			const lockObject = new Lock(objId);
			state.lockInfo = lockObject;
		} else {
			// ロック解放
			state.lockInfo = null;
		}
	},
	updateLockStatus(state, object) {
		for (const key of Object.keys(object)) {
			state.lockInfo[key] = object[key];
		}
	},
};

const actions = {
	/** ロック取得 */
	attemptLock({ state, commit }, objId) {
		if (state.lockInfo?.objId === objId) {
			// objIdに変更がない場合はロックの取得ではなく継続リクエストのためロック情報を変更しない
			return;
		}
		commit("setLockStatus", { objId, status: true });
	},
	/** ロック解放 */
	releaseLock({ commit }) {
		commit("setLockStatus", { status: false });
	},
	/** ロック更新 */
	keepLock({ state, commit }) {
		const intervalId = setInterval(() => {
			// 5秒に1回ロック情報を更新する。ループ中にロックが解放される可能性があるためリクエストを投げる前にロック情報の有無をチェックする
			if (state.lockInfo) {
				objectLockWebSocket.sendLockEvent(state.lockInfo.objId);
			}
		}, 5000);
		commit("updateLockStatus", { intervalId });
	},
	/** ロック更新停止 */
	stopLockUpdate({ state, commit }) {
		clearInterval(state.lockInfo.intervalId);
		commit("updateLockStatus", { intervalId: null });
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};

<template>
  <div>
    <div>
      <v-card-text class="py-0">
        <v-btn variant="text" icon size="36" @click="toggleExpansion" class="ml-0">
          <v-icon>
            mdi-play  {{ expansion ? "mdi-rotate-90" : "" }}
          </v-icon>
        </v-btn>
        <span>{{ group.pointCloud.getName() }}</span>
      </v-card-text>
    </div>
    <v-expand-transition>
      <div v-show="expansion" class="mx-3 mb-3">
        <v-card-text v-if="group.objects.length == 0" class="pa-0 pl-10">
          {{ $t("NO_DATA") }}
        </v-card-text>
        <object-list-item 
          style="width: 95%"
          v-for="obj in group.objects"
          :key="obj.id"
          :objId="obj.id"
          :isRoadAdd="isRoadAdd"
          :isFlatAdd="isFlatAdd"
          :isTrenchAdd="isTrenchAdd"
          :globeVisibilityBeforeCreationMode="globeVisibilityBeforeCreationMode"
          :objects2DViewVisibility="objects2DViewVisibility"
          @updateObject2DViewVisibility="updateObject2DViewVisibility"
          @updateGlobeVisibility="updateGlobeVisibility"
          @clear2DViews="clear2DViews"
          class="pa-0 ml-3"
        >
        </object-list-item>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import ObjectListItem from "./ObjectListItem.vue";

export default {
	components: {
		ObjectListItem,
	},
	props: {
		group: Object,
		isFlatAdd: Boolean,
		isRoadAdd: Boolean,
		isTrenchAdd: Boolean,
		globeVisibilityBeforeCreationMode: Boolean,
		objects2DViewVisibility: Object,
	},
	data() {
		return {
			expansion: false,
		};
	},
	methods: {
		updateGlobeVisibility(visibility) {
			this.$emit("updateGlobeVisibility", visibility);
		},
		updateObject2DViewVisibility(show2DView, id) {
			this.$emit("updateObject2DViewVisibility", show2DView, id);
		},
		clear2DViews() {
			this.$emit("clear2DViews");
		},
		toggleExpansion() {
			// オブジェクトデータリストの制御
			this.expansion = !this.expansion;
		},
	},
	beforeUnmount() {},
};
</script>
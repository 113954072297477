import axios from "axios";
import {
	DateFormatDelimiter,
	TimeFormatDelimiter,
	convertToDateString,
	convertToTimeString,
} from "./dateTimeUtils";

/**
 * Set up the coordinate system for the specified site.
 *
 * @async
 * @function setUpSiteCoordinateSystem
 * @param {string} siteId - The ID of the site.
 * @param {boolean} isFromTop - Flag indicating whether the page was transitioned from the top page (true).
 * @returns {Promise<{status:number,data:object|null}>} A promise that resolves with the status code and data object of response when the setup is complete.
 */
export const setUpSiteCoordinateSystem = async (siteId, isFromTop) => {
	let status = 500;
	let data = null;
	try {
		const url = `${import.meta.env.VITE_API_BASE}/sites/${siteId}/coordinate-system/setup`;
		const response = await axios.put(url, {
			isFromTop: isFromTop,
		});
		if (response.status !== 200) {
			throw new Error("Failed to set up site coordinate system.");
		}
		status = response.status;
		data = response.data ?? null;
	} catch (e) {
		console.error(e.message);
		status = e.response?.status ?? 500;
		data = e.response?.data ?? null;
	}
	console.debug(`status: ${status}, data: ${JSON.stringify(data)}`);
	return {
		status: status,
		data: data,
	};
};

/**
 * Check the point cloud data exists DB of Design3D.
 *
 * @async
 * @function checkPointCloudDataExistence
 * @param {string} siteId - The ID of the site.
 * @returns {Promise<boolean>} A promise that resolves to true if point cloud data exists, false otherwise.
 */
export const checkPointCloudDataExistence = async (siteId) => {
	try {
		const url = `${import.meta.env.VITE_API_BASE}/pointcloud/${siteId}`;
		const response = await axios.get(url);
		return response.data.length !== 0;
	} catch (e) {
		return false;
	}
};

/**
 * Gets the nodes of preconstruction data saved to the Platform.
 *
 * @async
 * @function getPreconstructionNode
 * @param {string} siteId - The ID of the site.
 * @returns {Promise<Object|undefined>} The response data containing preconstruction nodes, or undefined if no nodes are found.
 * @throws Will throw an error if no preconstruction nodes or if the request fails.
 */
export const getPreconstructionNode = async (siteId) => {
	try {
		const url = `${import.meta.env.VITE_API_BASE}/assetLink/preconstruction/${siteId}`;
		const response = await axios.get(url);
		return response.data.nodes && response.data.nodes.length !== 0
			? response.data
			: undefined;
	} catch (e) {
		if (e.response?.status === 404) {
			return undefined;
		}
		throw new Error("Failed to get preconstruction nodes.");
	}
};

/**
 * Gets the axis information for preconstruction from the Dashboard.
 *
 * @async
 * @function getPreconstructionAxis
 * @param {string} siteId - The ID of the site.
 * @returns {Promise<string|undefined>} The axis information if present, otherwise undefined.
 * @throws Will throw an error if the request fails.
 */
export const getPreconstructionAxis = async (siteId) => {
	try {
		const url = `${import.meta.env.VITE_API_BASE}/dashboard/${siteId}/preconstruction/axis`;
		const response = await axios.get(url);
		return response.data || undefined;
	} catch (e) {
		throw new Error("Failed to get preconstruction axis.");
	}
};

/**
 * Uploads point cloud data to import terrain data from the Dashboard.
 *
 * @async
 * @function uploadPreconstructionData
 * @param {string} siteId - The ID of the site.
 * @param {string} bucketId - The ID of the bucket.
 * @param {string} nodeId - The ID of the node.
 * @param {string} pointCloudName - The name of the point cloud.
 * @param {string} [axis] - The axis information of the terrain data.
 * @returns {Promise<void>} A promise that resolves when the import process is complete.
 * @throws Will throw an error if the request fails.
 */
export const uploadPreconstructionData = async (
	siteId,
	bucketId,
	nodeId,
	pointCloudName,
	axis,
) => {
	try {
		const url = `${import.meta.env.VITE_API_BASE}/assetLink/pointcloud/${siteId}`;
		const date = new Date();
		const params = {
			bucket_id: bucketId,
			node_id: nodeId,
			name: pointCloudName,
			date: convertToDateString(date, DateFormatDelimiter.HYPHEN),
			time: convertToTimeString(date, TimeFormatDelimiter.COLON),
		};
		if (axis) {
			params.axis = axis;
		}
		await axios.post(url, params);
	} catch (e) {
		throw new Error("Failed to upload preconstruction data.");
	}
};

/**
 * Imports the preconstruction data uploaded by Dashboard to link to Design3D project.
 *
 * @async
 * @function importDashboardPreconstruction
 * @param {string} siteId - The ID of the site.
 * @param {Object} bucketInfo - The bucket information where the preconstruction data is stored.
 * @param {string} bucketInfo.bucket_id - The ID of the bucket.
 * @param {Array<{id: string, name: string}>} bucketInfo.nodes - The array of nodes within the bucket.
 * @param {string} bucketInfo.nodes[].id - The ID of the node.
 * @param {string} bucketInfo.nodes[].name - The name of the node.
 * @returns {Promise<void>} A promise that resolves when the linking process succeeds.
 * @throws Will throw an error if the request fails.
 */
export const importDashboardPreconstruction = async (siteId, bucketInfo) => {
	try {
		// get corresponding preconstruction axis
		const axis = await getPreconstructionAxis(siteId);
		// link preconstruction data to Design3D
		await uploadPreconstructionData(
			siteId,
			bucketInfo.bucket_id,
			bucketInfo.nodes[0].id,
			bucketInfo.nodes[0].name,
			axis,
		);
	} catch (e) {
		throw new Error("Failed to import preconstruction data.");
	}
};

/**
 * Gets the site information with the specified ID.
 *
 * @async
 * @function getLandlogReadSite
 * @param {string} siteId - The ID of the site.
 * @returns {Promise<Object>} The response data of LandlogAPI Read Site.
 * @throws Will throw an error if the request fails.
 */
export const getLandlogReadSite = async (siteId) => {
	try {
		const url = `${import.meta.env.VITE_API_BASE}/sites/${siteId}/read`;
		const response = await axios.get(url);
		return response.data;
	} catch (e) {
		console.debug(e);
		throw e;
	}
};

/**
 * Gets the latitude and longitude of the specified site.
 *
 * @async
 * @function getSiteLatitudeAndLongitude
 * @param {string} siteId - The ID of the site.
 * @returns {Promise<{latitude:string,longitude:string}>} The latitude and longitude value of the specified site.
 * @see {@link getLandlogReadSite}
 */
export const getSiteLatitudeAndLongitude = async (siteId) => {
	const data = await getLandlogReadSite(siteId);
	return {
		latitude: data.coordinate.latitude,
		longitude: data.coordinate.longitude,
	};
};

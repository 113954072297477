<script setup>
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

/** props定義 */
const props = defineProps({
	entity: Object,
	draggable: Boolean,
});

/** 関数定義 */
const store = useStore();
const { t } = useI18n();
const route = useRoute();

const toggleHeightMap = async () => {
	viewer._scDesign.toggleHightMapDisplay(props.entity.getAssetIdNum());
};
const checkHightMapControl = () => {
	if (!viewer._scDesign.isHightMapExist(props.entity.getAssetIdNum())) {
		return false;
	}

	if (viewer._scDesign.isHeightMapActive()) {
		return true;
	}

	if (window.debugFlag.hmap) {
		return true;
	}

	return false;
};
const checkHightMapVisibility = () => {
	return (
		viewer._scDesign.isHightMapVisible(props.entity.getAssetIdNum()) === true
	);
};

const remove = async () => {
	// オブジェクトリストの最新化
	try {
		await store.dispatch("get_obj_list", route.query.siteId);
	} catch {
		store.commit("set_snackbar", {
			text: `${t("REFRESH_OBJECT_LIST")} ${t("failed")}`,
			color: "rgba(153, 0, 0, 0.72)",
		});
		return;
	}
	// 擦り付け先に設定されているオブジェクトの存在チェック
	const associateObject = store.state.objects.find((obj) => {
		const associatePointCloudId =
			obj.json && JSON.parse(obj.json).associatePointCloudId;
		// 削除対象の点群が摺り付け点群に設定されているオブジェクトを返す
		return (
			associatePointCloudId &&
			props.entity.getAssetIdNum() === associatePointCloudId
		);
	});
	// 擦り付け先点群に設定されていればエラーダイアログを表示
	if (associateObject) {
		store.commit("set_snackbar", {
			text: t("CANNOT_DELETE_ASSOCIATED_POINT_CLOUD"),
			color: "rgba(153, 0, 0, 0.72)",
		});
		return;
	}
	// 削除実行
	try {
		await store.dispatch("delete_point_cloud", props.entity);
		store.commit("set_snackbar", {
			text: `${props.entity.getName()} ${t("REMOVE")} ${t("successful")}`,
			color: "rgba(0, 153, 0, 0.72)",
		});
	} catch {
		store.commit("set_snackbar", {
			text: `${t("REMOVE")} ${t("failed")}`,
			color: "rgba(153, 0, 0, 0.72)",
		});
	}
};
</script>

<template>
  <span>
    <span v-if="timeStamp" class="ml-4">{{ timeStamp }}</span>
    <v-card-actions class="py-0" :style="isActive ? 'background-color: #0064BA;' : ''">
      <!-- アップロードステータス -->
      <div v-if="entity.getAssetId() && (showUploadProgress || isUploadError)">
        <ProgressCircle class="mr-1 my-3" :entity="entity" :showUploadProgress="showUploadProgress"
          :isUploadError="isUploadError" @update-upload-progress="(value) => {
            showUploadProgress = value;
          }
            " @update-upload-error-status="(value) => {
              isUploadError = value;
            }
              "></ProgressCircle>
      </div>
      <v-btn v-if="draggable" icon size="24" class="handle" style="cursor: grab">
        <v-icon> mdi-drag </v-icon>
      </v-btn>
      <v-btn icon size="24" @click="toggle_visibility" :disabled="isDisabledVisibilityBtn" class="ml-0">
        <v-icon>
          {{ entity.getVisibility() ? "icon:View" : "icon:View-Off" }}
        </v-icon>
      </v-btn>
      <v-btn icon size="36" @click="toggle_expansion" class="ml-0">
        <v-icon>
          mdi-play {{ entity.getExpansion() ? "mdi-rotate-90" : "" }}
        </v-icon>
      </v-btn>
      <ListItemLabel :text="entity?.getName() ?? ''" />
      <v-btn @click="focus" :disabled="isDisabledFocusBtn" icon size="24">
        <v-icon size="small">icon:Search</v-icon>
      </v-btn>
      <v-menu transition="slide-y-transition" :disabled="entity.getTilingProgress()">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props" size="24">
            <v-icon> icon:Overflow-Menu-Vertical </v-icon>
          </v-btn>
        </template>
        <v-list density="compact">
          <v-list-item @click="remove">
            <v-icon class="mr-4">mdi-delete</v-icon>
            <span style="font-size: 13px">{{ $t("REMOVE") }}</span>
          </v-list-item>
          <v-list-item @click="toggleHeightMap" v-if="checkHightMapControl()">
            <p v-if="checkHightMapVisibility()" style="font-size: 13px">HMap OFF</p>
            <p v-else>HMap ON</p>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- 処理ステータス -->
      <div v-if="entity.getProcessingStatus()">
        <processing-status :status="entity.getProcessingStatus()"
          :processingStatus="constants.pointCloudProcessingStatus">
        </processing-status>
      </div>
    </v-card-actions>
    <div class="pl-7" v-show="entity.getExpansion()">
      <v-card-actions class="py-0 px-5">
        {{ $t("OPACITY") }}
        <v-slider class="ml-1" v-model="transparency" :disabled="isDisabledTransparency" @end="saveSettings"
          hide-details max="1" step="0.01" color="primary" track-size="0.5" thumb-size="14"></v-slider>
      </v-card-actions>
    </div>
  </span>
</template>

<script>
import * as cesiumCommon from "@/utils/cesium-common";
import ProgressCircle from "./ProgressCircle";
import * as constants from "@/constant.js";
import ProcessingStatus from "@/components/common/ProcessingStatus.vue";
import ListItemLabel from "@/components/Project/ListItemLabel.vue";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import axios from 'axios';
dayjs.extend(isSameOrAfter);

export default {
  name: "PointCloudListItem",
  components: {
    ProgressCircle,
    ProcessingStatus,
    ListItemLabel
  },
  data() {
    return {
      showUploadProgress: true,
      isUploadError: false,
      constants,
    };
  },
  computed: {
    isAssetUploaded() {
      return this.entity.getIsAssetUploaded();
    },
    isActive() {
      const activePointCloudId =
        this.$store.getters.activePointCloud?.getAssetId();
      return (
        activePointCloudId !== undefined &&
        activePointCloudId === this.entity.getAssetId()
      );
    },
    /**
     * 画面のクリエーションモードの状態
     */
    inCreationMode() {
      return this.$store.state.modes.inCreationMode.isActive;
    },
    /**
     * 点群の透明度
     */
    transparency: {
      get() {
        return this.entity.getTransparency();
      },
      set(value) {
        this.entity.setTransparency(value);
      },
    },
    /**
     * 可視状態切り替えボタンの活性状態を制御する
     * 以下の場合非活性
     * ・道路追加や平場追加を実行中で他の点群が活性状態じゃない
     * ・タイリング途中
     * ・アセットがアップロードされていない
     */
    isDisabledVisibilityBtn() {
      return (
        (this.inCreationMode && !this.entity.getIsCreating()) ||
        this.entity.getTilingProgress() ||
        this.isAssetUploaded === "PENDING" ||
        this.isAssetUploaded === "ERROR"
      );
    },
    /**
     * 透明度スライダーの活性状態を制御する
     */
    isDisabledTransparency() {
      // 非表示状態の場合は非活性
      if (!this.entity.getVisibility()) return true;
      else return false;
    },
    /**
     * フォーカスボタンの活性状態を制御する
     */
    isDisabledFocusBtn() {
      // 非表示状態の場合は非活性
      if (!this.entity.getVisibility()) return true;
      else return false;
    },
    timeStamp() {
      return this.entity.getTimeStamp();
    },
  },
  watch: {
    // アップロードステータスがPENDINGになれば再度ポーリング開始
    isAssetUploaded(newValue) {
      if (newValue === "PENDING") {
        this.showUploadProgress = true;
        this.isUploadError = false;
      }
    },
    transparency() {
      let transparency_val = parseFloat(this.transparency.toFixed(2));
      try {
        window["viewer"].updateTilesetTransparency(
          parseInt(this.entity.getAssetId()),
          "pointcloud",
          transparency_val
        );
      } catch (e) {
        console.error(e);
      }
    },
    inCreationMode(newCreationMode) {
      if (newCreationMode) {
        // 画面がクリエーションモードに変更になった場合
        if (this.entity.getVisibility()) {
          // 可視状態であれば点群をクリエーションモードに変更
          this.entity.setIsCreating(true);
        }
      } else {
        // 画面のクリエーションモードが解除された場合
        if (this.entity.getIsCreating()) {
          // 点群がクリエーションモードであれば解除
          this.entity.setIsCreating(false);
        }
      }
    },
  },
  methods: {
    async toggle_visibility() {
      await cesiumCommon.togglePointCloudVisibility(
        this.entity,
        this.$store.getters.getRoads
      );
      if (!this.entity.getVisibility()) {
        // 点群が非表示になったら仮設道路・平場・床堀も非表示にする
        const relatedVisibleObjects = this.$store.getters.objects
          .filter((object) => object.json)
          .map((object) => [
            JSON.parse(object.json).associatePointCloudId,
            object,
          ])
          .filter(
            ([assetId, object]) =>
              assetId === this.entity.getAssetId() && object.isShow
          ); // assetId は文字列なので === での厳密な比較はしない
        for (const [assetId, object] of relatedVisibleObjects) {
          object.isShow = false;
          switch (object.type) {
            case constants.objectType.ROAD: {
              cesiumCommon.toggleRoadVisibility(object, assetId, false);
              break;
            }
            case constants.objectType.FLAT: {
              cesiumCommon.toggleFlatGroundVisibility(object, assetId);
              break;
            }
            case constants.objectType.TRENCH: {
              cesiumCommon.setTrenchVisibility(object, assetId, false);
              break;
            }
          }
        }

        const newActivePointCloud = this.$store.getters.activePointCloud;
        if (newActivePointCloud) {
          newActivePointCloud.setVisibility(false); //hack to make it true
          await cesiumCommon.togglePointCloudVisibility(
            newActivePointCloud,
            this.$store.getters.getRoads
          );
        }
      }
    },
    toggle_expansion() {
      this.entity.setExpansion(!this.entity.getExpansion());
    },
    focus() {
      cesiumCommon.zoomToTileset(this.entity);
    },
    saveSettings() {
      axios.put(
        `${import.meta.env.VITE_API_BASE}/pointCloud/setting/${this.$route.query.siteId}/${this.entity.getId()}`,
        {
          transparency: this.transparency
        }
      )
    }
  },
  beforeUnmount() {
    // 仮設道路追加や平場追加を行っている場合キャンセル
    if (this.inCreationMode) {
      window.dispatchEvent(new Event("cancelCreateMode"));
    }
  },
};
</script>

<template>
    <div class="fullscreen" @keyup.esc="onClickAway" @click="onClickAway" @mousedown="onClickAway">
        <div class="fixedPos" :style="{ 'top': `${posY - 48}px`, 'left': posX + 'px' }" @keyup.enter="onEnterKeyUp"
            @keydown.enter.prevent @click="stopImmediatePropagation" @mousedown="stopImmediatePropagation">
            <v-form v-model="isValid">
                <SlopeSettingInput ref="slopeValue" :suffix-to-display="getSuffix(slopeUnit)" :is-editable="true"
                    :slope-value="slopeValue" variant="solo" :step="longitudinalSlope.step" v-model="value"
                    :max-value="longitudinalSlope.maxValue" :min-value="longitudinalSlope.minValue"
                    :decimal-length="longitudinalSlope.decimalLength" :un-valid-zero="slopeUnit === 'percent'">
                </SlopeSettingInput>
            </v-form>
        </div>
    </div>
</template>

<script>
import SlopeSettingInput from "./Project/SlopeSettingInput.vue";
import { UnitTypes } from "../constant";
import { longitudinalSlope } from "@/config/numberConfig";
export default {
	name: "Slope2dValueInput",
	components: {
		SlopeSettingInput,
	},
	props: {
		objId: String,
		slopeValue: Number,
		slopeUnit: String,
		slopeIndex: Number,
		posX: Number,
		posY: Number,
	},
	data() {
		return {
			value: this.slopeValue,
			isValid: false,
			longitudinalSlope,
		};
	},
	methods: {
		getSuffix(unitType) {
			const unit = UnitTypes.find((type) => type.value === unitType);
			return unit.symbol;
		},
		onEnterKeyUp(e) {
			e.preventDefault();
			e.stopImmediatePropagation();
			// バリデーションエラーがある場合は更新しない
			if (!this.isValid) {
				return;
			}
			const roadLength = window["viewer"].getLengthOfRoad(this.objId);
			const projectedLength = window[
				"viewer"
			].getCorrespondingProjectedDistFromStart(this.objId, roadLength);

			const slopeBreaks = window["viewer"].getSlopeBreaks(this.objId);
			const updatedSlopeBreaks = [...slopeBreaks];

			const firstSlopeData = slopeBreaks[this.slopeIndex];
			const nextSlopeData = slopeBreaks[this.slopeIndex + 1];
			const horizontalDistance =
				(nextSlopeData.percentage - firstSlopeData.percentage) *
				projectedLength;
			let heightOfSecondSlope;
			if (this.value) {
				heightOfSecondSlope =
					horizontalDistance / this.value + firstSlopeData.height;
			} else {
				heightOfSecondSlope = firstSlopeData.height;
			}
			updatedSlopeBreaks[this.slopeIndex + 1].height = heightOfSecondSlope;

			window["viewer"].updateSlopeBreaksFromLongitudinalView(
				this.objId,
				updatedSlopeBreaks,
			);
			this.$emit("close");
		},
		onClickAway() {
			this.$emit("close");
		},
		stopImmediatePropagation(e) {
			e.stopImmediatePropagation();
		},
	},
	mounted() {
		this.$refs.slopeValue.updateDisplaySlopeValue(this.slopeValue);
	},
};
</script>

<style lang='scss' scoped>
.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    background-color: transparent;
}

.fixedPos {
    display: block;
    position: fixed;
    width: 100px;
    height: 2em;
}

:deep(div.v-field) {
    input.v-field__input {
        padding: 8px 0px 8px 8px;
    }
    span.v-text-field__suffix {
        padding-right: 8px;
    }
}
</style>
// AIによる道路追加で使用するstore
const state = {
	// 設計データ
	designFilename: "",
	// 地形データ
	pointCloudItem: "",
	//地形情報
	terrainItems: [
		{
			name: "terrain_x_value_min",
			value: "",
		},
		{
			name: "terrain_x_value_max",
			value: "",
		},
		{
			name: "terrain_y_value_min",
			value: "",
		},
		{
			name: "terrain_y_value_max",
			value: "",
		},
		{
			name: "design_x_value_min",
			value: "",
		},
		{
			name: "design_x_value_max",
			value: "",
		},
		{
			name: "design_y_value_min",
			value: "",
		},
		{
			name: "design_y_value_max",
			value: "",
		},
		{
			name: "terrain_height_min",
			value: "",
		},
		{
			name: "terrain_height_max",
			value: "",
		},
		{
			name: "design_height_min",
			value: "",
		},
		{
			name: "design_height_max",
			value: "",
		},
	],
	isAIRoadPreviewMode: false,
};

const getters = {
	designFilename: (state) => state.designFilename,
	pointCloudItem: (state) => state.pointCloudItem,
	terrainItems: (state) => state.terrainItems,
	isAIRoadPreviewMode: (state) => state.isAIRoadPreviewMode,
};

const mutations = {
	update_design_file_name(state, designFilename) {
		state.designFilename = designFilename;
	},
	update_point_cloud_item(state, pointCloudItem) {
		state.pointCloudItem = pointCloudItem;
	},
	update_terrain_items(state, terrainItems) {
		state.terrainItems = terrainItems;
	},
	update_is_ai_road_preview_mode(state, isAIRoadPreviewMode) {
		state.isAIRoadPreviewMode = isAIRoadPreviewMode;
	},
};

const actions = {
	update_design_file_name({ commit }, designFilename) {
		commit("update_design_file_name", designFilename);
	},
	update_point_cloud_item({ commit }, pointCloudItem) {
		commit("update_point_cloud_item", pointCloudItem);
	},
	update_terrain_items({ commit }, terrainItems) {
		commit("update_terrain_items", terrainItems);
	},
	update_is_ai_road_preview_mode({ commit }, isAIRoadPreviewMode) {
		commit("update_is_ai_road_preview_mode", isAIRoadPreviewMode);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

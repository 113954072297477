<template>
  <v-list density="compact">
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForFlatGroundEdit" :key="index"
      @click="onCMClickForFlatGroundEdit($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import * as lockUtils from "@/utils/lock-utils";
import * as constants from "@/constant.js";

export default {
	props: {
		flatGroundId: String,
		pointId: String,
	},
	data: () => ({
		isroadOrFlatArea: "Road",
		itemsForFlatGroundEdit: [
			// { id: 1, title: "ADD" },
			{ id: 2, title: "Remove" },
			{ id: 3, title: "Switchdragmode" },
			{ id: 4, title: "Uniformdrag" },
			{ id: 5, title: "Undo" },
			{ id: 6, title: "Redo" },
			{ id: 7, title: "Update" },
			{ id: 8, title: "FLATGROUND_HEIGHT" },
			{ id: 9, title: "Finishdrawing" },
		],
	}),
	methods: {
		addControlPoint() {
			// window["viewer"].toggleFlatGroundPointVerticalDrag(
			//   false,
			//   this.flatGroundId
			// );
			// window["viewer"].toggleFlatGroundPointDrag(
			//   true,
			//   this.flatGroundId,
			//   () => { uploadFlatGroundObj(obj) }
			// );
			// window["viewer"].addMidPointsForFlatGround(
			//   this.flatGroundId
			// );
		},
		removeControlPoint() {
			window["viewer"].removePointFromFlatGround(
				this.flatGroundId,
				this.pointId,
			);
		},
		switchToDragMode() {
			this.$store.state.modes.isFlatGroundHoriDrag = false;
			this.$store.state.modes.isFlatGroundUniformDrag = false;
			this.$store.state.modes.isFlatGroundVertiDrag =
				!this.$store.state.modes.isFlatGroundVertiDrag;

			if (this.$store.state.modes.isFlatGroundVertiDrag) {
				window["viewer"].setFlatGroundPointToVerticalDrag(this.flatGroundId);
			} else {
				this.$store.state.modes.isFlatGroundHoriDrag = true;
				window["viewer"].setFlatGroundPointToHorizontalDrag(this.flatGroundId);
			}
		},
		openPropertyDialogUpdateControlPoint(disabilityConditions = "NONE") {
			window.dispatchEvent(
				new CustomEvent("openPropertyDialog", {
					detail: {
						id: this.flatGroundId,
						type: 3,
						pointId: this.pointId,
						entityUnderEdit: constants.objectType.FLAT,
						disabilityConditions,
						origin: "CesiumView",
					},
				}),
			);
		},
		async finishDrawing() {
			// eslint-disable-next-line no-case-declarations
			const flatGround = this.$store.state.objects.find(
				(flatGround) => flatGround.id === this.flatGroundId,
			);
			if (flatGround) {
				this.$store.dispatch("executeWithSpinner", async () => {
					// ロックを解放
					const lockReleased = await lockUtils.releaseLock(flatGround.cid);
					if (!lockReleased) return;
					this.$store.dispatch("endEditMode");
					this.$store.state.modes.isFlatGroundHoriDrag = false;
					this.$store.state.modes.isFlatGroundVertiDrag = false;
					this.$store.state.modes.isFlatGroundUniformDrag = false;
					window["viewer"].enableFlatGroundEdit(false, flatGround.id);
					flatGround.edit = false;
				});
				window["viewer"].clearCommandManager();
			}
		},
		uniformlyDragFlatGround() {
			if (this.$store.state.modes.inEditMode.isActive) {
				this.$store.state.modes.isFlatGroundHoriDrag = false;
				this.$store.state.modes.isFlatGroundVertiDrag = false;
				this.$store.state.modes.isFlatGroundUniformDrag =
					!this.$store.state.modes.isFlatGroundUniformDrag;

				if (this.$store.state.modes.isFlatGroundUniformDrag) {
					window["viewer"].setFlatGroundToUniformVerticalDrag(
						this.flatGroundId,
					);
				} else {
					this.$store.state.modes.isFlatGroundHoriDrag = true;
					window["viewer"].setFlatGroundPointToHorizontalDrag(
						this.flatGroundId,
					);
				}
			}
		},
		onCMClickForFlatGroundEdit(e, id) {
			e.preventDefault();
			// const obj = this.$store.state.objects.find(
			//   (flatGround) => flatGround.id === this.flatGroundId
			// );
			this.isroadOrFlatArea = "FlatArea";
			switch (id) {
				// case 1:
				//   this.addControlPoint(obj);
				//   break;
				case 2:
					this.removeControlPoint();
					break;
				case 3:
					this.switchToDragMode();
					break;
				case 4:
					this.uniformlyDragFlatGround();
					break;
				case 5:
					window["viewer"].undo();
					break;
				case 6:
					window["viewer"].redo();
					break;
				case 7:
					this.openPropertyDialogUpdateControlPoint("NONE");
					break;
				case 8:
					this.openPropertyDialogUpdateControlPoint("XY");
					break;
				case 9:
					this.finishDrawing();
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>

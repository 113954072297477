export class Drawing2dImage {
	constructor({
		name,
		file,
		fileName,
		isAssetUploaded,
		assetId,
		centerLatitude,
		centerLongitude,
		rotateDegree,
		imageWidth,
		imageHeight,
		transparency = 0,
		isDraped = false,
		isVisible = true,
		selectedDate = undefined,
		selectedTime = undefined,
		expansion = false,
		tilingProgress = false, // will delete
		processingStatus = "COMPLETED", // will delete
		elevation = Number.NaN,
		isLoaded = false,
		hasImage = false,
		hasJson = false,
	} = {}) {
		this.name = name;
		this.file = file;
		this.fileName = fileName;
		this.isAssetUploaded = isAssetUploaded;
		this.assetId = assetId;
		this.latitude = centerLatitude;
		this.longitude = centerLongitude;
		this.rotateDegree = rotateDegree;
		this.imageWidth = imageWidth;
		this.imageHeight = imageHeight;
		this.transparency = transparency;
		this.isVisible = isVisible;
		this.selectedDate = selectedDate;
		this.selectedTime = selectedTime;
		this.isDraped = isDraped;
		this.expansion = expansion;
		this.tilingProgress = tilingProgress;
		this.processingStatus = processingStatus;
		this.is_creating = false;
		this.isLoaded = isLoaded;
		this.elevation = elevation;
		this.hasImage = hasImage;
		this.hasJson = hasJson;
	}

	getProcessingStatus() {
		return this.processingStatus;
	}

	setProcessingStatus(value) {
		this.processingStatus = value;
	}

	getName() {
		return this.name;
	}

	getExtension() {
		const lastDotIndex = this.fileName.lastIndexOf(".");
		const extension = this.fileName.substring(lastDotIndex + 1);
		return extension;
	}

	getTilingProgress() {
		return this.tilingProgress;
	}

	getIsAssetUploaded() {
		return this.isAssetUploaded;
	}

	getAssetId() {
		return this.assetId;
	}

	get asset_id() {
		return this.assetId;
	}

	getId() {
		return this.assetId;
	}

	getTransparency() {
		return this.transparency;
	}

	setTransparency(value) {
		this.transparency = value;
	}

	getIsVisible() {
		return this.isVisible;
	}

	getVisibility() {
		return this.isVisible;
	}

	setIsVisible(value) {
		this.isVisible = value;
	}

	getTimeStamp() {
		// const date = new Date(`${this.selectedDate}T${this.selectedTime}`);
		const date = new Date(
			Date.parse(`${this.selectedDate} ${this.selectedTime}`),
		);
		return this.selectedDate && this.selectedTime
			? [
					date.toLocaleString("en-US", { day: "2-digit" }),
					`${date.toLocaleString("en-US", { month: "short" })},`,
					date.toLocaleString("en-US", { year: "numeric" }),
					date.toLocaleString("en-US", { hour: "numeric", minute: "2-digit" }),
				].join(" ")
			: "";
	}

	getExpansion() {
		return this.expansion;
	}

	setExpansion(value) {
		this.expansion = value;
	}

	getIsCreating() {
		return this.is_creating;
	}

	setIsCreating(isCreating) {
		this.is_creating = isCreating;
	}

	getIsLoaded() {
		return this.isLoaded;
	}

	setIsLoaded(isLoaded) {
		this.isLoaded = isLoaded;
	}

	setImage(image) {
		this.file = image;
	}
	setLatLon(lat, lon) {
		this.latitude = lat;
		this.longitude = lon;
	}
	setImageDimension(width, height) {
		this.imageWidth = width;
		this.imageHeight = height;
	}
	setIsDraped(isDraped) {
		this.isDraped = isDraped;
	}
	setElevation(elevation) {
		this.elevation = elevation;
	}
	getElevation() {
		return this.elevation;
	}
	setHasImage(hasImage) {
		this.hasImage = hasImage;
	}
	getHasImage() {
		return this.hasImage;
	}
	setHasJson(hasJson) {
		this.hasJson = hasJson;
	}
	getHasJson() {
		return this.hasJson;
	}
}

<template>
    <v-table hover density="compact" style="background-color: rgba(41, 41, 47, 0)">
        <tbody>
            <!-- 法面高さ -->
            <tr>
                <td style="width: 50%">
                    {{ $t("Slopeheight") }}
                </td>
                <td>
                    <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m" 
                        :disabled="!property.settings[moundTypeSettings].generateShelf || !withSlope"
                        :rules="
                          !property.settings[moundTypeSettings].generateShelf || !withSlope ? [] :  
                          [
                            rules.orLess(99.99),
                            rules.orMore(0.01),
                            rules.decimalLength(2),
                            rules.numberFormat
                          ]"
                        @update:model-value="updateHeightDbSide()" v-model.number="propertyData.settings[moundTypeSettings].height" />
                </td>
            </tr>
            <!-- 法面勾配 -->
            <tr>
                <td>
                    {{ $t("Slopegradient") }}
                </td>
                <td>
                    <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(fillSettingSlopeUnit.slopeGradient)"
                        :is-editable="withSlope"
                        :slope-value="property.settings[moundTypeSettings].moundSlope" :step="0.01"
                        @update:model-value="updateMoundSlopeDbSide()" v-model.number="propertyData.settings[moundTypeSettings].moundSlope"
                        :max-value="fillSettingSlopeUnit.slopeGradient === 'percent' ? 9999.99 : 999.99" :min-value="0.01"
                        :decimal-length="2" :un-valid-zero="false"></SlopeSettingInput>
                </td>
            </tr>
            <!-- 小段あり -->
            <tr>
                <td>
                    {{ $t("Thereisberm") }}
                </td>
                <td>
                  <v-checkbox
                    color="primary"
                    :disabled="!withSlope"
                    hide-details
                    density="compact"
                    class="ma-0"
                    @click="emitUpdatedSettings()"
                    v-model="propertyData.settings[moundTypeSettings].generateShelf"
                  ></v-checkbox>
                </td>
            </tr>
            <!-- 小段幅 -->
            <tr>
                <td>
                    {{ $t("Bermwidth") }}
                </td>
                <td>
                    <v-text-field variant="underlined" hide-details density="compact" type="number" min="0" step="0.01" suffix="m" 
                        :disabled="!property.settings[moundTypeSettings].generateShelf || !withSlope"
                        :rules="
                          !property.settings[moundTypeSettings].generateShelf || !withSlope ? [] :
                          [
                            rules.orLess(99.99),
                            rules.orMore(0.01),
                            rules.decimalLength(2),
                            rules.numberFormat
                          ]" 
                        @update:model-value="updateShelfWidthDbSide()"
                        v-model.number="propertyData.settings[moundTypeSettings].shelfWidth" />
                </td>
            </tr>
            <!-- 小段勾配 -->
            <tr>
                <td>
                    {{ $t("Bermgradient") }}
                </td>
                <td>
                    <SlopeSettingInput variant="underlined" :suffix-to-display="getSuffix(fillSettingSlopeUnit.bermGradient)"
                        :is-editable="property.settings[moundTypeSettings].generateShelf && withSlope"
                        :slope-value="property.settings[moundTypeSettings].shelfSlope" :step="0.01"
                        @update:model-value="updateShelfSlopeDbSide()" v-model.number="propertyData.settings[moundTypeSettings].shelfSlope"
                        :max-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 9999.99 : 999.99"
                        :min-value="fillSettingSlopeUnit.bermGradient === 'percent' ? 0 : 0.01" :decimal-length="2"
                        :un-valid-zero="fillSettingSlopeUnit.bermGradient === 'percent'"></SlopeSettingInput>

                </td>
            </tr>
            <!-- 水平地形色 -->
            <tr>
                <td>
                    {{ $t("Flatgroundcolor") }}
                </td>
                <td>
                        <v-menu
                          :disabled="!withSlope"
                        >
                            <template v-slot:activator="{ props }">
                              <v-avatar
                                :style="horizontalTerrainColorStyle"
                                :color="property.settings[moundTypeSettings].color"
                                size="20px"
                               
                                v-bind="props">
                              </v-avatar>
                            </template>
                            <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches mode="hexa"
                                v-model="propertyData.settings[moundTypeSettings].color"
                                @update:model-value="emitUpdatedSettings()"></v-color-picker>
                        </v-menu>
                </td>
            </tr>
        </tbody>
    </v-table>
</template>

<script>
import SlopeSettingInput from "./SlopeSettingInput.vue";
import rules from "@/utils/validation-rules.js";
import { UnitTypes } from "../../constant";

export default {
	name: "PropertyFlatMoundSettings",
	components: { SlopeSettingInput },
	props: {
		property: Object,
		moundTypeSettings: String,
	},
	methods: {
		getSuffix(unitType) {
			const unit = UnitTypes.find((type) => type.value === unitType);
			return unit.symbol;
		},
		updateHeightDbSide() {
			this.propertyData.settings[this.moundTypeSettings].height = this.isNum(
				this.property.settings[this.moundTypeSettings].height,
			)
				? this.property.settings[this.moundTypeSettings].height
				: this.defaultProperty.settings[this.moundTypeSettings].height;
			this.emitUpdatedSettings();
		},
		updateMoundSlopeDbSide() {
			// this.updateSlopes(this.property);
			this.propertyData.settings[this.moundTypeSettings].moundSlope =
				this.isNum(this.property.settings[this.moundTypeSettings].moundSlope)
					? this.property.settings[this.moundTypeSettings].moundSlope
					: this.defaultProperty.settings[this.moundTypeSettings].moundSlope;
			this.emitUpdatedSettings();
		},
		updateShelfWidthDbSide() {
			this.propertyData.settings[this.moundTypeSettings].shelfWidth =
				this.isNum(this.property.settings[this.moundTypeSettings].shelfWidth)
					? this.property.settings[this.moundTypeSettings].shelfWidth
					: this.defaultProperty.settings[this.moundTypeSettings].shelfWidth;
			this.emitUpdatedSettings();
		},
		updateShelfSlopeDbSide() {
			// this.updateSlopes(this.property);
			this.propertyData.settings[this.moundTypeSettings].shelfSlope =
				this.isNum(this.property.settings[this.moundTypeSettings].shelfSlope)
					? this.property.settings[this.moundTypeSettings].shelfSlope
					: this.defaultProperty.settings[this.moundTypeSettings].shelfSlope;
			this.emitUpdatedSettings();
		},
		isNum(val) {
			return typeof val == "number";
		},
		emitUpdatedSettings() {
			this.$emit("settingsUpdated");
		},
	},
	data() {
		return {
			rules: {
				...rules,
				validName: (v) => !/[~@#$^*()+=[\]{}|\\,?:<>!'"/;₹€&`%]/.test(v),
			},
			propertyData: { ...this.property },
		};
	},
	computed: {
		/**
		 * 水平地形色のスタイル
		 */
		horizontalTerrainColorStyle() {
			// 編集不可のときに適用するCSS
			if (!this.withSlope) {
				return {
					opacity: 0.5,
					"pointer-events": "none",
				};
			} else {
				// 編集可のときに適用するCSS
				return {
					opacity: 1.0,
				};
			}
		},
		withSlope() {
			return this.property.settings.commonSettings.withSlope;
		},
		fillSettingSlopeUnit() {
			return this.$store.state.fillSettingSlopeUnit;
		},
		defaultProperty() {
			return this.$store.getters.defaultFlatGroundProperty;
		},
	},
	watch: {
		propertyData: {
			deep: true,
			handler(newProperty, oldProperty) {
				if (newProperty !== oldProperty) {
					this.$emit("update:property", newProperty);
				}
			},
		},
	},
};
</script>
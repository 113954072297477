<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-text>
      <v-container>
        <v-row class="mt-2">
          {{ $t("LineWorkparamater") }}
        </v-row>
        <v-row>
          <v-table density="compact" style="background-color: rgba(41, 41, 47, 0)">
            <tbody>
              <tr>
                <td style="width: 50%">
                  {{ $t("WidthofLineWork") }} {{ $t("Left") }}
                </td>
                <td>
                  <v-text-field hide-details density="compact" type="number" min="1" step="0.001" suffix="m"
                    v-model.number="crossSectionData.leftWidth"
                    :rules="[rules.orLess(999.999), rules.orMore(1), rules.decimalLength(3), rules.numberFormat]" />
                </td>
              </tr>
              <!-- 道路幅_右 -->
              <tr>
                <td>
                  <span>{{ $t("WidthofLineWork") }}</span>
                  {{ $t("Right") }}
                </td>
                <td>
                  <v-text-field hide-details density="compact" type="number" min="1" step="0.001" suffix="m"
                    v-model.number="crossSectionData.rightWidth"
                    :rules="[rules.orLess(999.999), rules.orMore(1), rules.decimalLength(3), rules.numberFormat]" />
                </td>
              </tr>
              <!-- 測点間隔 -->
              <tr>
                <td>
                  {{ $t("STATION_INTERVAL") }}
                </td>
                <td>
                  <v-text-field hide-details density="compact" type="number" min="1" step="0.001" suffix="m"
                    v-model.number="crossSectionData.interpolationPitch" :rules="[
            rules.orLess(999.999),
            rules.orMore(1),
            rules.decimalLength(3),
            rules.numberFormat
          ]" />
                </td>
              </tr>
            </tbody>
          </v-table>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>


<script>
import rules from "@/utils/validation-rules.js";

export default {
	name: "LineWorksCrossSectionInput",
	props: {
		crossSectionData: Object,
	},
	data() {
		return {
			rules: {
				...rules,
				required: (value) => !!value || "*",
				validName: (v) => !/[~@#$^*()+=[\]{}|\\,?:<>!'"/;&₹€`%]/.test(v),
			},
		};
	},
	methods: {
		resetProperty({ leftWidth, rightWidth, interpolationPitch }) {
			this.leftWidth = leftWidth;
			this.rightWidth = rightWidth;
			this.interpolationPitch = interpolationPitch;
		},
	},
};
</script>

<style scoped>
.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: 2em;
}

.pt-2 {
  padding-top: 2em;
}

.full-width {
  width: 100%;
}
</style>
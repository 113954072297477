import { Drawing2dImage } from "../../models/Drawing2DImage";
import axios from "axios";
import localDb from "../../utils/local-db";

const state = {
	drawing2dImageList: [],
	pointsOfTwoPointAlignment: [],
	isTwoPointAlignment: false,
};

const getters = {
	getDrawing2dImageList: (state) => state.drawing2dImageList,
	getActiveDrawing2dImageList: (state) => {
		return state.drawing2dImageList.filter((drawing2dImage) =>
			drawing2dImage.getIsVisible(),
		);
	},
};

const mutations = {
	addDrawing2dImage: (state, drawing2dImage) => {
		state.drawing2dImageList = [...state.drawing2dImageList, drawing2dImage];
	},
	updateDrawing2dImageList: (state, drawing2dImageList) => {
		//syncronize
		for (const obj of state.drawing2dImageList) {
			const index = drawing2dImageList.findIndex(
				(o) => o.assetId === obj.assetId,
			);
			if (index !== -1) {
				drawing2dImageList.splice(index, 1);
			}
		}
		state.drawing2dImageList = [
			...state.drawing2dImageList,
			...drawing2dImageList,
		];
	},
	deleteDrawing2dImageListStateById: (state, assetId) => {
		const drawing2dImageList = state.drawing2dImageList.filter(
			(drawing2dImage) => drawing2dImage.assetId !== assetId,
		);
		state.drawing2dImageList = drawing2dImageList;
	},
	setIsTwoPointAlignment: (state, flag) => {
		state.isTwoPointAlignment = flag;
	},
	addPointForTwoPointsAlignment: (state, pointObj) => {
		const newArray = [...state.pointsOfTwoPointAlignment];
		newArray[pointObj.index] = pointObj;
		newArray[pointObj.index].disabled = false;
		state.pointsOfTwoPointAlignment = newArray;
	},
	deletePointForTwoPointsAlignmentByIndex: (state, index) => {
		const newArray = [...state.pointsOfTwoPointAlignment];
		newArray[index].disabled = true;
		state.pointsOfTwoPointAlignment = newArray;
	},
	deleteAllPointsForTwoPointsAlignment: (state) => {
		state.pointsOfTwoPointAlignment = [];
	},
};

const actions = {
	async addDrawing2dImage({ commit }, { drawing2dImage }) {
		commit("addDrawing2dImage", drawing2dImage);
		await localDb.deleteDrawing2DImage(
			this.state.user.id,
			this.state.site.site_uuid,
			drawing2dImage.asset_id,
		);
	},
	async getDrawing2dImageList({ commit }, site_id) {
		// call backend api to get all drawing 2d image list
		// get drawing 2d image list from server but currently hard coded TODO:mr-akami
		const res = await axios.get(
			`${import.meta.env.VITE_API_BASE}/drawing2dImage/${site_id}`,
			{
				auth: this.state.authObject,
			},
		);
		const drawing2dImageList = [];
		for (const obj of res.data) {
			const drawing2dImage = new Drawing2dImage({
				file: null,
				name: obj.drawing_name,
				fileName: obj.image_name,
				assetId: obj.id,
				isAssetUploaded: true,
				transparency: 0.5,
				isVisible: false,
				selectedDate: new Date(obj.capture_at).toLocaleDateString(),
				selectedTime: new Date(obj.capture_at).toLocaleTimeString(),
			});
			drawing2dImageList.push(drawing2dImage);
		}
		commit("updateDrawing2dImageList", drawing2dImageList);
	},
	async deleteDrawing2dImage({ commit }, { siteId, drawing2dImage }) {
		try {
			await axios.delete(
				`${import.meta.env.VITE_API_BASE}/drawing2dImage/${siteId}/${drawing2dImage.assetId}`,
				{
					auth: this.state.authObject,
				},
			);
			await localDb.deleteDrawing2DImage(
				this.state.user.id,
				this.state.site.site_uuid,
				drawing2dImage.asset_id,
			);
		} catch (e) {
			console.warn(e);
			throw e;
		}
		commit("deleteDrawing2dImageListStateById", drawing2dImage.assetId);
	},
	setIsTwoPointAlignment: ({ commit }, { flag }) => {
		commit("setIsTwoPointAlignment", flag);
	},
	addPointForTwoPointsAlignment: ({ commit }, { pointInfo }) => {
		commit("addPointForTwoPointsAlignment", pointInfo);
	},
	deletePointForTwoPointsAlignmentByIndex: ({ commit }, { index }) => {
		commit("deletePointForTwoPointsAlignmentByIndex", index);
	},
	deleteAllPointsForTwoPointsAlignment: ({ commit }) => {
		commit("deleteAllPointsForTwoPointsAlignment");
	},
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

const props = defineProps({
	entity: Object,
});

/** 関数定義 */
const store = useStore();
const { t } = useI18n();

// 地表面に投影
const isProjectionToGroundExecuting = computed(() =>
	store.getters["dxf/projectionToGroundExecutingIds"].some(
		(id) => id === props.entity.getId(),
	),
);
const showDXF = () => {
	props.entity.setVisibility(true);
	window["viewer"].setDXFVisibility(props.entity.getId(), true);
};
const hideDXF = () => {
	props.entity.setVisibility(false);
	window["viewer"].setDXFVisibility(props.entity.getId(), false);
};
const projectionToGround = async (isProjected) => {
	if (isProjected) {
		// 一旦非表示
		hideDXF();
		try {
			// 投影開始
			await store.dispatch("dxf/projectionToGround", {
				dxfId: props.entity.getId(),
				pointCloudId: store.getters.activePointCloudId,
			});
		} catch (e) {
			console.error(e);
			// 失敗メッセージ
			store.commit("set_snackbar", {
				text: t("LINEWORKS_PROJECTION_FAILED", {
					name: props.entity.getName(),
				}),
				color: "rgba(153, 0, 0, 0.72)",
			});
		}
		// 成功メッセージ
		store.commit("set_snackbar", {
			text: t("LINEWORKS_PROJECTION_SUCCESS", { name: props.entity.getName() }),
			color: "rgba(0, 153, 0, 0.72)",
		});
		// 表示に戻す
		showDXF();
	} else {
		// 一旦非表示
		hideDXF();
		// 投影解除
		await store.dispatch("dxf/unprojectionToGround", props.entity.getId());
		// 表示に戻す
		showDXF();
	}
};

const isLineDisabledBtn = computed(() => {
	// 線形データ制御フラグ
	const statusFlag = !["complete", "complete_wgs84"].some(
		(s) => s === props.entity.getProcessingStatus(),
	);
	const entityVisibility = !props.entity.getVisibility();
	return statusFlag || entityVisibility;
});

const remove = async () => {
	// 線形データ削除
	window["viewer"].removeDXF(props.entity.getId());
	const deleteResult = await store.dispatch("delete_lineworks", props.entity);
	if (deleteResult.success) {
		// 削除成功
		store.commit("set_snackbar", {
			text: `${props.entity.getName()} ${t("REMOVE")} ${t("successful")}`,
			color: "rgba(0, 153, 0, 0.72)",
		});
	} else {
		// 削除失敗
		store.commit("set_snackbar", {
			text: `${props.entity.getName()} ${t("REMOVE")} ${t("failed")}`,
			color: "rgba(255, 0, 0, 0.72)",
		});
	}
};
</script>

<template>
    <v-list density="compact">
        <v-list-item class="menu-item" :disabled="isLineDisabledBtn">
            <v-checkbox density="compact" class="check-box mr-4" v-model="entity.is_projected_on_ground" hide-details
                @click.stop @update:model-value="projectionToGround" v-if="!isProjectionToGroundExecuting"></v-checkbox>
            <v-progress-circular v-else class="mr-4" :size="16" indeterminate></v-progress-circular>
            <div class="d-flex align-center">
                <span>{{ $t("PROJECTED_GROUND") }}</span>
            </div>
        </v-list-item>
        <v-list-item class="menu-item ion" @click="remove">
            <v-icon class="mr-4">mdi-delete</v-icon>
            <div class="d-flex align-center">
                <span>{{ $t("REMOVE") }}</span>
            </div>
        </v-list-item>
    </v-list>
</template>

<style lang="scss" scoped>
.menu-item {
    font-size: 13px;

    :deep(div.v-list-item__content) {
        display: flex;
        vertical-align: middle;
    }

    .check-box {
        width: 24px;
    }
}
</style>
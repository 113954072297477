/** グローバルなダイアログの開閉ステータスをリアクティブに扱うためのロジックを集約 */
import { computed } from "vue";
import { useStore } from "vuex";

export function useGlobalDialogs() {
	const store = useStore();

	/** 道路作成ダイアログ */
	const showPropertyDialogRoad = computed({
		get() {
			return store.getters["dialogs/isPropertyDialogRoadActive"];
		},
		set(value) {
			const dialogName = "propertyDialogRoad";
			if (value) store.commit("dialogs/showDialog", dialogName);
			else store.commit("dialogs/closeDialog", dialogName);
		},
	});

	/** 床掘作成ダイアログ */
	const showPropertyDialogTrench = computed({
		get() {
			return store.getters["dialogs/isPropertyDialogTrenchActive"];
		},
		set(value) {
			const dialogName = "propertyDialogTrench";
			if (value) store.commit("dialogs/showDialog", dialogName);
			else store.commit("dialogs/closeDialog", dialogName);
		},
	});

	/** 平場作成ダイアログ */
	const showPropertyDialogFlat = computed({
		get() {
			return store.getters["dialogs/isPropertyDialogFlatActive"];
		},
		set(value) {
			const dialogName = "propertyDialogFlat";
			if (value) store.commit("dialogs/showDialog", dialogName);
			else store.commit("dialogs/closeDialog", dialogName);
		},
	});

	return {
		showPropertyDialogRoad,
		showPropertyDialogTrench,
		showPropertyDialogFlat,
	};
}

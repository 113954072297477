import { i18n } from "@/i18n.js";
import { createRouter, createWebHistory } from "vue-router";
import ListPage from "../pages/ListPage";
import ProjectPage from "../pages/ProjectPage";
import store from "../store";
import { getCookie } from "../utils/getCookie";
import {
	checkPointCloudDataExistence,
	getPreconstructionNode,
	getSiteLatitudeAndLongitude,
	importDashboardPreconstruction,
	setUpSiteCoordinateSystem,
} from "../utils/sitePreparationUtils";
import versionUpdate from "../utils/versionUpdate";
import { flyToLongLatHeight } from "../utils/cesium-common";
const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			name: "top",
			path: "/top",
			component: ListPage,
		},
		{
			name: "project",
			path: "/",
			component: ProjectPage,
			beforeEnter: async (to, from, next) => {
				store.commit("setLoading", true);

				const siteId = to.query.siteId;
				const isFromTop = from.path === "/top";

				// Set up site coordinate system
				const setUpResult = await setUpSiteCoordinateSystem(siteId, isFromTop);

				if (setUpResult.status === 200) {
					const snackbars = []; // To display later

					// If localization file cannot be uploaded because it does not exist
					if (setUpResult.data?.existLocalizationFile === false) {
						snackbars.push({
							text: i18n.global.t("MESSAGE_NOT_FOUND_LOCALIZATION_FILE"),
							color: "rgba(245, 124, 0, 0.72)",
						});
					}

					if (!(await checkPointCloudDataExistence(siteId))) {
						// The site does not have point cloud data
						try {
							// Get preconstruction nodes
							const bucketInfo = await getPreconstructionNode(siteId);
							if (bucketInfo) {
								snackbars.push({
									text: i18n.global.t(
										"ACQUIRING_POINT_CLOUD_DATA_FROM_OTHER_APP",
									),
									color: "rgba(0, 153, 0, 0.72)",
								});

								// Import preconstruction data
								await importDashboardPreconstruction(siteId, bucketInfo);
							} else {
								// Not found preconstruction data
								throw new Error("Not found preconstruction data.");
							}
						} catch (e) {
							// no operation
						}
					}

					try {
						// Get latitude and longitude of the site
						const { latitude, longitude } =
							await getSiteLatitudeAndLongitude(siteId);

						flyToLongLatHeight({
							longitude: Number(longitude),
							latitude: Number(latitude),
							height: 1000,
							duration: 0,
						});

						// Get site information and save in store
						await store.dispatch("getSiteInfo", siteId);

						for (const item of snackbars) {
							// Keep remaining snackbar after redirect to site page
							await store.commit("set_snackbar", item); // Use await
						}

						next();
					} catch (error) {
						store.commit("reset_snackbars");
						// 現場取得エラーの場合トップ画面にリダイレクトしエラーメッセージを表示する
						let errorMessage;
						let color = undefined;
						if (error.response?.status === 403) {
							errorMessage = i18n.global.t("NO_PERMISSION_TO_VIEW_SITE");
						} else if (error.response?.status === 404) {
							errorMessage = i18n.global.t("NOT_EXIST_SITE_INFO");
							color = "rgba(245, 124, 0, 0.72)";
						} else {
							errorMessage = i18n.global.t("FAILED_TO_FETCH_SITE_INFO");
						}
						store.commit("set_snackbar", {
							text: errorMessage,
							color: color ?? "rgba(153, 0, 0, 0.72)",
						});
						next("/top");
					} finally {
						store.commit("setLoading", false);
					}
				} else if (setUpResult.status === 409) {
					// If the site coordinate system is conflicting between design3d and platform (or dashboard)
					store.commit("set_conflict_coordinate_system", setUpResult.data);
					store.commit("setLoading", false);
					next();
				} else {
					// Redirect to TOP and show snackbar
					const errorMessage =
						setUpResult.status === 403
							? i18n.global.t("NO_PERMISSION_TO_VIEW_SITE")
							: i18n.global.t("FAILED_TO_FETCH_SITE_INFO");
					store.commit("set_snackbar", {
						text: errorMessage,
						color: "rgba(153, 0, 0, 0.72)",
					});
					store.commit("setLoading", false);
					next("/top");
				}
			},
		},
	],
});

router.beforeEach(async (to, _from, next) => {
	const login_auth = getCookie("login_auth");

	// ブラウザ更新また別タブで立ち上げたときRefresh機能適用
	if (!login_auth || !window.name) {
		document.cookie = `requested_path=${to.fullPath}`; // ログイン後に遷移するパスを保存
		window.name = "init";
		window.location.href = `${import.meta.env.VITE_API_BASE}/login`;
	} else {
		//ソースがサーバーと一致するかどうかを判断し、一致しなければ画面をリフレッシュして最新版を取得します
		versionUpdate.isNewVersion();
		const requestedPath = getCookie("requested_path");
		if (requestedPath) {
			document.cookie = "requested_path=; max-age=0";
			next(requestedPath);
		} else if (to.path === "/" && !to.query.siteId) {
			next("/top");
		} else {
			next();
		}
	}
});

/**
 * TOP画面初期状態
 */
router.beforeEach((to, _from, next) => {
	const scUserId = getCookie("login_auth");
	const scCorporationId = getCookie("sc_corporation");

	if (to.path === "/top") {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			sc_corporation_id: scCorporationId,
			sc_user_id: scUserId,
		});
	}
	next();
});

/**
 * TOPのルーティングが完了した後に実行
 */
router.afterEach((to) => {
	if (to.path === "/top") {
		window.dataLayer.push({
			event: "sc_open",
		});
	}
});

export default router;

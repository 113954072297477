<!-- オブジェクト選択リスト -->
<template>
  <v-card>
    <v-card-title class="d-flex align-center justify-space-between">
      {{ $t("SELECT_OBJECT") }}
      <v-btn icon variant="flat" @click="close()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <!-- 仮設道路追加 -->
      <v-container class="ma-4">
        <v-row>
          <v-col>
            <v-card class="object-select-btn card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <img
                  class="mr-1"
                  :src="TemporaryRoadIcon"
                  alt="Temporary Road Icon"
                  >
                </v-col>
                <v-col cols="10" @click="clickOnDialog('road')">
                  <v-card-title class="card-btn-title"> {{ $t("Addtemporaryroad") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!-- 平場追加 -->
      <v-container class="object-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <img
                  class="mr-1"
                  :src="FlatFieldIcon"
                  alt="Temporary Road Icon"
                  >
                </v-col>
                <v-col cols="10" @click="clickOnDialog('ground')">
                  <v-card-title class="card-btn-title"> {{ $t("Addflatground") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!-- 床掘 -->
      <v-container class="object-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <img
                  class="mr-1"
                  :src="DiggingIcon"
                  alt="Temporary Road Icon"
                  >
                </v-col>
                <v-col cols="10" @click="clickOnDialog('trench')">
                  <v-card-title class="card-btn-title"> {{ $t("Addtemporarytrench") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!-- AIによる道路追加 -->
      <v-container class="object-select-btn ma-4" v-if="enableAIRoadCreation">
        <v-row>
          <v-col>
            <v-card class="card-btn" :disabled="isEpsg4326()">
              <v-row>
                <v-col cols="2" class="d-flex align-center justify-center">
                  <v-icon size="large"> mdi-robot </v-icon>
                </v-col>
                <v-col cols="10" @click="clickOnDialog('roadAI')">
                  <v-card-title class="card-btn-title"> {{ $t("RoadadditionbyAI") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import TemporaryRoadIcon from "@/assets/icons/customIcons/temporary-road-icon.svg";
import FlatFieldIcon from "@/assets/icons/customIcons/flat-field-icon.svg";
import DiggingIcon from "@/assets/icons/customIcons/digging-icon.svg";

export default {
	components: {},
	name: "SelectObject",
	props: {},
	mounted: () => {},
	data: () => ({
		TemporaryRoadIcon,
		FlatFieldIcon,
		DiggingIcon,
		enableAIRoadCreation:
			import.meta.env.VITE_ENABLE_AI_ROAD_CREATION === "true",
	}),
	methods: {
		close() {
			this.$emit("close");
		},
		clickOnDialog(type) {
			// 仮設道路追加
			if (type === "road") this.$emit("data-to-parent", "road");
			// 平場追加
			else if (type === "ground") this.$emit("data-to-parent", "ground");
			// 床掘追加
			else if (type === "trench") this.$emit("data-to-parent", "trench");
			// AIによる道路追加
			else if (type === "roadAI") this.$emit("data-to-parent", "roadAI");
			// ダイアログ選択画面閉じる
			this.close();
		},
		isEpsg4326() {
			return this.$store.state.site && this.$store.state.site.epsg === "4326"
				? true
				: false;
		},
	},
};
</script>

<style scoped>
.card-btn {
  display: flex;
  align-items: center;
  height: 90px;
}

.card-btn:hover {
  background-color: #2e2e2e;
}

.card-btn-title {
  white-space: normal
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.object-select-btn {
  width: 90%;
}
</style>

<template>
	<v-container id="Cesium" fluid class="cesium-wrapper pa-0" @click.left="DisableContextMenu" @contextmenu="show"
		@mousemove="fillXYZCoordinates">
		<v-menu v-model="showMenuForRoadCreation" :target="[x, y]" absolute>
			<road-creation-context-menu></road-creation-context-menu>
		</v-menu>
		<v-menu v-model="showMenuForArbitraryLineCreation" :target="[x, y]" absolute>
			<arbitrary-line-creation-context-menu></arbitrary-line-creation-context-menu>
		</v-menu>
		<v-menu v-model="showMenuForTrenchCreation" :target="[x, y]" absolute>
			<trench-creation-context-menu></trench-creation-context-menu>
		</v-menu>
		<v-menu v-model="showMenuForReferenceTopologyCreation" :target="[x, y]" absolute>
			<reference-topology-creation-context-menu></reference-topology-creation-context-menu>
		</v-menu>
		<v-menu v-model="showControlPointMenuForRoadEdit" :target="[x, y]" absolute>
			<road-edition-context-menu :path-id="controlPointPathInfo.pathId" :point-id="controlPointPathInfo.pointId"
				:show-remove-option="showRemoveOptionForControlPointMenuForRoadEdit"></road-edition-context-menu>
		</v-menu>
		<v-menu v-model="showControlPointMenuForTrenchEdit" :target="[x, y]" absolute>
			<trench-edit-context-menu :path-id="controlPointTrenchPathInfo.pathId"
				:point-id="controlPointTrenchPathInfo.pointId"
				:show-remove-option="showRemoveOptionForControlPointMenuForTrenchEdit"></trench-edit-context-menu>
		</v-menu>
		<v-menu v-model="showMenuForFlatGroundCreation" :target="[x, y]" absolute>
			<flat-ground-creation-context-menu></flat-ground-creation-context-menu>
		</v-menu>

		<v-menu v-model="showMenuForFlatGroundEdit" :target="[x, y]" absolute>
			<flat-ground-edition-context-menu :flat-ground-id="flatGroundInfo.flatGroundId"
				:point-id="flatGroundInfo.pointId"></flat-ground-edition-context-menu>
		</v-menu>
		<v-menu v-model="showMenuForFlatGroundOutlineEdit" :target="[x, y]" absolute>
			<flat-ground-outline-edit-context-menu :flat-ground-id="flatGroundInfo.flatGroundId"
				:position="flatGroundInfo.position"></flat-ground-outline-edit-context-menu>
		</v-menu>
		<v-menu v-model="showRoadSegmentMenuForRoadEdit" :target="[x, y]" absolute>
			<road-segment-context-menu :path-id="roadSegmentPathInfo.pathId" :segment-id="roadSegmentPathInfo.segId"
				:point="roadSegmentPathInfo.position"></road-segment-context-menu>
		</v-menu>
		<v-menu v-model="showTrenchSegmentMenuForTrenchEdit" :target="[x, y]" absolute>
			<trench-segment-context-menu :path-id="trenchSegmentPathInfo.pathId" :segment-id="trenchSegmentPathInfo.segId"
				:point="trenchSegmentPathInfo.position"></trench-segment-context-menu>
		</v-menu>
		<v-menu v-model="showMenuForCenterLineRightClick" :target="[x, y]" absolute>
			<road-center-line-context-menu :path-id="roadCenterLinePathInfo.pathId"
				:point="roadCenterLinePathInfo.position"></road-center-line-context-menu>
		</v-menu>
		<v-menu v-model="showMenuForTrenchCenterLineRightClick" :target="[x, y]" absolute>
			<trench-center-line-context-menu :path-id="trenchCenterLinePathInfo.pathId"
				:point="trenchCenterLinePathInfo.position"></trench-center-line-context-menu>
		</v-menu>
		<point-selection-overlay :show="modes.ai_road_point_selection" />

		<v-menu v-model="showMenuForPointSelection" :target="[x, y]" absolute>
			<point-selection-context-menu></point-selection-context-menu>
		</v-menu>
		<div v-if="isTwoPointAlignment" absolute>
			<Drawing2DImage2PointAignment></Drawing2DImage2PointAignment>
		</div>
		<div style="display: inline-flex; flex-direction: column; position: absolute; z-index: 1; right: 10px; top: 55px">
			<v-btn @click="click_plus" variant="outlined" class="btn1" style="border-radius: 16px 16px 0px 0px">
				<v-icon>mdi-plus</v-icon>
			</v-btn>
			<v-btn @click="click_minus" variant="outlined" class="btn1" style="border-radius: 0px 0px 16px 16px">
				<v-icon>mdi-minus</v-icon>
			</v-btn>
		</div>
		<CoordinatePanel v-if="this.$route.query.siteId" :coordinates="[...coordinates]"
			:show-progress="fetchCoordinateProgress" />
	</v-container>
</template>

<style>
.btn1 {
	width: 16px !important;
	min-width: 0px !important;
	background-color: rgba(0, 0, 0);
}
</style>

<script>
import "../../node_modules/cesium/Build/Cesium/Widgets/widgets.css";
import CoordinatePanel from "@/components/CoordinatePanel.vue";
import { chartBoardTabs } from "@/constant.js";
import {
	convertCoordinateToPointCloudCrs,
	convertCoordinatesToCesiumCrs,
	convertCoordinatesToPointCloudCrs,
} from "@/utils/cesium-common";
import { EarthBrainViewer } from "earthbrain-viewer";
import { mapMutations, mapState } from "vuex";
import ArbitraryLineCreationContextMenu from "./CesiumViewContextMenu/ArbitraryLineCreationContextMenu.vue";
import FlatGroundCreationContextMenu from "./CesiumViewContextMenu/FlatGroundCreationContextMenu.vue";
import FlatGroundEditionContextMenu from "./CesiumViewContextMenu/FlatGroundEditionContextMenu.vue";
import FlatGroundOutlineEditContextMenu from "./CesiumViewContextMenu/FlatGroundOutlineEditContextMenu.vue";
import PointSelectionContextMenu from "./CesiumViewContextMenu/PointSelectionContextMenu.vue";
import RoadCenterLineContextMenu from "./CesiumViewContextMenu/RoadCenterLineContextMenu.vue";
import RoadCreationContextMenu from "./CesiumViewContextMenu/RoadCreationContextMenu.vue";
import RoadEditionContextMenu from "./CesiumViewContextMenu/RoadEditionContextMenu.vue";
import RoadSegmentContextMenu from "./CesiumViewContextMenu/RoadSegmentContextMenu.vue";
import TrenchCenterLineContextMenu from "./CesiumViewContextMenu/TrenchCenterLineContextMenu.vue";
import TrenchCreationContextMenu from "./CesiumViewContextMenu/TrenchCreationContextMenu.vue";
import TrenchEditContextMenu from "./CesiumViewContextMenu/TrenchEditContextMenu.vue";
import TrenchSegmentContextMenu from "./CesiumViewContextMenu/TrenchSegmentContextMenu.vue";
import PointSelectionOverlay from "./PointSelectionOverlay";
import ReferenceTopologyCreationContextMenu from "./CesiumViewContextMenu/ReferenceTopologyCreationContextMenu.vue";
import Drawing2DImage2PointAignment from "./Drawing2DImage/Drawing2DImage2PointAignment.vue";

const debounce = (func, wait = 1000) => {
	let timerId;
	return function (...args) {
		if (timerId) {
			clearTimeout(timerId);
		}
		timerId = setTimeout(() => {
			func.apply(this, args);
		}, wait);
	};
};

export default {
	components: {
		CoordinatePanel,
		PointSelectionOverlay,
		RoadCreationContextMenu,
		TrenchCreationContextMenu,
		RoadEditionContextMenu,
		TrenchEditContextMenu,
		FlatGroundCreationContextMenu,
		FlatGroundEditionContextMenu,
		FlatGroundOutlineEditContextMenu,
		PointSelectionContextMenu,
		RoadSegmentContextMenu,
		TrenchSegmentContextMenu,
		RoadCenterLineContextMenu,
		TrenchCenterLineContextMenu,
		ArbitraryLineCreationContextMenu,
		ReferenceTopologyCreationContextMenu,
		Drawing2DImage2PointAignment,
	},
	computed: {
		...mapState(["modes", "panelName"]),
		...mapState("drawing2dImage", ["isTwoPointAlignment"]),
	},
	watch: {
		panelName() {
			this.showMenuForRoadCreation = false;
			this.showMenuForTrenchCreation = false;
			this.showMenuForFlatGroundCreation = false;
			this.showMenuForRoadEdit = false;
			this.showMenuForFlatGroundEdit = false;
			this.showMenuForFlatGroundOutlineEdit = false;
			this.showMenuForPointSelection = false;
			this.showMenuForCenterLineRightClick = false;
			this.showMenuForTrenchCenterLineRightClick = false;
			this.showMenuForReferenceTopologyCreation = false;
		},
	},
	data: () => ({
		showMenuForRoadCreation: false,
		showMenuForTrenchCreation: false,
		showMenuForFlatGroundCreation: false,
		showControlPointMenuForRoadEdit: false,
		showControlPointMenuForTrenchEdit: false,
		showRemoveOptionForControlPointMenuForRoadEdit: false,
		showRemoveOptionForControlPointMenuForTrenchEdit: false,
		showRoadSegmentMenuForRoadEdit: false,
		showTrenchSegmentMenuForTrenchEdit: false,
		showMenuForFlatGroundEdit: false,
		showMenuForFlatGroundOutlineEdit: false,
		showMenuForPointSelection: false,
		showMenuForCenterLineRightClick: false,
		showMenuForTrenchCenterLineRightClick: false,
		showMenuForArbitraryLineCreation: false,
		showMenuForReferenceTopologyCreation: false,
		x: 0,
		y: 0,
		offsetX: 0,
		offsetY: 0,
		isPathUC: false,
		isFlatGroundUC: false,
		isTrenchGroundUC: false,
		controlPointPathInfo: { pathId: undefined, pointId: undefined },
		controlPointTrenchPathInfo: { pathId: undefined, pointId: undefined },
		roadSegmentPathInfo: {
			pathId: undefined,
			segId: undefined,
			position: undefined,
		},
		roadCenterLinePathInfo: { pathId: undefined, position: undefined },
		trenchCenterLinePathInfo: { pathId: undefined, position: undefined },
		trenchSegmentPathInfo: {
			pathId: undefined,
			segId: undefined,
			position: undefined,
		},
		flatGroundInfo: { flatGroundId: undefined, pointId: undefined },
		all_sites: [],
		coordinates: [],
		fetchCoordinateProgress: false,
	}),
	methods: {
		...mapMutations(["set_modes"]),
		convertCoordinate(coordinates) {
			const regx = /[°'EN.]/g;
			if (coordinates.length > 0) {
				const latitudeStringArray = coordinates[0].replace(regx, "").split(" ");
				const longitudeStringArray = coordinates[1]
					.replace(regx, "")
					.split(" ");
				const latitudeString = `${latitudeStringArray[0]}.${latitudeStringArray[1]}${latitudeStringArray[2]}`;
				const longitudeString = `${longitudeStringArray[0]}.${longitudeStringArray[1]}${longitudeStringArray[2]}`;
				return [latitudeString, longitudeString];
			}
		},
		async getMetres(coordinates) {
			if (coordinates.length > 0 && this.$route.query.siteId) {
				const convertedCoords = await convertCoordinateToPointCloudCrs({
					x: coordinates[0],
					y: coordinates[1],
					z: coordinates[2],
				});
				return [convertedCoords.x, convertedCoords.y, convertedCoords.z];
			}
		},
		openWarningSnackbarForInvalidCtrlPtRoad() {
			this.$store.commit("set_snackbar", {
				text: `${this.$t("Roadctrlptwarning")}`,
				color: "rgba(245, 124, 0, 0.72)",
			});
		},
		openWarningSnackbarForInvalidCtrlPtTrench() {
			this.$store.commit("set_snackbar", {
				text: `${this.$t("Trenchctrlptwarning")}`,
				color: "rgba(245, 124, 0, 0.72)",
			});
		},
		openWarningSnackbarForInvalidCtrlPtLine() {
			this.$store.commit("set_snackbar", {
				text: `${this.$t("ARBITRARY_LINE_CTRL_PT_WARNING")}`,
				color: "rgba(245, 124, 0, 0.72)",
			});
		},
		openWarningSnackbarForNoPointCloudSelection() {
			this.$store.commit("set_snackbar", {
				text: `${this.$t("NoPointCloudSelected")}`,
				color: "rgba(245, 124, 0, 0.72)",
			});
		},
		show(e) {
			this.$store.dispatch("changePanel", "Cesium");
			e.preventDefault();
			this.showMenuForRoadCreation = false;
			this.showMenuForTrenchCreation = false;
			this.showMenuForFlatGroundCreation = false;
			this.showControlPointMenuForRoadEdit = false;
			this.showControlPointMenuForTrenchEdit = false;
			this.showRemoveOptionForControlPointMenuForRoadEdit = false;
			this.showRemoveOptionForControlPointMenuForTrenchEdit = false;
			this.showMenuForFlatGroundEdit = false;
			this.showMenuForFlatGroundOutlineEdit = false;
			this.showMenuForPointSelection = false;
			this.showRoadSegmentMenuForRoadEdit = false;
			this.showTrenchSegmentMenuForTrenchEdit = false;
			this.showMenuForCenterLineRightClick = false;
			this.showMenuForTrenchCenterLineRightClick = false;
			this.showMenuForArbitraryLineCreation = false;
			this.showMenuForReferenceTopologyCreation = false;
			this.x = e.clientX;
			this.y = e.clientY;
			this.offsetX = e.offsetX;
			this.offsetY = e.offsetY;
			this.$nextTick(async () => {
				if (this.$store.state.modes.inEditMode.isActive) {
					let isEditableSelected = false;
					const editableRoads = this.$store.state.objects.filter(
						(road) => road.edit === true,
					);
					if (editableRoads.length > 0) {
						//{ pathId: string | undefined; entityId: string | undefined, type: Pickable, entity: any}
						const info = await window["viewer"].pickPathandPoint(
							e.offsetX,
							e.offsetY,
							"cesiumView",
						);
						if (info.pathId !== undefined && info.entityId !== undefined) {
							const editableRoad = editableRoads.filter(
								(editable) => editable.id === info.pathId,
							);
							if (editableRoad.length > 0) {
								if (info.type === "road-control-point") {
									this.controlPointPathInfo.pathId = info.pathId;
									this.controlPointPathInfo.pointId = info.entityId;
									this.showControlPointMenuForRoadEdit = true;
									this.showRemoveOptionForControlPointMenuForRoadEdit = window[
										"viewer"
									].isRoadControlPointRemovable(info.pathId, info.entityId);
								} else if (info.type === "road-segment") {
									if (info.position) {
										const segId = window["viewer"].getSegmentIdByPoint(
											info.pathId,
											info.position,
										);
										this.roadSegmentPathInfo.pathId = info.pathId;
										this.roadSegmentPathInfo.segId = segId;
										this.roadSegmentPathInfo.position = info.position;
										this.showRoadSegmentMenuForRoadEdit = true;
									}
								} else if (info.type === "road-center-line") {
									if (info.position) {
										this.roadCenterLinePathInfo.pathId = info.pathId;
										this.roadCenterLinePathInfo.position = info.position;
										this.showMenuForCenterLineRightClick = true;
									}
								}
							}
						}
					}
					const editableFlatGround = this.$store.state.objects.filter(
						(flatGround) => flatGround.edit === true,
					);
					if (editableFlatGround.length > 0) {
						const flatGroundInfo = await window[
							"viewer"
						].pickFlatGroundEntities(e.offsetX, e.offsetY);
						for (const editable of editableFlatGround) {
							if (editable.id === flatGroundInfo.flatGroundId) {
								isEditableSelected = true;
								break;
							}
						}
						if (isEditableSelected) {
							this.showMenuForFlatGroundEdit = false;
							this.showMenuForFlatGroundOutlineEdit = false;
							this.flatGroundInfo.position = flatGroundInfo.position;
							this.flatGroundInfo.flatGroundId = flatGroundInfo.flatGroundId;
							this.flatGroundInfo.pointId = flatGroundInfo.pointId;
							if (flatGroundInfo.type === "flat-ground-control-point") {
								this.showMenuForFlatGroundEdit = true;
							} else if (flatGroundInfo.type === "flat-ground-edit-outline") {
								this.showMenuForFlatGroundOutlineEdit = true;
							}
						}
					}
					const editableTrench = this.$store.state.objects.filter(
						(trench) => trench.edit === true,
					);
					if (editableTrench.length > 0) {
						const info = await window["viewer"].pickTrenchEntities(
							e.offsetX,
							e.offsetY,
							"cesiumView",
						);
						if (info.type === "trench-control-point") {
							this.controlPointTrenchPathInfo.pathId = info.pathId;
							this.controlPointTrenchPathInfo.pointId = info.entityId;
							this.showControlPointMenuForTrenchEdit = true;
							this.showRemoveOptionForControlPointMenuForTrenchEdit = window[
								"viewer"
							].isTrenchControlPointRemovable(info.pathId, info.entityId);
						} else if (info.type === "trench-segment") {
							if (info.position) {
								this.trenchSegmentPathInfo.pathId = info.pathId;
								this.trenchSegmentPathInfo.segId = info.entityId;
								this.trenchSegmentPathInfo.position = info.position;
								this.showTrenchSegmentMenuForTrenchEdit = true;
							}
						} else if (info.type === "trench-center-line") {
							if (info.position) {
								this.trenchCenterLinePathInfo.pathId = info.pathId;
								this.trenchCenterLinePathInfo.position = info.position;
								this.showMenuForTrenchCenterLineRightClick = true;
							}
						}
					}
				}
				if (this.$store.state.modes.inCreationMode) {
					const isPathUC = window["viewer"].checkRoadUnderCreation();
					const isTrenchUC = window["viewer"].checkTrenchUnderCreation();
					const isArbitraryUC =
						window["viewer"].checkArbitraryLineUnderCreation();
					const isdragged = window["viewer"].isMouseDraggedAfterRightClick();
					if (isPathUC === true && isdragged == false) {
						this.isPathUC = isPathUC;
						this.showMenuForRoadCreation = true;
					}
					if (isTrenchUC === true && isdragged == false) {
						this.isTrenchUC = isTrenchUC;
						this.showMenuForTrenchCreation = true;
					}
					if (isArbitraryUC === true && isdragged == false) {
						this.isArbitraryUC = isArbitraryUC;
						this.showMenuForArbitraryLineCreation = true;
					}
					const isFlatGroundUC =
						window["viewer"].checkFlatGroundUnderCreation();
					if (isFlatGroundUC === true && isdragged == false) {
						this.isFlatGroundUC = isFlatGroundUC;
						this.showMenuForFlatGroundCreation = true;
					}
					const isReferenceTopologyUC =
						window["viewer"].checkDrawing2DImageUnderConstruction();
					if (isReferenceTopologyUC === true && isdragged === false) {
						this.isReferenceTopologyUC = isReferenceTopologyUC;
						this.showMenuForReferenceTopologyCreation = true;
					}
				}
				if (this.modes.ai_road_point_selection) {
					this.showMenuForPointSelection = true;
				}
			});
		},
		DisableContextMenu() {
			this.showMenuForFlatGroundEdit = false;
			this.showMenuForRoadCreation = false;
			this.showMenuForFlatGroundCreation = false;
			this.showControlPointMenuForRoadEdit = false;
			this.showControlPointMenuForTrenchEdit = false;
			this.showRemoveOptionForControlPointMenuForRoadEdit = false;
			this.showRemoveOptionForControlPointMenuForTrenchEdit = false;
			this.showMenuForPointSelection = false;
			this.showRoadSegmentMenuForRoadEdit = false;
			this.showTrenchSegmentMenuForTrenchEdit = false;
			this.showMenuForCenterLineRightClick = false;
			this.showMenuForTrenchCenterLineRightClick = false;
			this.showMenuForArbitraryLineCreation = false;
			this.showMenuForReferenceTopologyCreation = false;
		},
		click_plus() {
			window["viewer"].zoomInSite();
		},
		click_minus() {
			window["viewer"].zoomOutSite();
		},
		fillXYZCoordinates: debounce(function (e) {
			this.fetchCoordinateProgress = true;
			const coordinate = window["viewer"].getXYZCoordinates(
				e.offsetX,
				e.offsetY,
			);
			if (coordinate !== undefined) {
				this.getMetres(coordinate).then((coords) => {
					if (coords) {
						this.coordinates.length = 0;
						this.coordinates.push(...coords);
						this.fetchCoordinateProgress = false;
					}
				});
			}
		}, 100),
		keyDownHandler(event) {
			const key = event.key.toLowerCase();
			if (key === "escape" || key === "esc") {
				if (
					this.$store.getters["dialogs/isPropertyDialogRoadActive"] ||
					this.$store.getters["dialogs/isPropertyDialogTrenchActive"] ||
					this.$store.getters["dialogs/isPropertyDialogFlatActive"]
				) {
					// いずれかの作成ダイアログを開いている場合escイベントを無効化
					return;
				}
				window.dispatchEvent(new Event("disableAllEdits"));
				window.dispatchEvent(new Event("cancelCreateMode"));
				this.DisableContextMenu();
			}
		},
		onLineWorkCrossSectionClick(lineID, _pointType, _pointName) {
			if (
				this.$store.state.chartBoardCurrentTab.id !==
					chartBoardTabs.LINE_SECTION.id &&
				this.$store.state.draggedTab.id !== chartBoardTabs.LINE_SECTION.id
			) {
				this.$store.commit(
					"setChartBoardCurrentTab",
					chartBoardTabs.LINE_SECTION,
				);
			}
			// 線形断面ビューの場合
			if (lineID) {
				// viewer上でクリックされた点を持つ線形をアクティブな断面として設定
				this.$store.dispatch("setActiveLineCrossSectionById", lineID);
			} else {
				// viewer上でクリックされた点を持つ線形がない場合、アクティブな断面をリセット
				this.$store.dispatch("setActiveLineCrossSection", null);
			}
		},
	},
	async mounted() {
		console.log("Cesium Container Initialized");
		const viewer = new EarthBrainViewer({
			cesiumContainer: "Cesium",
			token: import.meta.env.VITE_CESIUM_ION_ACCESS_TOKEN || "",
			enableDesignDataOutline: true,
			enableSceneModePicker:
				import.meta.env.VITE_ENABLE_SCENE_MODE_PICKER === "true",
			worldTransformCallback: convertCoordinatesToCesiumCrs.bind(this),
			localTransformCallback: convertCoordinatesToPointCloudCrs.bind(this),
			lineWorkCrossSectionClickCallback:
				this.onLineWorkCrossSectionClick.bind(this),
		});
		window["viewer"] = viewer;
		this.$store.commit("set_viewer", viewer);

		window.addEventListener(
			"invalidControlPointForRoad",
			this.openWarningSnackbarForInvalidCtrlPtRoad.bind(this),
		);
		window.addEventListener(
			"invalidControlPointForTrench",
			this.openWarningSnackbarForInvalidCtrlPtTrench.bind(this),
		);
		window.addEventListener(
			"invalidControlPointForLine",
			this.openWarningSnackbarForInvalidCtrlPtLine.bind(this),
		);
		window.addEventListener(
			"noPointCloudSelected",
			this.openWarningSnackbarForNoPointCloudSelection.bind(this),
		);
		document.addEventListener("keydown", this.keyDownHandler.bind(this));
		// 勾配単位を設定
		window["viewer"].updateLongitudeRenderingType(
			this.$store.state.longitudinalSectionViewerSlopeUnit.nextGradient,
		);
		window["viewer"].updateCrossSectionRenderingType(
			this.$store.state.crossSectionViewerSlopeUnit.slopeGradient,
		);
	},
	unmounted() {
		window.removeEventListener(
			"invalidControlPointForRoad",
			this.openWarningSnackbarForInvalidCtrlPtRoad.bind(this),
		);
		window.removeEventListener(
			"invalidControlPointForTrench",
			this.openWarningSnackbarForInvalidCtrlPtTrench.bind(this),
		);
		window.removeEventListener(
			"invalidControlPointForLine",
			this.openWarningSnackbarForInvalidCtrlPtLine.bind(this),
		);
		window.removeEventListener(
			"noPointCloudSelected",
			this.openWarningSnackbarForInvalidCtrlPtTrench.bind(this),
		);
		document.removeEventListener("keydown", this.keyDownHandler.bind(this));
	},
};
</script>

<style lang="scss" scoped>
.cesium-wrapper {
	position: relative;
	height: calc(100vh - var(--v-layout-top) - var(--v-layout-bottom));
	transition: all 0.2s;
	border: 1px solid #434348;
}

.list-ChartContextMenu:hover {
	background-color: #0064ba !important;
}
</style>
<script setup>
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";

/** props定義 */
const props = defineProps({
	entity: Object,
});

/** 関数定義 */
const store = useStore();
const { t } = useI18n();

const remove = async () => {
	await store.dispatch("executeWithSpinner", async () => {
		try {
			if (props.entity.lineType === "sima") {
				await store.dispatch("simaLine/deleteSimaLine", props.entity);
			} else if (props.entity.lineType === "arbitrary") {
				await store.dispatch("arbitraryLine/deleteArbitraryLine", props.entity);
			}
			store.commit("set_snackbar", {
				text: `${props.entity.lineName} ${t("REMOVE")} ${t("successful")}`,
				color: "rgba(0, 153, 0, 0.72)",
			});
		} catch (e) {
			store.commit("set_snackbar", {
				text: `${props.entity.lineName} ${t("REMOVE")} ${t("failed")}`,
				color: "rgba(255, 0, 0, 0.72)",
			});
		}
	});
};
</script>

<template>
    <v-list density="compact">
        <v-list-item :disabled="isLineDisabledBtn()" @click="showCrossSectionDialog">
            <v-icon class="mr-4">icon:Pencil</v-icon>
            <span class="item-name">{{ $t("GENERATE_CROSS_SECTION") }}</span>
        </v-list-item>
        <v-list-item @click="remove">
            <v-icon class="mr-4">mdi-delete</v-icon>
            <span class="item-name">{{ $t("REMOVE") }}</span>
        </v-list-item>
    </v-list>
</template>

<script>
export default {
    name: "LineWorksNewCrossSectionThreeDotMenu",
    methods: {
        isLineDisabledBtn() {
            // 線形データ制御フラグ
            const statusFlag = this.entity.processingStatus !== "complete";
            const entityVisibility = !this.entity.isVisible;
            return statusFlag || entityVisibility;
        },
        showCrossSectionDialog() {
            const event = new CustomEvent("openPropertyDialog", {
                detail: { id: this.entity.lineId, type: 10, name: this.entity.lineName },
            });
            window.dispatchEvent(event);
        },
    }
}
</script>

<style scoped>
.item-name {
    font-size: 13px;
}
</style>
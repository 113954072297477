<template>
  <v-list density="compact">
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForTrenchEdit" :key="index"
      @click="onCMClickForTrenchSegmentEdit($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
import * as constant from "@/constant";

export default {
	props: {
		pathId: String,
		segmentId: String,
		point: Array,
	},

	data: () => ({
		itemsForTrenchEdit: [
			{ id: 1, title: "Straightline" },
			{ id: 2, title: "Curvedline" },
			{ id: 3, title: "AllStraightLine" },
			{ id: 4, title: "AllCurvedLine" },
			{ id: 5, title: "Undo" },
			{ id: 6, title: "Redo" },
		],
	}),
	methods: {
		onCMClickForTrenchSegmentEdit(e, id) {
			e.preventDefault();
			switch (id) {
				case 1:
					window["viewer"].updateTrenchSegmentToStraight(
						this.pathId,
						this.segmentId,
					);
					break;
				case 2:
					window["viewer"].updateTrenchSegmentToCurve(
						this.pathId,
						this.segmentId,
					);
					break;
				case 3:
					this.updateAllSegment(constant.segmentType.STRAIGHT);
					break;
				case 4:
					this.updateAllSegment(constant.segmentType.CURVE);
					break;
				case 5:
					window["viewer"].undo();
					break;
				case 6:
					window["viewer"].redo();
					break;
				// case 3:
				// window["viewer"].addControlPointOnTrenchSegment(
				//     this.pathId,
				//     this.segmentId,
				//     this.point
				//   );
				// window.dispatchEvent(new CustomEvent("addPointEvent", {
				//   detail: { trenchId: this.pathId, pointIndex: 0 },
				// }));
				// break;
			}
		},
		updateAllSegment(type) {
			const trench = JSON.parse(window["viewer"].getTrenchJSON(this.pathId));
			const settings = trench.trenchSettings;

			if (settings.segmentSettings.length) {
				settings.segmentSettings.forEach((item) => {
					item.type = type;
				});
			} else {
				for (let i = 0; i < trench.segments.length; i++) {
					settings.segmentSettings.push({
						startIndex: i,
						endIndex: i + 1,
						type,
						commonSetting: settings.commonSetting,
					});
				}
			}
			window["viewer"].updateTrenchSegmentsSettings(this.pathId, type);
		},
	},
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>

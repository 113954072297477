<template>
  <v-card class="px-4 pt-2 pb-4" color="rgba(41, 41, 47, 0.8)">
    <v-card-title> {{ $t("ADD_LINEWORK_INFO") }} </v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-row class="justify-center">
          <line-works-cross-section-input :cross-section-data="crossSectionData"></line-works-cross-section-input>
        </v-row>
        <v-row>
          <v-card-actions class="full-width justify-end mt-2">
            <v-btn variant="flat" @click="closeDialog" color="red">
              {{ $t("CANCEL") }}
            </v-btn>
            <v-btn variant="flat" @click="generateCrossSection()" color="primary" :disabled="!isValid">
              {{ $t("ADD") }}
            </v-btn>
          </v-card-actions>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>


<script>
import rules from "@/utils/validation-rules.js";
import { mapActions, mapGetters } from "vuex";
import LineWorksCrossSectionInput from "./LineWorksCrossSectionInput.vue";
import { defaultLineWorksCrossSection } from "./default";

export default {
	name: "LineWorksCrossSectionDialog",
	components: { LineWorksCrossSectionInput },
	props: {
		id: Number,
	},
	data: () => ({
		isValid: false,
		crossSectionData: defaultLineWorksCrossSection(),
		rules: {
			...rules,
			required: (value) => !!value || "*",
		},
	}),
	methods: {
		...mapActions("simaLine", ["addCrossSectionSettingForSima"]),
		...mapActions("arbitraryLine", ["addCrossSectionSettingForArbitraryLine"]),
		...mapGetters(["linearList"]),
		resetProperty() {
			this.crossSectionData = defaultLineWorksCrossSection();
			this.isValid = true;
			if (this.$refs.line_works_cross_section_input) {
				this.$refs.line_works_cross_section_input.resetProperty({
					leftWidth: this.leftWidth,
					rightWidth: this.rightWidth,
					interpolationPitch: this.interpolationPitch,
				});
			}
		},
		closeDialog() {
			window["viewer"].cancelArbitraryCreation();
			this.$emit("close", false);
		},
		async generateCrossSection() {
			try {
				// ローディング開始
				await this.$store.dispatch("executeWithSpinner", async () => {
					try {
						const setting = {
							leftWidth: this.crossSectionData.leftWidth,
							rightWidth: this.crossSectionData.rightWidth,
							interpolationPitch: this.crossSectionData.interpolationPitch,
							samplingSize: this.crossSectionData.samplingSize,
						};
						const linearData = this.linearList().find(
							(item) => item.lineId === this.id,
						);
						if (linearData.lineType === "arbitrary") {
							await this.addCrossSectionSettingForSima({
								simaLine: linearData,
								crossSectionSetting: setting,
							});
						} else if (linearData.lineType === "sima") {
							await this.addCrossSectionSettingForSima({
								simaLine: linearData,
								crossSectionSetting: setting,
							});
						}
					} catch (e) {
						// Todo display appropriate message
						console.error(e);
					}
				});
			} finally {
				this.$emit("close", false);
			}
		},
	},
};
</script>

<style scoped>
.mt-0 {
  margin-top: 0;
}

.mt-2 {
  margin-top: 2em;
}

.pt-2 {
  padding-top: 2em;
}

.full-width {
  width: 100%;
}
</style>
<script setup>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

/** props定義 */
const props = defineProps({
	entity: Object,
	draggable: Boolean,
});

/** 関数定義 */
const store = useStore();
const { t } = useI18n();
const route = useRoute();

const remove = async () => {
	// オブジェクトリストの最新化
	try {
		await store.dispatch("get_obj_list", route.query.siteId);
	} catch {
		store.commit("set_snackbar", {
			text: `${t("REFRESH_OBJECT_LIST")} ${t("failed")}`,
			color: "rgba(153, 0, 0, 0.72)",
		});
		return;
	}
	// 擦り付け先に設定されているオブジェクトの存在チェック
	const associateObject = store.state.objects.find((obj) => {
		// associatePointCloudId または destination.id に当該設計データのアセットIDが設定されている場合削除不可
		const associatePointCloudId =
			obj.json && JSON.parse(obj.json)?.associatePointCloudId;
		const destinationId = obj.settings.commonSettings.destination?.id;
		// 削除対象の点群が摺り付け点群に設定されているオブジェクトを返す
		return (
			(associatePointCloudId &&
				props.entity.getAssetIdNum() === associatePointCloudId) ||
			(destinationId && props.entity.getAssetIdNum() === destinationId)
		);
	});
	// 擦り付け先点群に設定されていればエラーダイアログを表示
	if (associateObject) {
		store.commit("set_snackbar", {
			text: t("CANNOT_DELETE_ASSOCIATED_POINT_CLOUD"),
			color: "rgba(153, 0, 0, 0.72)",
		});
		return;
	}
	const deleteResult = await store.dispatch("delete_design", props.entity);
	if (deleteResult.success) {
		// 削除成功
		store.commit("set_snackbar", {
			text: `${props.entity.getName()} ${t("REMOVE")} ${t("successful")}`,
			color: "rgba(0, 153, 0, 0.72)",
		});
	} else {
		// 削除失敗
		store.commit("set_snackbar", {
			text: `${props.entity.getName()} ${t("REMOVE")} ${t("failed")}`,
			color: "rgba(255, 0, 0, 0.72)",
		});
	}
};
</script>

<template>
  <span>
    <span v-if="timeStamp" class="ml-4">{{ timeStamp }}</span>
    <v-card-actions class="list-item py-0" :style="isActive ? 'background-color: #0064BA;' : ''">
      <!-- アップロードステータス -->
      <div v-if="entity.getType() === 'design' &&
      entity.getAssetId() &&
      (showUploadProgress || isUploadError)
      ">
        <progress-circle class="mr-1 my-3" :entity="entity" :showUploadProgress="showUploadProgress"
          :isUploadError="isUploadError" @update-upload-progress="(value) => {
        showUploadProgress = value;
      }
      " @update-upload-error-status="(value) => {
        isUploadError = value;
      }
      ">
        </progress-circle>
      </div>
      <v-btn v-if="draggable" icon size="24" class="handle" style="cursor: grab">
        <v-icon> mdi-drag </v-icon>
      </v-btn>
      <v-btn icon size="24" @click="toggle_visibility" :disabled="isDisabledVisibilityBtn" class="ml-0">
        <v-icon>
          {{ entity.getVisibility() ? "icon:View" : "icon:View-Off" }}
        </v-icon>
      </v-btn>
      <v-btn icon size="36" @click="toggle_expansion" class="ml-0" :disabled="entity.getType() !== 'design'">
        <v-icon>
          mdi-play {{ entity.getExpansion() ? "mdi-rotate-90" : "" }}
        </v-icon>
      </v-btn>
      <ListItemLabel :text="entity?.getName() ?? ''" />
      <v-btn @click="focus" :disabled="isDisabledFocusBtn" icon size="24">
        <v-icon size="small">icon:Search</v-icon>
      </v-btn>
      <v-menu transition="slide-y-transition">
        <template v-slot:activator="{ props }">
          <v-btn icon v-bind="props" size="24">
            <v-icon> icon:Overflow-Menu-Vertical </v-icon>
          </v-btn>
        </template>
        <v-list density="compact">
          <v-list-item @click="remove">
            <v-icon class="mr-4">mdi-delete</v-icon>
            <span style="font-size: 13px">{{ $t("REMOVE") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- 処理ステータス -->
      <div v-if="entity.getProcessingStatus()">
        <processing-status :status="entity.getProcessingStatus()" :processingStatus="constants.designProcessingStatus">
        </processing-status>
      </div>
    </v-card-actions>
    <div class="pl-7" v-show="entity.getExpansion()">
      <v-card-actions>
        <span> {{ $t("DESIGN") }} {{ $t("COLOR") }}</span>
        <v-menu :disabled="!isVisible">
          <template v-slot:activator="{ props }">
            <v-avatar :color="meshColor" size="20px" v-bind="props" class="mx-3">
            </v-avatar>
          </template>
          <v-color-picker class="ma-0" hide-canvas hide-inputs hide-sliders show-swatches
            @update:model-value="updateDesignMeshColor" mode="hexa" :model-value="meshColor"></v-color-picker>
        </v-menu>
      </v-card-actions>
      <v-card-actions class="py-0 px-5">
        {{ $t("OPACITY") }}
        <v-slider class="ml-1" v-model="transparency" :disabled="isDisabledTransparency"
          @end="saveSettings" hide-details max="1" step="0.01" color="primary" track-size="0.5"
          thumb-size="14"></v-slider>
      </v-card-actions>
    </div>
  </span>
</template>

<script>
import * as cesiumCommon from "@/utils/cesium-common";
import ProgressCircle from "./ProgressCircle";
import * as constants from "@/constant.js";
import ProcessingStatus from "@/components/common/ProcessingStatus.vue";
import ListItemLabel from "@/components/Project/ListItemLabel.vue";
import axios from "axios";
import { hexToRgb } from "../../utils/cesium-common";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);

export default {
  name: "DesignListItem",
  components: {
    ProgressCircle,
    ProcessingStatus,
    ListItemLabel,
  },
  data() {
    return {
      showUploadProgress: true,
      isUploadError: false,
      constants,
    };
  },
  computed: {
    isAssetUploaded() {
      return (
        this.entity.getType() === "design" && this.entity.getIsAssetUploaded()
      );
    },
    isActive() {
      const activePointCloudId = this.$store.getters.activeDesign?.getAssetId();
      return (
        activePointCloudId !== undefined &&
        activePointCloudId === this.entity.getAssetId()
      );
    },
    /**
     * 設計データの透明度
     */
    transparency: {
      get() {
        if (this.entity.getType() !== "design") {
          return 0;
        }
        return this.entity.getTransparency();
      },
      set(value) {
        this.entity.setTransparency(value);
      },
    },

    meshColor: {
      get() {
        return this.entity.getMeshColor()
          ? this.entity.getMeshColor()
          : "#FFFFFF";
      },
      set(value) {
        this.entity.setMeshColor(value);
      },
    },
    /**
     * 可視状態切り替えボタンの活性状態を制御する
     * 以下の場合非活性
     * ・タイリング途中
     * ・アセットがアップロードされていない
     */
    isDisabledVisibilityBtn() {
      return (
        this.entity.getTilingProgress() ||
        this.isAssetUploaded === "PENDING" ||
        this.isAssetUploaded === "ERROR"
      );
    },
    /**
     * 透明度スライダーの活性状態を制御する
     */
    isDisabledTransparency() {
      // 非表示状態の場合は非活性
      if (!this.entity.getVisibility()) return true;
      else return false;
    },
    /**
     * フォーカスボタンの活性状態を制御する
     */
    isDisabledFocusBtn() {
      // 非表示状態の場合は非活性
      if (!this.entity.getVisibility()) return true;
      else return false;
    },
    isVisible() {
      return this.entity.getVisibility();
    },

    timeStamp() {
      return this.entity.getTimeStamp();
    },
  },
  watch: {
    // アップロードステータスがPENDINGになれば再度ポーリング開始
    isAssetUploaded(newValue) {
      if (newValue === "PENDING") {
        this.showUploadProgress = true;
        this.isUploadError = false;
      }
    },
    transparency() {
      let transparency_val = parseFloat(this.transparency.toFixed(2));
      try {
        window["viewer"].updateTilesetTransparency(
          parseInt(this.entity.getAssetId()),
          "design",
          transparency_val
        );
      } catch (e) {
        console.error(e);
      }
    },
  },
  methods: {
    async toggle_visibility() {
      await cesiumCommon.toggleDesignVisibility(this.entity);
    },
    toggle_expansion() {
      this.entity.setExpansion(!this.entity.getExpansion());
    },
    focus() {
      cesiumCommon.zoomToTileset(this.entity);
    },
    updateDesignMeshColor(c) {
      this.meshColor = c;
      window["viewer"].updateTilesetColor(
        this.entity.getAssetId(),
        hexToRgb(c),
        "design"
      );
      this.saveSettings();
    },

    //updates design properties on server
    saveSettings() {
        axios.put(
            `${import.meta.env.VITE_API_BASE}/design/setting/${this.$route.query.siteId}/${this.entity.getId()}`,
            {
                transparency: this.transparency,
                color: this.meshColor
            }
        )
    }
  },
};
</script>

<style lang="scss" scoped>
.list-item {
  align-items: center;
}
</style>

<!-- 線形アクション選択 -->
<template>
  <v-card>
    <v-card-title class="d-flex align-center justify-space-between">
      {{ $t("SELECT_CREATION_TYPE") }}
      <v-btn icon variant="flat" @click="close()">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <!-- アップロード -->
      <v-container class="action-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon> icon:Export</v-icon>
                </v-col>
                <v-col cols="10" @click="openTerrainDataDialog">
                  <v-card-title class="card-btn-title"> {{ $t("UPLOAD_LINE_WORKS") }} </v-card-title>
                  <v-card-text>SIMA(.sim)</v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!-- 任意線形作成 -->
      <v-container class="action-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="icon">
                  <v-icon>mdi-vector-polyline</v-icon>
                </v-col>
                <v-col cols="10" @click="toggleLineWorkCreationMode">
                  <v-card-title class="card-btn-title"> {{ $t("CREATE_LINEWORK") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <!-- 道路・床掘中心線から作成 -->
      <v-container class="action-select-btn ma-4">
        <v-row>
          <v-col>
            <v-card class="card-btn">
              <v-row>
                <v-col cols="2" class="d-flex align-center justify-center">
                  <img :src="temporaryRoadIcon" class="mr-1" alt="Temporary Road Icon" />
                </v-col>
                <v-col cols="10" @click="openSelectObjectForLinearCreationDialog">
                  <v-card-title class="card-btn-title"> {{ $t("CREATE_LINEWORK_FROM_OBJECT") }} </v-card-title>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import temporaryRoadIcon from "@/assets/icons/customIcons/temporary-road-icon.svg";
import { mapMutations } from "vuex";

export default {
	data() {
		return {
			temporaryRoadIcon,
		};
	},
	methods: {
		...mapMutations(["set_snackbar"]),
		close() {
			this.$emit("close");
		},
		toggleLineWorkCreationMode() {
			this.$emit("data-to-parent");
			this.close();
		},
		openTerrainDataDialog() {
			const allowedFileTypes = ".sim";
			const axisShow = false;
			this.$emit("open-terrain-data", allowedFileTypes, axisShow);
		},
		openSelectObjectForLinearCreationDialog() {
			const dummyId = 1;
			const event = new CustomEvent("openPropertyDialog", {
				detail: { id: dummyId, type: 11 },
			});
			window.dispatchEvent(event);
			this.close();
		},
	},
};
</script>

<style scoped>
.card-btn {
  display: flex;
  align-items: center;
  height: 110px;
}

.card-btn:hover {
  background-color: #2e2e2e;
}

.card-btn-title {
  white-space: normal
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-select-btn {
  width: 90%;
}
</style>

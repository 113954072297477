<template>
  <v-list density="compact">
    <v-list-item class="list-ChartContextMenu" v-for="(item, index) in itemsForCreation" :key="index"
      @click="onContextMenuClickForCreation($event, item.id)">
      <v-list-item-title>
        {{ $t(item.title) }}
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
	name: "ReferenceTopologyCreationContextMenu",
	data: () => ({
		itemsForCreation: [
			{ id: 1, title: "Finishdrawing" },
			{ id: 2, title: "Cancel" },
		],
	}),
	methods: {
		onContextMenuClickForCreation(e, id) {
			e.preventDefault();
			switch (id) {
				case 1:
					window["viewer"].finishDrawing2DImageConstruction();
					break;
				case 2:
					window["viewer"].cancelDrawing2DImageConstruction();
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.list-ChartContextMenu:hover {
  background-color: #0064ba !important;
}
</style>

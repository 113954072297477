<template>
  <!-- <div class="fullscreen"> -->
    <div v-for="(point, index) in pointsOfTwoPointAlignment">
			<DragableContainer :position="point.position" :color="point.color">
      <Drawing2DImageFloatingPoint :source="point.source" :destination="point.destination" :position="point.position" :color="point.color"
        :locked="lockedIndex === index" @onLock="lockPoint(index)">
      </Drawing2DImageFloatingPoint>
		</DragableContainer>
    </div>
    <div v-if="isTwoPointAlignment && pointsOfTwoPointAlignment?.length === 2" class="topoverlap">
      <v-btn
    min-width="0"
    height="auto"
    class="btn"
    variant="plain"
    @click.stop="onApply"
  >Apply</v-btn>
    </div>
  <!-- </div> -->
</template>

<script>
import { mapState } from "vuex";
import Drawing2DImageFloatingPoint from "./Drawing2DImageFloatingPoint.vue";
import { mapActions } from "vuex/dist/vuex.cjs.js";
import DragableContainer from "./DragableContainer.vue";

export default {
	components: {
		Drawing2DImageFloatingPoint,
		DragableContainer,
	},
	computed: {
		...mapState("drawing2dImage", [
			"pointsOfTwoPointAlignment",
			"isTwoPointAlignment",
		]),
	},
	watch: {
		pointsOfTwoPointAlignment() {
			if (this.lockedIndex === null) {
				const count = this.pointsOfTwoPointAlignment.filter(
					(item) => !!item,
				).length;
				this.lockedIndex = count === 2 ? 1 : null;
			}
		},
	},
	data() {
		return {
			lockedIndex: null,
		};
	},
	methods: {
		...mapActions("drawing2dImage", [
			"deletePointForTwoPointsAlignmentByIndex",
		]),
		lockPoint(index) {
			this.lockedIndex = index;
			this.$store.getters.viewer.lockPointFor2PointAlignment(this.lockedIndex);
		},
		deletePoint(index) {
			// this.$store.getters.viewer.disablePointFor2PointAlignment(index);
			this.deletePointForTwoPointsAlignmentByIndex({ index });
		},
		onClickAway(e) {
			// e.stopImmediatePropagation();
			console.log("Click away");
			this.isFocussed = false;
		},
		onApply() {
			this.$store.getters.viewer.end2PointsAlignment({
				twoPointArgs: {
					source1: {
						x: this.pointsOfTwoPointAlignment[0].source.x,
						y: this.pointsOfTwoPointAlignment[0].source.y,
					},
					source2: {
						x: this.pointsOfTwoPointAlignment[1].source.x,
						y: this.pointsOfTwoPointAlignment[1].source.y,
					},
					destination1: {
						x: this.pointsOfTwoPointAlignment[0].destination.x,
						y: this.pointsOfTwoPointAlignment[0].destination.y,
					},
					destination2: {
						x: this.pointsOfTwoPointAlignment[1].destination.x,
						y: this.pointsOfTwoPointAlignment[1].destination.y,
					},
				},
			});
		},
	},
	mounted() {
		console.log("Full screen mounted");
	},
};
</script>

<style lang="scss" scoped>
.fullscreen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vw;
  background-color: transparent;
  z-index: 9999;
}
.topoverlap {
  position: absolute;
  background-color: rgba(0.5,0.5,0.5,0.5);
  height: 2rem;;
  width: 100%;
  top: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
}
.btn {
  background-color: var(--primary-bg);
}
</style>

const state = {
	propertyDialogRoad: {
		isActive: false,
	},
	propertyDialogTrench: {
		isActive: false,
	},
	propertyDialogFlat: {
		isActive: false,
	},
};

const getters = {
	isPropertyDialogRoadActive(state) {
		return state.propertyDialogRoad.isActive;
	},
	isPropertyDialogTrenchActive(state) {
		return state.propertyDialogTrench.isActive;
	},
	isPropertyDialogFlatActive(state) {
		return state.propertyDialogFlat.isActive;
	},
};

const mutations = {
	showDialog(state, dialogName) {
		state[dialogName].isActive = true;
	},
	closeDialog(state, dialogName) {
		state[dialogName].isActive = false;
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
};

export default class Line {
	constructor(
		type,
		id,
		name,
		path,
		processingStatus,
		createdAt,
		siteId,
		line_type,
	) {
		this._type = type;
		this._id = id;
		this._name = name;
		this._path = path;
		this._processingStatus = processingStatus;
		this._createdAt = new Date(createdAt);
		this._siteId = siteId;
		this._visible = false;
		this._crossSectionVisible = false;
		this._expanded = false;
		this._loadedInViewer = false;
		this._crossSectionLoadedInViewer = false;
		this.is_asset_uploaded = "PENDING";
		this._lineType = line_type;
		this.is_crs_conversion_in_progress = false;
	}

	update(lineworks_info) {
		this._type = lineworks_info.data_type;
		this._name = lineworks_info.lineworks_name;
		this._path = lineworks_info.lineworks_path;
		this._processingStatus = lineworks_info.processing_status;
		this._siteId = lineworks_info.site_id;
	}

	// getter
	getCreatedAt() {
		return this._createdAt;
	}

	getType() {
		return this._type;
	}

	getLineType() {
		return this._lineType;
	}

	getId() {
		return this._id;
	}

	getName() {
		return this._name;
	}

	getAssetId() {
		return this._id;
	}

	getAssetIdNum() {
		return Number.parseInt(this._id);
	}

	getExpansion() {
		return this._expanded;
	}

	getVisibility() {
		return this._visible;
	}

	getCrossSectionVisibility() {
		return this._crossSectionVisible;
	}

	getProcessingStatus() {
		return this._processingStatus;
	}

	getSiteId() {
		return this._siteId;
	}

	getTimeStamp() {
		return [
			this._createdAt.toLocaleString("en-US", { day: "2-digit" }),
			this._createdAt.toLocaleString("en-US", { month: "short" }) + ",",
			this._createdAt.toLocaleString("en-US", { year: "numeric" }),
			this._createdAt.toLocaleString("en-US", {
				hour: "numeric",
				minute: "2-digit",
			}),
		].join(" ");
	}

	getIsCRSConversionInProgress() {
		return this.is_crs_conversion_in_progress;
	}

	isLoadedInViewer() {
		return this._loadedInViewer;
	}

	isCrossSectionLoadedInViewer() {
		return this._crossSectionLoadedInViewer;
	}

	// setter

	setVisibility(visible) {
		this._visible = visible;
	}

	setLoadedInViewer(loaded) {
		this._loadedInViewer = loaded;
	}

	setCrossSectionVisibility(visible) {
		this._crossSectionVisible = visible;
	}

	setCrossSectionLoadedInViewer(loaded) {
		this._crossSectionLoadedInViewer = loaded;
	}

	setIsAssetUploaded(isAssetUploaded) {
		this.is_asset_uploaded = isAssetUploaded;
	}

	getIsAssetUploaded() {
		return this.is_asset_uploaded;
	}

	getCrossSectionSetting() {
		return this._cross_section_setting;
	}

	setCrossSectionSetting(crossSectionSetting) {
		this._cross_section_setting = crossSectionSetting;
	}

	setExpansion(expanded) {
		this._expanded = expanded;
	}

	setIsCRSConversionInProgress(status) {
		return (this.is_crs_conversion_in_progress = status);
	}
}

/**
 * storeのobjectsにのオブジェクトをリアクティブに更新するためのMixin
 * 更新対象のオブジェクトはidがobjIdと一致するもの
 * objIdは呼び出し元のprops,data、computedのいずれかに定義されている必要がある
 */
export default {
	computed: {
		obj: {
			get() {
				return this.$store.state.objects.find((item) => item.id == this.objId);
			},
			set(obj) {
				this.$store.commit("updateObjInObjects", obj);
			},
		},
	},
};

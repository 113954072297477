<template>
	<v-card>
		<v-card-title class="d-flex align-center justify-space-between">
			{{ $t("SAVE") }}
			<v-btn icon variant="flat" @click="close()">
				<v-icon> mdi-close </v-icon>
			</v-btn>
		</v-card-title>
		<v-container style="width: 90%" class="ma-4">			
			<v-row>{{ $t("ASSET_NAME") }} </v-row>
			<v-row>
				<v-col>
					<v-text-field variant="outlined" :rules="nameRules" v-model="name" required />
				</v-col>
			</v-row>
			<v-row>{{ $t("ASSET_CAPTURE_DATE") }}</v-row>
			<v-row>
				<v-col>
					<v-text-field variant="outlined" type="date" v-model="date" required />
				</v-col>
				<v-col>
					<v-text-field variant="outlined" type="time" time-format="HH:mm" class="text-center" v-model="time"
						required />
				</v-col>
			</v-row>
			<span>
				<v-row>{{ $t("PROJECTED_GROUND") }}</v-row>
				<v-row>
					<v-col>
						<v-switch v-model="showIsDraped" inset hide-details color="success"></v-switch>
					</v-col>
				</v-row>
			</span>
			<v-row>
				<v-col>
					<v-btn color="primary" class="uploadBtn" id="upload_btn" :disabled="isBtnDisabled" rounded
						@click="saveData()">
						{{ $t("SAVE") }}
					</v-btn>
				</v-col>
			</v-row>
		</v-container>
	</v-card>
</template>
<script>
export default {
	name: "saveReferenceTopologyDialog",
	mounted() {
		this.setDateTime();
	},
	data: () => ({
		file: [],
		name: "",
		nameRules: [
			(v) => v.length <= 10 || "Name must be less than 10 characters",
		],
		date: undefined,
		time: undefined,
		showIsDraped: false,
	}),
	computed: {
		isBtnDisabled() {
			if (
				this.name &&
				this.date &&
				this.time &&
				this.name.length <= 10 &&
				!/^\s*$/.test(this.name)
			) {
				return false;
			}
			return true;
		},
	},
	methods: {
		reset() {
			this.name = "";
			this.date = undefined;
			this.time = undefined;
			this.showIsDraped = false;
		},
		close() {
			this.reset();
			this.$emit("close");
		},
		saveData() {
			console.log("--START UPLOAD--");
			if (!this.name || !this.date || !this.time) {
				alert("Please fill all the fields !!!");
			} else {
				const data = [this.name, this.date, this.time, this.showIsDraped];
				this.reset();
				this.$emit("save", data);
			}
		},
		setDateTime() {
			const today = new Date();
			// 日付を設定
			this.date =
				today.getFullYear() +
				"-" +
				(today.getMonth() + 1).toString().padStart(2, "0") +
				"-" +
				today.getDate().toString().padStart(2, "0");

			// 時間を設定
			this.time =
				today.getHours().toString().padStart(2, "0") +
				":" +
				today.getMinutes().toString().padStart(2, "0");
		},
	},
};
</script>
<style scoped>

.uploadBtn {
	margin-bottom: 3%;
}
</style>
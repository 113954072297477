<!-- 道路・平場・床掘 -->
<template>
	<div>
		<select-object @data-to-parent="receiveDataFromChild" @close="closeDialog()" />
	</div>
</template>
  
<script>
import SelectObject from "../ObjectData/SelectObject.vue";

export default {
	name: "ObjectDataDialog",
	components: {
		SelectObject,
	},
	props: {},
	mounted: () => {},
	data: () => ({}),
	methods: {
		closeDialog() {
			this.$emit("close");
		},
		receiveDataFromChild(data) {
			this.$emit("data-to-parent", data);
		},
	},
};
</script>
  
import axios from "axios";
import LineWorks from "../../models/LineWorks";

const state = {
	simaLineList: [],
};

const mutations = {
	setSimaLineList(state, simaLineList) {
		state.simaLineList = simaLineList;
	},
	addSimaLine(state, simaLine) {
		if (simaLine instanceof LineWorks) {
			state.simaLineList.push(simaLine);
		} else {
			const line = new LineWorks(
				simaLine.linear_id,
				simaLine.linear_name,
				simaLine.linear_path,
				simaLine.processing_status,
				simaLine.create_at,
				simaLine.site_id,
				"sima",
			);
			state.simaLineList.push(line);
		}
	},
	deleteSimaLine(state, simaLine) {
		state.simaLineList = state.simaLineList.filter(
			(item) => item.lineId !== simaLine.lineId,
		);
	},
	updateSimaLine(state, simaLine) {
		const index = state.simaLineList.findIndex(
			(item) => item.lineId === simaLine.lineId,
		);
		if (index !== -1) {
			state.simaLineList[index] = simaLine;
			// リアクティブにするためにstateを新規配列で上書き
			state.simaLineList = [...state.simaLineList];
		}
	},
};

const actions = {
	async getSimaLineList({ state, commit }, site_id) {
		const simaLineList = [];
		try {
			const simalines = await axios.get(
				`${import.meta.env.VITE_API_BASE}/linearfile/${site_id}`,
				{
					auth: this.state.authObject,
				},
			);

			for (const simaLineData of simalines.data) {
				const currentLine = state.simaLineList.find(
					(entity) => entity.lineId === simaLineData.lineworks_id,
				);
				// 既にリストに存在するかどうかでAddかUpdateか分岐
				if (currentLine) {
					// Update
					currentLine.update({
						lineName: simaLineData.linear_name,
						processingStatus: simaLineData.processing_status,
					});
					simaLineList.push(currentLine);
				} else {
					// Add
					const newLine = new LineWorks(
						simaLineData.linear_id,
						simaLineData.linear_name,
						simaLineData.linear_path,
						simaLineData.processing_status,
						simaLineData.create_at,
						simaLineData.site_id,
						"sima",
					);
					simaLineList.push(newLine);
				}
			}
		} catch (e) {
			// Todo display appropriate message
			console.warn(e);
			throw e;
		}

		try {
			const response = await axios.get(
				`${import.meta.env.VITE_API_BASE}/crossSection/linearfile/${site_id}`,
				{
					auth: this.state.authObject,
				},
			);
			const simaLineCrossSections = response.data.filter(
				(l) => l.related_object_type === 2,
			);
			for (const line of simaLineList) {
				for (const crossSection of simaLineCrossSections) {
					if (line.lineId === crossSection.related_object_id) {
						line.crossSectionSetting = {
							id: crossSection.cross_section_id,
							name: crossSection.name,
							leftWidth: crossSection.left_side_width,
							rightWidth: crossSection.right_side_width,
							interpolationPitch: crossSection.station_interval,
							samplingSize: crossSection.sampling_size,
						};
						line.isCrossSectionLoaded = true;
						break;
					}
				}
			}
		} catch (err) {
			console.log(err);
		}
		commit("setSimaLineList", simaLineList);
	},
	async loadSimaLine({ state }, simaLine) {
		try {
			const res = await axios.get(
				`${import.meta.env.VITE_API_BASE}/linearfile/${this.state.site.site_uuid}/${simaLine.lineId}`,
				{
					auth: state.authObject,
				},
			);
			const viewerLineId = await window["viewer"].addSIMAData(res.data);
			simaLine.viewerLineId = viewerLineId;
			simaLine.isLoadedInViewer = true;
			simaLine.isLoaded = true;
			simaLine.isVisible = true;
		} catch (err) {
			simaLine.isLoadedInViewer = false;
			simaLine.isLoaded = false;
			simaLine.isVisible = false;
			//Todo inform user properly
			throw err;
		}
	},
	async addCrossSectionSettingForSima(
		{ commit },
		{ simaLine, crossSectionSetting },
	) {
		const crossSectionRes = await axios.post(
			`${import.meta.env.VITE_API_BASE}/crossSection/linearfile/${this.state.site.site_uuid}/${simaLine.lineId}`,
			{
				name: simaLine.lineName,
				leftSideWidth: crossSectionSetting.leftWidth,
				rightSideWidth: crossSectionSetting.rightWidth,
				stationInterval: crossSectionSetting.interpolationPitch,
				samplingSize: crossSectionSetting.samplingSize,
			},
		);
		const newCrossSectionSetting = {
			id: crossSectionRes.data.cross_section_id,
			name: crossSectionRes.data.name,
			leftWidth: crossSectionRes.data.left_side_width,
			rightWidth: crossSectionRes.data.right_side_width,
			interpolationPitch: crossSectionRes.data.station_interval,
			samplingSize: crossSectionRes.data.sampling_size,
		};
		simaLine.crossSectionSetting = newCrossSectionSetting;
		simaLine.isCrossSectionLoaded = true;
		simaLine.isCrossSectionVisible = true;
		await window["viewer"].generateLineWorksCrossSection(
			simaLine.viewerLineId,
			newCrossSectionSetting,
		);
		commit("updateSimaLine", simaLine);
	},
	async deleteSimaLine({ state, commit }, simaLine) {
		try {
			if (simaLine.isLoadedInViewer) {
				window["viewer"].removeSIMA(simaLine.viewerLineId);
			}
			if (simaLine.isCrossSectionLoaded) {
				await axios.delete(
					`${import.meta.env.VITE_API_BASE}/crossSection/linearfile/${this.state.site.site_uuid}/${simaLine.lineId}`,
					{
						auth: state.authObject,
					},
				);
			}
			await axios.delete(
				`${import.meta.env.VITE_API_BASE}/linearfile/${this.state.site.site_uuid}/${simaLine.lineId}`,
				{
					auth: state.authObject,
				},
			);
			const list = state.simaLineList.filter(
				(line) => line.lineId !== simaLine.lineId,
			);
			commit("setSimaLineList", list);
		} catch (e) {
			console.warn(e);
			throw e;
		}
	},
	async updateName(_, { simaLine }) {
		try {
			const res = await axios.put(
				`${import.meta.env.VITE_API_BASE}/linearfile/${simaLine.lineId}`,
				{
					name: simaLine.lineName,
				},
			);
			return res;
		} catch (err) {
			console.warn("Arbitrary line Name is not saved");
			//Todo inform user properly
			throw err;
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};

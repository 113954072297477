<template>
    <v-card>
      <div id="lineProfileView" tile class="pa-0 chart-board"
      ref="lineProfileView"></div>
    </v-card>
  </template>
  
  <script>
import "./style.css";
export default {
	name: "LineProfileViewDialog",
	props: {
		id: String,
	},

	mounted() {
		this.$nextTick(function () {
			this.update();
			this.onScreenResize();
		});
	},
	data: () => ({}),
	methods: {
		onScreenResize() {
			window.addEventListener("resize", () => {
				this.update();
			});
		},
		update() {
			const canvas =
				window["viewer"] &&
				window["viewer"].getCanvasForView("lineProfileView");
			const element = this.$refs.lineProfileView;
			if (canvas && element) {
				for (const node of element.children) {
					element.removeChild(node);
				}
				element.appendChild(canvas);
				canvas.height = element.getBoundingClientRect().height;
				canvas.width = element.getBoundingClientRect().width;
				window["viewer"].resize2dView(
					{ width: canvas.width, height: canvas.height },
					"lineProfileView",
				);
			}
		},
	},
};
</script>
  
  
  <style scoped>
  .line-profile-container{
    width: 100%;
    height: 240px;
  }
  </style>
<script setup>
import CloseButton from "@/components/common/CloseButton.vue";

const props = defineProps({
	isShow: Boolean,
	title: String,
	message: String,
	buttonText: {
		type: String,
		default: "OK",
	},
});

const emit = defineEmits(["close"]);
</script>

<template>
    <v-card v-if="isShow" class="px-4 pt-2 pb-4">
        <v-card-title class="d-flex align-center justify-space-between">
            <label>
                {{ title }}
            </label>
            <close-button @click="emit('close')" size="18px"></close-button>
        </v-card-title>
        <v-card-text class="pb-2 px-3 white--text">
            <v-container class="pa-0">
                <v-row class="ma-0">
                    <v-col>
                        <span v-html="message"></span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pa-0 d-flex justify-center">
                        <v-btn class="rounded-pill" color="primary" width="100px" height="35px" @click="emit('close')">
                            {{ buttonText }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
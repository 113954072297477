import { ref, watch, computed, nextTick } from "vue";
import { uploadObjAndNotify } from "@/utils/cesium-common";
import { useStore } from "vuex";
import * as constants from "@/constant.js";

export function useObjectPanel(objId) {
	const store = useStore();
	const isValid = ref(false);
	const form = ref(null); // templateのref='form'とマッピングする

	/** 更新対象のオブジェクトを取得しリアクティブに使用できるようにする */
	const obj = computed({
		get() {
			return store.state.objects.find((item) => item.id == objId);
		},
		set(newObj) {
			store.commit("updateObjInObjects", newObj);
		},
	});

	/** 更新を保存する */
	const saveChanges = () => {
		nextTick(() => {
			if (!form.value) return;
			form.value.validate();
			if (!isValid.value) return;
			uploadObjAndNotify(obj.value, obj.value.type);
		});
	};

	/** 透明度 */
	watch(
		() => obj.value.transparency,
		() => {
			if (obj.value.id === null) return;
			switch (obj.value.type) {
				case constants.objectType.ROAD:
					window["viewer"].updateRoadMaterialSettings(
						obj.value.id,
						{},
						{ transparency: obj.value.transparency },
						{ transparency: obj.value.transparency },
					);
					break;
				case constants.objectType.TRENCH:
					window["viewer"].updateTrenchMaterialSettings(
						obj.value.id,
						{},
						{ transparency: obj.value.transparency },
					);
					break;
				case constants.objectType.FLAT:
					window["viewer"].updateFlatGroundMaterialSettings(
						obj.value.id,
						{ transparency: obj.value.transparency },
						{ transparency: obj.value.transparency },
					);
					break;
			}
		},
	);

	/** 色 */
	const updateColor = () => {
		if (obj.value.id === null) return;
		switch (obj.value.type) {
			case constants.objectType.ROAD:
				window["viewer"].updateRoadMaterialSettings(
					obj.value.id,
					{ surfaceColor: obj.value.settings.commonSettings.surfaceColor },
					{ color: obj.value.settings.embarkmentSettings.color },
					{ color: obj.value.settings.cutSettings.color },
				);
				break;
			case constants.objectType.TRENCH:
				window["viewer"].updateTrenchMaterialSettings(
					obj.value.id,
					{ surfaceColor: obj.value.settings.commonSettings.surfaceColor },
					{ color: obj.value.settings.cutSettings.color },
				);
				break;
			case constants.objectType.FLAT:
				window["viewer"].updateFlatGroundMaterialSettings(
					obj.value.id,
					{ material: obj.value.settings.embankmentSettings.color },
					{ material: obj.value.settings.cutSettings.color },
				);
				break;
		}
		saveChanges();
	};

	watch(
		[
			/** 色関連のプロパティが存在すれば監視する */
			() => obj.value.settings.commonSettings?.surfaceColor,
			() => obj.value.settings.embarkmentSettings?.color,
			() => obj.value.settings.embankmentSettings?.color,
			() => obj.value.settings.cutSettings?.color,
		],
		() => {
			updateColor();
		},
	);

	return {
		obj,
		isValid,
		form,
		saveChanges,
	};
}

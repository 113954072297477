<template>
  <v-card color="rgba(41, 41, 47, 0.8)">
    <v-card-title> {{ $t("EXPORT_AS") }} </v-card-title>
    <v-card-text>
      <v-card-title class="justify-center text-center" style="word-break:break-word;white-space: break-spaces;">
        {{$t('ExportRoad', {name: name})}}<br />
        {{$t('SelectOutputFormat')}}
      </v-card-title>
      <v-radio-group v-model="active">
        <v-radio name="active" :label="$t('LandXML')" :value="ExportType.LandXML"></v-radio>
        <v-radio name="active" :label="$t('GEOTIFF')" :value="ExportType.GEOTIFF"></v-radio>
        <v-radio name="active" :label="$t('Registertodashboard')" :value="ExportType.RegisterToDashboard"></v-radio>       
      </v-radio-group>
      <v-row class="ma-0">
        <v-col>
          <v-card-actions class="justify-end">
            <v-btn 
              variant="flat"
              @click="closeDialog" 
              color="red"
            >
              {{ $t("CANCEL") }}
            </v-btn>
            <v-btn variant="flat" @click="exportGeotiffOrData()" color="primary">
              {{ $t("EXPORT_AS") }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as cesiumCommon from "@/utils/cesium-common";
import axios from "axios";
import { LandXML } from "earthbrain-viewer";
import { saveAs } from "file-saver";
import { mapMutations } from "vuex";

// Define the enum for export types in JavaScript
const ExportType = {
	LandXML: "LandXML",
	GEOTIFF: "GEOTIFF",
	RegisterToDashboard: "RegisterToDashboard",
};

export default {
	name: `ExportDrawing2DImageDialog`,
	props: {
		name: String,
		geometry: Object,
		imageId: Number,
	},
	data: () => ({
		active: ExportType.LandXML,
		ExportType,
	}),
	methods: {
		...mapMutations([`set_snackbar`]),
		resetProperty() {
			this.active = ExportType.LandXML;
		},
		closeDialog() {
			this.$emit(`close`, false);
		},
		exportGeotiffOrData() {
			if (this.active === ExportType.LandXML) {
				this.exportLandXML();
			} else if (this.active === ExportType.GEOTIFF) {
				this.exportGeoTiff();
			} else if (this.active === ExportType.RegisterToDashboard) {
				this.RegisterToDashboard();
			}
		},
		async exportLandXML() {
			const site = this.$store.state.site;
			const points = [];
			for (let i = 0; i < this.geometry.vertices.length; i += 3) {
				points.push({
					x: this.geometry.vertices[i],
					y: this.geometry.vertices[i + 1],
					z: this.geometry.vertices[i + 2],
				});
			}
			const landxmlPointsBuffer = [];
			for (const point of points) {
				landxmlPointsBuffer.push(point.y, point.x, point.z); //for landxml, x and y should be flipped
			}
			LandXML.exportMesh(
				landxmlPointsBuffer,
				this.geometry.indices,
				site.site_name,
				this.createFileName(new Date(), this.name, site.site_name),
			);
			this.closeDialog();
		},
		async exportGeoTiff() {
			// imageの読み込み処理(toggle処理)を追加すること
			const site = this.$store.state.site;
			this.$store.dispatch(`updateIsLoading`, true);
			const imageObj = cesiumCommon.getJsonOfDrawing2dImage(this.imageId);
			await axios
				.post(
					`${import.meta.env.VITE_API_BASE}/drawing2dImage/geotiff/${this.$route.query.siteId}/${this.imageId}`,
					{
						json: imageObj,
						name: this.name,
					},
					{
						responseType: `arraybuffer`,
					},
				)
				.then((data) => {
					const blob = new Blob([data.data], { type: `image/tiff` });
					const fileName = this.createFileName(
						new Date(),
						this.name,
						site.site_name,
					);
					saveAs(blob, fileName);
				})
				.catch((e) => {
					this.set_snackbar({
						text: `${this.$t("MESSAGE_ERROR_DEFAULT")}`,
						color: `rgba(153, 0, 0, 0.72)`,
					});
					console.log(e.message);
				})
				.finally(() => {
					this.$store.dispatch(`updateIsLoading`, false);
					this.closeDialog();
				});
		},
		async RegisterToDashboard() {
			const site = this.$store.state.site;
			// create xml
			const points = [];
			for (let i = 0; i < this.geometry.vertices.length; i += 3) {
				points.push({
					x: this.geometry.vertices[i],
					y: this.geometry.vertices[i + 1],
					z: this.geometry.vertices[i + 2],
				});
			}
			const landxmlPointsBuffer = [];
			for (const point of points) {
				landxmlPointsBuffer.push(point.y, point.x, point.z); //for landxml, x and y should be flipped
			}
			// Mechanism to return xml without downloading if the 4th argument is omitted.
			const xml = LandXML.exportMesh(
				landxmlPointsBuffer,
				this.geometry.indices,
				site.site_name,
			);
			// get image cornerposition
			const imageObj = cesiumCommon.getJsonOfDrawing2dImage(this.imageId);
			// start upload
			this.$store.dispatch(`updateIsLoading`, true);
			// file_name
			const file_name = this.createFileName(
				new Date(),
				this.name,
				site.site_name,
			);
			await axios
				.post(
					`${import.meta.env.VITE_API_BASE}/dashboard/image/${this.$route.query.siteId}/${this.imageId}`,
					{
						xmlData: xml,
						siteId: site.site_uuid, //this.$route.query.siteId,
						json: imageObj,
						name: file_name,
					},
				)
				.then(() => {
					this.set_snackbar({
						text: `${this.$t("Registertodashboard")} ${this.$t("successful")}`,
						color: `rgba(0, 153, 0, 0.72)`,
					});
				})
				.catch((e) => {
					this.set_snackbar({
						text: `${this.$t("Registertodashboard")} ${this.$t("failed")}`,
						color: `rgba(153, 0, 0, 0.72)`,
					});
					console.log(e.message);
				})
				.finally(() => {
					this.$store.dispatch(`updateIsLoading`, false);
					this.closeDialog();
				});
		},
		createFileName(date, objName, siteName) {
			return (
				[
					date.getFullYear(),
					this.padSingleDigit(date.getMonth() + 1),
					this.padSingleDigit(date.getDate()),
				].join(`-`) +
				` ` +
				[
					this.padSingleDigit(date.getHours()),
					this.padSingleDigit(date.getMinutes()),
					this.padSingleDigit(date.getSeconds()),
				].join(`_`) +
				`_${siteName}_${objName}`
			);
		},
		padSingleDigit(num) {
			return num.toString().padStart(2, `0`);
		},
	},
};
</script>
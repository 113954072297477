import { createI18n } from "vue-i18n";

import dict from "./SCDesignDict.json";

export const i18n = createI18n({
	allowComposition: true,
	legacy: false,
	fallbackLocale: "en",
	locale: "ja",
	messages: dict,
});

import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { defaultLineWorksCrossSection } from "@/components/Project/LineWorks/default.js";
import * as constants from "@/constant.js";

export default function useArbitraryLines() {
	const store = useStore();
	const route = useRoute();
	const { t } = useI18n();

	/**
	 * 道路/床掘の中心線から任意線形を作成する
	 * @param {Object[]} objects
	 * @param {function} callback finallyで実行して欲しいCallback
	 */
	async function createArbitraryLineFromObject(objects, callback) {
		const siteId = route.query.siteId;

		// スピナー開始
		let viewerLineId;
		await store
			.dispatch("executeWithSpinner", async () => {
				// 道路・床掘から線形情報を抽出
				const linearDatas = objects
					.map((obj) => {
						if (obj.type === constants.objectType.ROAD) {
							return JSON.parse(
								window["viewer"].getRoadLinearData({
									roadID: String(obj.id),
									roadName: obj.name,
									siteID: siteId,
									isVisible: obj.isShow,
								}),
							);
						} else if (obj.type === constants.objectType.TRENCH) {
							return JSON.parse(
								window["viewer"].getTrenchLinearData({
									trenchID: String(obj.id),
									trenchName: obj.name,
									siteID: siteId,
									isVisible: obj.isShow,
								}),
							);
						}
						return;
					})
					.filter((obj) => !!obj);

				// 線形情報から任意線形を作成
				for (const linearData of linearDatas) {
					const lineInfoJSON = JSON.stringify(linearData.lineInfo.json);
					viewerLineId =
						await window["viewer"].loadArbitraryLineJSON(lineInfoJSON);
					const data = {
						lineInfo: {
							viewerLineId: viewerLineId,
							label: linearData.lineInfo.label,
							json: lineInfoJSON,
							siteId: siteId,
							isVisible: true,
						},
						crossSectionSetting: defaultLineWorksCrossSection(), // 一旦断面設定は初期値を入れる仕様
					};
					await store.dispatch("arbitraryLine/addArbitraryLine", data);
				}
			})
			.catch((e) => {
				console.error(e);
				if (viewerLineId) {
					window["viewer"].deleteArbitraryLine(viewerLineId);
				}
				store.commit("set_snackbar", {
					text: t("LINEAR_SAVE_FAILURE"),
					color: "rgba(153, 0, 0, 0.72)",
				});
			})
			.finally(callback);
	}

	return {
		createArbitraryLineFromObject,
	};
}

<template>
	<span>
		<v-card class="justify-center confirm-dialog-content">
			<v-card-title>
				{{ $t("CHANGE_COORDINATE_SYSTEM") }}
			</v-card-title>
			<v-card-text class="text-subtitle-1 text-content">
				<v-icon> mdi-alert </v-icon>
				<div class="main-text">
					{{ $t("CHANGE_SITE_COORDINATE_SYSTEM_WARNING_MESSAGE") }}
				</div>
			</v-card-text>
			<v-card-actions class="justify-end button-content">
				<v-btn variant="flat" width="120px" min-height="31px" @click="closeDialog(false)" color="red">
					{{ $t("CANCEL") }}
				</v-btn>
				<v-btn variant="flat" width="120px" min-height="31px" @click="closeDialog(true)" color="primary">
					{{ $t("PROCEED") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</span>
</template>

<script>
export default {
	name: "ChangeSiteCoordinateSystemDialog",
	methods: {
		closeDialog(isProceed) {
			this.$emit("close", isProceed);
			return;
		},
	},
};
</script>

<style scoped>
.confirm-dialog-content {
	width: 100%;
}

.text-content {
	display: flex;
	flex-direction: row;
	padding-bottom: 0;

	.main-text {
		padding-left: 3%;
	}
}

.button-content {
	padding: 3%;
}
</style>

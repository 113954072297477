const state = {
	isProjectionToGroundExecuting: false,
	projectionToGroundExecutingIds: [],
};

const getters = {
	projectionToGroundExecutingIds(state) {
		return state.projectionToGroundExecutingIds;
	},
};

const mutations = {
	startProjectionToGround(state, dxfId) {
		state.projectionToGroundExecutingIds.push(dxfId);
	},
	completeProjectionToGround(state, dxfId) {
		state.projectionToGroundExecutingIds =
			state.projectionToGroundExecutingIds.filter((id) => id !== dxfId);
	},
};

const actions = {
	async projectionToGround({ commit }, { dxfId, pointCloudId }) {
		commit("startProjectionToGround", dxfId);
		await window["viewer"].adjustHeightOfDXFData(
			dxfId,
			"pointcloud",
			pointCloudId,
		);
		commit("completeProjectionToGround", dxfId);
	},
	async unprojectionToGround({ commit }, dxfId) {
		commit("startProjectionToGround", dxfId);
		await window["viewer"].resetHeightOfDXFData(dxfId);
		commit("completeProjectionToGround", dxfId);
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};

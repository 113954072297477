import axios from "axios";

const actions = {
	async saveCrossSectionOfLineWorks(_, { crossSectionSetting }) {
		try {
			await axios.put(
				`${import.meta.env.VITE_API_BASE}/crossSection/${this.state.site.site_uuid}/${crossSectionSetting.id}`,
				{
					name: crossSectionSetting.name,
					leftSideWidth: crossSectionSetting.leftWidth,
					rightSideWidth: crossSectionSetting.rightWidth,
					stationInterval: crossSectionSetting.interpolationPitch,
					samplingSize: crossSectionSetting.samplingSize,
				},
			);
		} catch (err) {
			console.warn("CrossSection settings is not saved");
			//Todo inform user properly
			throw err;
		}
	},
	async exportCrossSection(_, line) {
		try {
			const crossSectionData = window[
				"viewer"
			].get2DCrossSectionDataOfLineWorks(line.viewerLineId);
			const lines = [];
			for (const crossSection of crossSectionData) {
				const assetPoints = new Map();
				crossSection.left.forEach((assetLists, assetType) => {
					assetLists.forEach((assetPointLists) => {
						assetPointLists.forEach((pointList) => {
							const points = pointList.map((point) => {
								return { x: point[0], y: point[1] };
							});
							if (assetPoints[assetType]) {
								assetPoints[assetType].push(points);
							} else {
								assetPoints[assetType] = [points];
							}
						});
					});
				});

				crossSection.right.forEach((assetLists, assetType) => {
					assetLists.forEach((assetPointLists) => {
						assetPointLists.forEach((pointList) => {
							const points = pointList.map((point) => {
								return { x: point[0], y: point[1] };
							});
							if (assetPoints[assetType]) {
								assetPoints[assetType].push(points);
							} else {
								assetPoints[assetType] = [points];
							}
						});
					});
				});
				const center = {
					x: crossSection.referencePoint.x,
					y: crossSection.referencePoint.y,
					z: crossSection.referencePoint.z,
				};
				lines.push({ assetPoints, center, type: crossSection.pointType });
			}
			const response = await axios.post(
				`${import.meta.env.VITE_API_BASE}/crossSection/createDXF/${this.state.site.site_uuid}/${line.crossSectionSetting.id}`,
				{
					lines,
				},
			);
			const blob = new Blob([response.data], { type: "application/dxf" });
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = `${line.lineName}.dxf`;
			link.click();
			URL.revokeObjectURL(link.href);
		} catch (err) {
			console.warn("Dxf download failed", err);
			throw err;
		}
	},
};

export default {
	namespaced: true,
	actions,
};
